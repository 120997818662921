import { FormControl, FormControlLabel, FormLabel, Radio } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';

const SingleSelectForm = ({ inputValues, onSelectChange, selectedValue, classes }) => {

  return (
    <FormControl>
      {inputValues.map(({ key, label, elements }) => {
        return elements ?
          <>
            <FormLabel>{label}</FormLabel>
            <div className={classes.radioGroup}>
              {elements.map(({ key, label }) => (
                <FormControlLabel
                  onClick={() => onSelectChange(key)}
                  checked={selectedValue === key}
                  value={key}
                  control={< Radio />}
                  label={label}
                />
              ))}
            </div>
          </> :
          <>
            <FormControlLabel
              checked={selectedValue === key}
              onClick={() => onSelectChange(key)}
              value={key} control={< Radio />}
              label={label}
            />
          </>;

      })}
    </FormControl>
  );
};

const styles = () => ({
  radioGroup: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 30,
  },
});

SingleSelectForm.propTypes = {
  inputValues: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles),
);

const SelectFormComponent = prepareForExport(SingleSelectForm);
export { SelectFormComponent };