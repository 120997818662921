import Immutable from 'immutable';

import { 
  GET_LOCATION_PREVIEW_FAILURE,
  GET_LOCATION_PREVIEW_REQUEST,
  GET_LOCATION_PREVIEW_SUCCESS,
} from './hubspot.actions';

const initialState = Immutable.Map({
  isLoading: false,
  locationPreview: null,
  isLoadingLocationPreview: false,
});

export default function(state = initialState, action) {
  switch (action.type) {

    case GET_LOCATION_PREVIEW_SUCCESS:
      return state.set('locationPreview', action.response).set('isLoadingLocationPreview', false);
      
    case GET_LOCATION_PREVIEW_FAILURE:
      return state.set('isLoadingLocationPreview', false);

    case GET_LOCATION_PREVIEW_REQUEST:
      return state.set('isLoadingLocationPreview', true);

    default:
      return state;
  }
}
