import { TableCell, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import moment from 'moment';

import { DATE_FORMAT, formatDurationHourMinSec } from '../../../../utilities/dateUtils';
import { exportCSVFile } from '../../../../utilities/csvUtils';
import { isMomOccupancy } from '../../../../utilities/monitoringUtils';
import DataTable from '../../../layout/dataTable/dataTable.component';

const TABLE_PADDING = 'dense';

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'localTimestamp', numeric: false, padding: TABLE_PADDING, label: 'Session Start Time at Location', sortable: true, filtering: false },
  { id: 'email', numeric: false, padding: TABLE_PADDING, label: 'Email', sortable: true, filtering: true },
  { id: 'occ_time', numeric: false, padding: TABLE_PADDING, label: 'Occupancy Time', sortable: true, filtering: true, filterOptions: ['is mom session', 'not mom session'], filterId: 'isMomOccupancy' },
  { id: 'isFromApp', numeric: false, padding: TABLE_PADDING, label: 'Sent via App' },
];

const renderRow = (row, i) => {

  return (
    <Fragment key={i}>
      <TableRow
        tabIndex={-1}
        hover
      >
        <TableCell align="left" padding={TABLE_PADDING}>{row.localTimestamp}</TableCell>
        <TableCell align="left" padding={TABLE_PADDING}>{row.email ? row.email : 'Guest'}</TableCell>
        <TableCell align="left" padding={TABLE_PADDING}>{row.formattedOccTime}</TableCell>
        <TableCell align="left" padding={TABLE_PADDING}>{row.isFromApp ? 'True' : 'False'}</TableCell>
      </TableRow>
    </Fragment>
  );
};

const handleExport = (filteredData) => () => {
  const csvColumns = columns.filter((column) => column.id !== 'timestamp' && column.id !== 'occ_time');

  exportCSVFile([
    ...csvColumns,
    {id: 'csvFormattedOccTime', label: 'Occupancy Time'},
  ], filteredData, `occupancies_${moment().format('YYYY-MM-DD')}`);
};

const Occupancies = ({occupancies, locationTimezone}) => {
  const formattedData = useMemo(
    () => occupancies.map((row) => {
      const occupancyStartTime = moment(row.timestamp).subtract(row.occ_time || 0, 'seconds');
      const occ_duration = row.occ_time ? moment.duration(row.occ_time, 'seconds') : null;
      row = { ...row }; 
      row.isMomOccupancy = isMomOccupancy(row) ? 'is mom session' : 'not mom session';
      row.localTimestamp = occupancyStartTime.toDate().toLocaleString(undefined, { timeZone: locationTimezone }).toLocaleLowerCase()
      row.formattedOccTime = formatDurationHourMinSec(occ_duration, true);
      row.csvFormattedOccTime = formatDurationHourMinSec(occ_duration);
      return row;
    }), [occupancies]);

  return (
    <DataTable
      data={formattedData}
      title={''}
      columns={columns}
      renderRow={renderRow}
      disableSearch
      defaultSortOrder="asc"
      onExportClick={handleExport}
    />
  );
};

const styles = () => ({
});

Occupancies.propTypes = {
  classes: PropTypes.object.isRequired,
  occupancies: PropTypes.array.isRequired,
  locationTimezone: PropTypes.string.isRequired,
};

const prepareForExport = compose(
  withStyles(styles)
);

export default prepareForExport(Occupancies);
