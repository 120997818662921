import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

export const useCheckPermission = () => {
  const { getIdTokenClaims } = useAuth0();

  const [permissions, setPermissions] = useState([]);
  useEffect(() => {
    getIdTokenClaims().then(claims => setPermissions(claims.permissionsKeys));
  }, [getIdTokenClaims]);

  const isAuthorized = requiredPermission => !requiredPermission || permissions.some(permission => requiredPermission === permission);
  
  return {
    isAuthorized,
  };
};
