import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { DEFAULT_TTLOCK_PIN_CODES, HARDWARE_TYPES, LIFECYCLE_STATUS, LOCATION_TYPES } from '../../../types/location.types';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';

function CreateLocationDialog({classes, onChange}) {

  const handlePrebuiltLocationCreation = async () => {
    await onChange({
      target: {
        name: 'lifecycleStatus',
        value: LIFECYCLE_STATUS.PREBUILT,
      },
    });
    await onChange({
      target: {
        name: 'type',
        value: LOCATION_TYPES.MAMAVA,
      },
    });
    await onChange({
      target: {
        name: 'pinCodes',
        value: DEFAULT_TTLOCK_PIN_CODES,
      },
    });
    await onChange({
      target: {
        name: 'podHardwareType',
        value: HARDWARE_TYPES.PCD_PAIRED_WITH_TTLOCK,
      },
    });
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h6">Create a new Location</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePrebuiltLocationCreation}
            >
              Create Prebuilt Mamava Pod
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onChange({
                  target: {
                    name: 'type',
                    value: LOCATION_TYPES.MAMAVA,
                  },
                });
              }}
            >
              Create Mamava Pod
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onChange({
                  target: {
                    name: 'type',
                    value: LOCATION_TYPES.LOCATION,
                  },
                });
              }}
            >
              Create Lactation Space
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const styles = () => ({
  card: {
    margin: 10,
  },
});

CreateLocationDialog.propTypes = {
  classes: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
);

export default prepareForExport(CreateLocationDialog);