import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { PERMISSIONS } from '../auth/constants';
import { PrivateRoute } from './privateRoute.component';
import FleetOverview from '../monitoring/fleetOverview/fleetOverview.container';
import Header from './header.container';
import HubspotPreviewPage from '../location/hubspotPreview.container';
import IotCommandCreatePage from '../iotCommand/iotCommandCreate.container';
import IotCommandDetailsPage from '../iotCommand/iotCommandDetails.container';
import IotCommandsContainer from '../iotCommand/iotCommands.container';
import IotLocationDetailsPage from '../iotCommand/iotLocationDetails.container';
import LocationViewPage from '../location/view.container';
import LocationsPage from '../locations/locations.container';
import MonitoringLocationsPage from '../monitoring/locations.container';
import PromotionCreatePage from '../promotion/create.container';
import PromotionEditPage from '../promotion/edit.container';
import PromotionSlotCreatePage from '../promotionSlot/create.container';
import PromotionSlotsPage from '../promotionSlots/promotionSlots.container';
import PromotionsPage from '../promotions/promotions.container';
import SubmissionsPage from '../submissions/submissions.container';
import Toast from './toast.container';
import UsersPage from '../users/users.container';

class MainContainer extends Component {
  render() {
    let { classes } = this.props;

    return (
      <Fragment>
        <div className={classes.root}>
          <Header />
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              <Route exact path="/"><Redirect to="/locations"/></Route>

              <Route exact path="/monitoring" component={FleetOverview} />
              <Route exact path="/monitoring/locations" component={MonitoringLocationsPage} />

              <PrivateRoute exact path="/iotCommands" component={IotCommandsContainer} requiredPermission={PERMISSIONS.ACCESS_COMMAND_CENTER} />
              <PrivateRoute exact path="/iotCommands/locations" component={IotCommandsContainer} requiredPermission={PERMISSIONS.ACCESS_COMMAND_CENTER} />
              <PrivateRoute exact path="/iotCommands/commandSet/create" component={IotCommandCreatePage} requiredPermission={PERMISSIONS.ACCESS_COMMAND_CENTER} />
              <PrivateRoute exact path="/iotCommands/commandSet/:id" component={IotCommandDetailsPage} requiredPermission={PERMISSIONS.ACCESS_COMMAND_CENTER} />
              <PrivateRoute exact path="/iotCommands/location/:id" component={IotLocationDetailsPage} requiredPermission={PERMISSIONS.ACCESS_COMMAND_CENTER} />

              <PrivateRoute exact path="/locations" component={LocationsPage} requiredPermission={PERMISSIONS.ACCESS_LOCATIONS} />
              <PrivateRoute exact path="/locations/:id" component={LocationViewPage} requiredPermission={PERMISSIONS.ACCESS_LOCATIONS} />
              <PrivateRoute exact path="/hubspot/locationPreview/:id" component={HubspotPreviewPage} requiredPermission={PERMISSIONS.ACCESS_LOCATIONS} />

              <PrivateRoute exact path="/users" component={UsersPage} requiredPermission={PERMISSIONS.ACCESS_USERS} />
              <PrivateRoute exact path="/submissions" component={SubmissionsPage} requiredPermission={PERMISSIONS.ACCESS_SUBMISSIONS} />

              <PrivateRoute exact path="/promotions" component={PromotionsPage} requiredPermission={PERMISSIONS.ACCESS_PROMOTIONS} />
              <PrivateRoute exact path="/promotions/create" component={PromotionCreatePage} requiredPermission={PERMISSIONS.ACCESS_PROMOTIONS} />
              <PrivateRoute exact path="/promotions/:id/edit" component={PromotionEditPage} requiredPermission={PERMISSIONS.ACCESS_PROMOTIONS} />
              <PrivateRoute exact path="/promotionSlots" component={PromotionSlotsPage} requiredPermission={PERMISSIONS.ACCESS_PROMOTIONS_APP_SLOTS} />
              <PrivateRoute exact path="/promotionSlots/create" component={PromotionSlotCreatePage} requiredPermission={PERMISSIONS.ACCESS_PROMOTIONS_APP_SLOTS} />

              <Redirect from="*" to="/" />
            </Switch>
          </main>

        </div>
        <Toast />
      </Fragment>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflowX: 'hidden',
  },
  content: {
    flexGrow: 1,
    overflowX: 'auto'
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
});


MainContainer.contextTypes = {
  router: PropTypes.object,
};

MainContainer.propTypes = {
  classes: PropTypes.object.isRequired,

  // Injected by React Router
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles, {withTheme: true}),
  withRouter,
  // Require authentication throughout whole app
  withAuthenticationRequired,
);


export default prepareForExport(MainContainer);