import { ENVIRONMENTS } from "../../types/environments.types";

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_RESPONSIVE_BREAKPOINT = 'SET_RESPONSIVE_BREAKPOINT';

export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';
export const HIDE_TOAST_MESSAGE = 'HIDE_TOAST_MESSAGE';
export const SET_TOAST_POSITION = 'SET_TOAST_POSITION';

export const TOGGLE_DRAWER_MENU = 'TOGGLE_DRAWER_MENU';

export const SET_DIRTY_FORM = 'SET_DIRTY_FORM';

const showEnv = process.env.REACT_APP_FIREBASE_ENV != ENVIRONMENTS.PRODUCTION;
const envName = process.env.REACT_APP_FIREBASE_ENV;

export const setPageTitle = (title, breadcrumbLink, breadcrumbTitle) => {
  return {
    type: SET_PAGE_TITLE,
    payload: showEnv ? `(${envName}) ${title}` : title,
    breadcrumb: breadcrumbLink,
    breadcrumbTitle,
  };
};

export const setResponsiveBreakpoint = (breakpoint) => {
  return {
    type: SET_RESPONSIVE_BREAKPOINT,
    payload: breakpoint,
  };
};

export const showToast = (message, isFailure) => {
  return {
    type: SHOW_TOAST_MESSAGE,
    message,
    isFailure,
  };
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST_MESSAGE,
  };
};

let toastTimer;
export const handleToastMessage = (message, isFailure) => {
  return (dispatch, getState) => {
    clearTimeout(toastTimer);
    dispatch(showToast(message, isFailure));
    toastTimer = setTimeout(() => {
      dispatch(hideToast());
    }, 3000);
  };
};

export const setToastPosition = (position) => {
  return {
    type: SET_TOAST_POSITION,
    position,
  };
};

export const toggleDrawerMenu = () => {
  return {
    type: TOGGLE_DRAWER_MENU,
  };
};

export const setDirtyForm = (flag) => {
  return {
    type: SET_DIRTY_FORM,
    payload: { flag },
  };
};
