import { compose } from 'redux';
import { connect } from 'react-redux';
import { first, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { UPDATE_PROMOTION_SUCCESS, getPromotion, setSelectedPromotion, updatePromotion } from '../promotions/promotions.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';
import PromotionForm from './form.component';

class PromotionEditContainer extends Component {
  constructor(props) {
    super(props);

    this.handleEdit = this.handleEdit.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.setPageTitle('Edit Promotion');
    let id = this.props.match.params.id;
    this.props.getPromotion(id);
  }

  async handleEdit(promotionData) {
    let response = await this.props.updatePromotion(this.props.selectedPromotion.id, promotionData);

    if (response.type === UPDATE_PROMOTION_SUCCESS) {
      this.props.handleToastMessage('Promotion updated.');
      this.props.history.goBack();
    } else {
      const message = first(get(response, 'messages', []));
      this.props.handleToastMessage(`Failed to update promotion: ${message}`, true);
    }
  }

  render() {
    let { handleToastMessage, selectedPromotion } = this.props;

    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <PromotionForm promotion={selectedPromotion} submitAction={this.handleEdit} handleToastMessage={handleToastMessage} />
      </div>
    );
  }
}

PromotionEditContainer.propTypes = {
  selectedPromotion: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getPromotion: PropTypes.func.isRequired,
  setSelectedPromotion: PropTypes.func.isRequired,
  updatePromotion: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedPromotion: state.promotions.get('selectedPromotion'),
    isLoading: state.promotions.get('isLoading'),
  };
};


const prepareForExport = compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      setPageTitle,
      getPromotion,
      setSelectedPromotion,
      updatePromotion,
      handleToastMessage,
    }),
);

export default prepareForExport(PromotionEditContainer);
