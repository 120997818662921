import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const DialogContainerComponent = ({ isOpen, onClose, onSubmit, isSubmitDisabled, title, children }) => {

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => onClose()}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()}>Cancel</Button>
          <Button disabled={isSubmitDisabled} variant="contained" color="primary" onClick={handleSubmit}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DialogContainerComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};