import { compose } from 'redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

import { RESPONSE_FAILURE, RESPONSE_SUCCESS } from '../iotCommand.actions';
import DataTable from '../../layout/dataTable/dataTable.component';

const styles = () => ({
  tableRowRoot: {
    cursor: 'pointer !important',
  },
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'description', numeric: false, padding: 'dense', label: 'Description', sortable: true, filtering: true },
  { id: 'commandName', numeric: false, padding: 'dense', label: 'Command', sortable: true, filtering: true },
  { id: 'userEmail', numeric: false, padding: 'dense', label: 'User', sortable: true, filtering: true },
  { id: 'sentDate', numeric: false, padding: 'dense', label: 'Date Sent', sortable: true, filtering: false },
  { id: 'lastResponseReceived', numeric: false, padding: 'dense', label: 'Last Response Received', sortable: true, filtering: false },
  { id: 'total', numeric: true, padding: 'dense', label: 'Total', sortable: true, filtering: true },
  { id: RESPONSE_SUCCESS, numeric: true, padding: 'dense', label: 'Success', sortable: true, filtering: true },
  { id: RESPONSE_FAILURE, numeric: true, padding: 'dense', label: 'Failure', sortable: true, filtering: true },
];

const CommandSetTable = ({data, history}) => {
  const handleAddClick = () => {
    history.push('/iotCommands/commandSet/create');
  };

  const handleEditClick = async (event, row) => {
    history.push(`/iotCommands/commandSet/${row.id}`);
  };

  // get custom row render component
  const renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          tabIndex={-1}
          onClick={(event) => handleEditClick(event, row)}
          hover
        >
          <TableCell align="left" padding={columns[0].padding}>{row.description}</TableCell>
          <TableCell align="left" padding={columns[1].padding}>{row.commandName}</TableCell>
          <TableCell align="left" padding={columns[2].padding}>{row.userEmail}</TableCell>
          <TableCell align="left" padding={columns[3].padding}>{`${row.sentDate ? moment(row.sentDate).format('MMMM D, YYYY h:mma') : ''}`}</TableCell>
          <TableCell align="left" padding={columns[4].padding}>{`${row.lastResponseReceived ? moment(row.lastResponseReceived).format('MMMM D, YYYY h:mma') : ''}`}</TableCell>
          <TableCell align="left" padding={columns[5].padding}>{row.total}</TableCell>
          <TableCell align="left" padding={columns[6].padding}>{row[RESPONSE_SUCCESS]}</TableCell>
          <TableCell align="left" padding={columns[7].padding}>{row[RESPONSE_FAILURE]}</TableCell>
        </TableRow>
      </Fragment>
    );
  };


  const formattedData = data.map((row) => {
    row = { ...row };
    row.userEmail = row.user ? row.user.email : '';
    row.sentDate = `${row.sentDate ? row.sentDate : ''}`;
    row.lastResponseReceived = `${row.lastResponseReceived ? row.lastResponseReceived : ''}`;
    row.total = row.commandResponses ? row.commandResponses.length : 0;
    return row;
  });

  return (
    <DataTable
      data={formattedData}
      title={''}
      columns={columns}
      renderRow={renderRow}
      disableSearch
      defaultSortOrder="asc"
      addButtonLabel="Add Command"
      onAddClick={handleAddClick}
    />
  );
};

CommandSetTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles)
);

export default prepareForExport(CommandSetTable);
