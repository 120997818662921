import { PermissionCheck } from '../auth/permissionCheck.component';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

export const PrivateRoute = ({path, component, requiredPermission, ...routeProps}) => (
  <PermissionCheck
    require={requiredPermission}
  >
    <Route path={path} component={component} {...routeProps}/>
  </PermissionCheck>
);

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
  requiredPermission: PropTypes.string.isRequired,
};
