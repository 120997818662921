import { CALL_API } from '../../middleware/api';

export const GET_PROMOTIONS_REQUEST = 'GET_PROMOTIONS_REQUEST';
export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS';
export const GET_PROMOTIONS_FAILURE = 'GET_PROMOTIONS_FAILURE';

export const CREATE_PROMOTION_REQUEST = 'CREATE_PROMOTION_REQUEST';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_FAILURE = 'CREATE_PROMOTION_FAILURE';

export const GET_PROMOTION_REQUEST = 'GET_PROMOTION_REQEUST';
export const GET_PROMOTION_SUCCESS = 'GET_PROMOTION_SUCCESS';
export const GET_PROMOTION_FAILURE = 'GET_PROMOTION_FAILURE';

export const SET_SELECTED_PROMOTION = 'SET_SELECTED_PROMOTION';

export const UPDATE_PROMOTION_REQUEST = 'UPDATE_PROMOTION_REQUEST';
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCES';
export const UPDATE_PROMOTION_FAILURE = 'UPDATE_PROMOTION_FAILURE';

export const DELETE_PROMOTION_REQUEST = 'DELETE_PROMOTION_REQUEST';
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS';
export const DELETE_PROMOTION_FAILURE = 'DELETE_PROMOTION_FAILURE';

export const PROMOTION_TEXT_FIELDS = [
  { name: 'name', label: 'Name' },
  { name: 'imageRef', label: 'Image Ref', optional: true },
  { name: 'promotionURL', label: 'Promotion URL' },
];

export const getPromotions = () => {
  return {
    [CALL_API]: {
      types: [GET_PROMOTIONS_REQUEST, GET_PROMOTIONS_SUCCESS, GET_PROMOTIONS_FAILURE],
      authenticated: true,
      endpoint: 'promotions',
      method: 'GET',
    },
  };
};

const prepare = (promotion) => {
  return promotion;
};

export const createPromotion = (promotionData) => {
  return {
    [CALL_API]: {
      types: [CREATE_PROMOTION_REQUEST, CREATE_PROMOTION_SUCCESS, CREATE_PROMOTION_FAILURE],
      authenticated: true,
      endpoint: 'promotions',
      method: 'POST',
      payload: prepare(promotionData),
    },
  };
};

export const getPromotion = (promotionId) => {
  return {
    [CALL_API]: {
      types: [GET_PROMOTION_REQUEST, GET_PROMOTION_SUCCESS, GET_PROMOTION_FAILURE],
      authenticated: true,
      endpoint: `promotions/${promotionId}`,
      method: 'GET',
      actionMetadata: {
        promotionId,
      },
    },
  };
};

export const setSelectedPromotion = (promotion) => {
  return {
    type: SET_SELECTED_PROMOTION,
    response: promotion,
  };
};

export const updatePromotion = (promotionId, promotionData) => {
  return {
    [CALL_API]: {
      types: [UPDATE_PROMOTION_REQUEST, UPDATE_PROMOTION_SUCCESS, UPDATE_PROMOTION_FAILURE],
      authenticated: true,
      endpoint: `promotions/${promotionId}`,
      method: 'PUT',
      payload: prepare(promotionData),
      actionMetadata: {
        promotionId,
      },
    },
  };
};

export const deletePromotion = (promotion) => {
  return {
    [CALL_API]: {
      types: [DELETE_PROMOTION_REQUEST, DELETE_PROMOTION_SUCCESS, DELETE_PROMOTION_FAILURE],
      authenticated: true,
      endpoint: `promotions/${promotion.id}`,
      method: 'DELETE',
      actionMetadata: {
        promotionId: promotion.id,
      },
    },
  };
};
