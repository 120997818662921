import { CALL_API } from '../../middleware/api';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const TOGGLE_USER_FLAG_REQUEST = 'TOGGLE_USER_FLAG_REQUEST';
export const TOGGLE_USER_FLAG_SUCCESS = 'TOGGLE_USER_FLAG_SUCCESS';
export const TOGGLE_USER_FLAG_FAILURE = 'TOGGLE_USER_FLAG_FAILURE';

export const CLEAR_USER_ACCESS_CODE_REQUEST = 'CLEAR_USER_ACCESS_CODE_REQUEST';
export const CLEAR_USER_ACCESS_CODE_SUCCESS = 'CLEAR_USER_ACCESS_CODE_SUCCESS';
export const CLEAR_USER_ACCESS_CODE_FAILURE = 'CLEAR_USER_ACCESS_CODE_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const getUsers = () => {
  return {
    [CALL_API]: {
      types: [GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE],
      authenticated: true,
      endpoint: 'users',
      method: 'GET',
    },
  };
};

// flagName is a string: 'isAdmin' or 'canDebug'
export const toggleUserFlag = (userId, flagName) => {
  return {
    [CALL_API]: {
      types: [TOGGLE_USER_FLAG_REQUEST, TOGGLE_USER_FLAG_SUCCESS, TOGGLE_USER_FLAG_FAILURE],
      authenticated: true,
      endpoint: `users/${userId}/toggle/${flagName}`,
      method: 'POST',
      actionMetadata: {
        userId,
        flagName,
      },
    },
  };
};

export const clearUserAccessCodes = userId => {
  return {
    [CALL_API]: {
      types: [CLEAR_USER_ACCESS_CODE_REQUEST, CLEAR_USER_ACCESS_CODE_SUCCESS, CLEAR_USER_ACCESS_CODE_FAILURE],
      authenticated: true,
      endpoint: `users/${userId}/accessCodes`,
      method: 'DELETE',
      actionMetadata: {
        userId,
      },
    },
  };
};

export const deleteUser = userId => {
  return {
    [CALL_API]: {
      types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
      authenticated: true,
      endpoint: `users/${userId}`,
      method: 'DELETE',
      actionMetadata: {
        userId,
      },
    },
  };
};