import { find, isEmpty } from 'lodash';
import Immutable from 'immutable';

import {
  CREATE_LOCATION_SUCCESS,
  CREATE_REPLY_SUCCESS,
  DELETE_LOCATION_SUCCESS,
  DELETE_REPLY_SUCCESS,
  GET_LOCATION_FAILURE,
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
  MODERATE_REVIEW_SUCCESS,
  REPLACE_LOCATION_LOCK_SUCCESS,
  SET_SELECTED_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_REPLY_SUCCESS,
} from './location.actions';

const initialState = Immutable.Map({
  selectedLocation: null,
  isLoading: false,
  errors: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_LOCATION_SUCCESS:
      return state.set('isLoading', false);

    case GET_LOCATION_SUCCESS:
      return state.set('selectedLocation', action.response).set('isLoading', false);

    case GET_LOCATION_FAILURE:
      return state.set('isLoading', false);

    case GET_LOCATION_REQUEST:
      return state.set('isLoading', true);

    case SET_SELECTED_LOCATION:
      return state.set('selectedLocation', action.response);
    case REPLACE_LOCATION_LOCK_SUCCESS: 
      return state.set('selectedLocation', action.response.location);
    case UPDATE_LOCATION_SUCCESS: {
      let selectedLocation = { ...state.get('selectedLocation') };
      const locationId = action.actionMetadata;

      if (!isEmpty(selectedLocation) && selectedLocation.id === locationId) {
        selectedLocation = Object.assign(selectedLocation, action.payload);
      }

      return state.set('selectedLocation', selectedLocation).set('isLoading', false);
    }

    case DELETE_LOCATION_SUCCESS:
      return state.set('isLoading', false);

    case MODERATE_REVIEW_SUCCESS: {
      let moderatedReview = action.response;
      let location = state.get('selectedLocation');
      console.log('here is the moderate location', state);
      if (location.reviews) {
        let reviews = location.reviews.map((review) => {
          if (review.id === moderatedReview.id) {
            review.status = moderatedReview.status;
          }
          return review;
        });
        location.reviews = reviews;
      }
      return state.set('selectedLocation', location).set('isLoading', false);
    }

    case CREATE_REPLY_SUCCESS: {
      let createdReview = action.response;
      let location = {...state.get('selectedLocation')};

      let review = find(location.reviews, { id: createdReview.parentReviewId });
      if (review){
        review.childReviews = [createdReview];
      }
      return state.set('selectedLocation', location).set('isLoading', false);
    }

    case UPDATE_REPLY_SUCCESS: {
      let updatedReview = action.response;
      let location = {...state.get('selectedLocation')};

      let review = find(location.reviews, { id: updatedReview.parentReviewId });
      if (review){
        review.childReviews = [updatedReview];
      }
      return state.set('selectedLocation', location).set('isLoading', false);
    }

    case DELETE_REPLY_SUCCESS: {
      let location = {...state.get('selectedLocation')};
      let { parentReviewId } = action.actionMetadata;

      let review = find(location.reviews, { id: parentReviewId });
      if (review){
        review.childReviews = [];
      }
      return state.set('selectedLocation', location).set('isLoading', false);
    }

    default:
      return state;
  }
}
