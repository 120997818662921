import { CALL_API } from '../../middleware/api';

export const GET_COMMAND_SETS_FAILURE = 'GET_COMMAND_SETS_FAILURE';
export const GET_COMMAND_SETS_REQUEST = 'GET_COMMAND_SETS_REQUEST';
export const GET_COMMAND_SETS_SUCCESS = 'GET_COMMAND_SETS_SUCCESS';

export const GET_COMMAND_SET_FAILURE = 'GET_COMMAND_SET_FAILURE';
export const GET_COMMAND_SET_REQUEST = 'GET_COMMAND_SET_REQUEST';
export const GET_COMMAND_SET_SUCCESS = 'GET_COMMAND_SET_SUCCESS';

export const GET_COMMAND_CONFIG_FAILURE = 'GET_COMMAND_CONFIG_FAILURE';
export const GET_COMMAND_CONFIG_REQUEST = 'GET_COMMAND_CONFIG_REQUEST';
export const GET_COMMAND_CONFIG_SUCCESS = 'GET_COMMAND_CONFIG_SUCCESS';

export const GET_PODS_REQUEST = 'GET_PODS_REQUEST';
export const GET_PODS_SUCCESS = 'GET_PODS_SUCCESS';
export const GET_PODS_FAILURE = 'GET_PODS_FAILURE';

export const SEND_COMMAND_REQUEST = 'SEND_COMMAND_REQUEST';
export const SEND_COMMAND_SUCCESS = 'SEND_COMMAND_SUCCESS';
export const SEND_COMMAND_FAILURE = 'SEND_COMMAND_FAILURE';

export const GET_IOT_LOCATIONS_REQUEST = 'GET_IOT_LOCATIONS_REQUEST';
export const GET_IOT_LOCATIONS_SUCCESS = 'GET_IOT_LOCATIONS_SUCCESS';
export const GET_IOT_LOCATIONS_FAILURE = 'GET_IOT_LOCATIONS_FAILURE';

export const GET_IOT_LOCATION_REQUEST = 'GET_IOT_LOCATION_REQUEST';
export const GET_IOT_LOCATION_SUCCESS = 'GET_IOT_LOCATION_SUCCESS';
export const GET_IOT_LOCATION_FAILURE = 'GET_IOT_LOCATION_FAILURE';

export const SELECT_PODS = 'SELECT_PODS';
export const SET_DESCRIPTION = 'SET_DESCRIPTION';
export const SET_SELECTED_COMMAND_INDEX = 'SET_SELECTED_COMMAND_INDEX';
export const SET_COMMAND_PAYLOAD = 'SET_COMMAND_PAYLOAD';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_CUSTOM_COMMAND = 'SET_CUSTOM_COMMAND';

export const CUSTOM_COMMAND_INDEX = -2;
export const EMPTY_COMMAND_INDEX = -1;

export const RESPONSE_SUCCESS = 'SUCCESS';
export const RESPONSE_FAILURE = 'FAILURE';
export const RESPONSE_PENDING = 'PENDING';

export const getCommandSets = () => {
  return {
    [CALL_API]: {
      types: [GET_COMMAND_SETS_REQUEST, GET_COMMAND_SETS_SUCCESS, GET_COMMAND_SETS_FAILURE],
      authenticated: true,
      endpoint: 'iot/commandSets',
      method: 'GET',
    },
  };
};

export const getCommandSet = (commandSetId) => {
  return {
    [CALL_API]: {
      types: [GET_COMMAND_SET_REQUEST, GET_COMMAND_SET_SUCCESS, GET_COMMAND_SET_FAILURE],
      authenticated: true,
      endpoint: `iot/commandSets/${commandSetId}`,
      method: 'GET',
    },
  };
};

export const getCommandConfig = () => {
  return {
    [CALL_API]: {
      types: [GET_COMMAND_CONFIG_REQUEST, GET_COMMAND_CONFIG_SUCCESS, GET_COMMAND_CONFIG_FAILURE],
      authenticated: true,
      endpoint: 'iot/commandConfig',
      method: 'GET',
    },
  };
};

export const getSmartPods = () => {
  return {
    [CALL_API]: {
      types: [GET_PODS_REQUEST, GET_PODS_SUCCESS, GET_PODS_FAILURE],
      authenticated: true,
      endpoint: 'smartPods',
      method: 'GET',
    },
  };
};

export const setPodsSelected = ({locationIds, isSelected}) => {
  return {
    type: SELECT_PODS,
    response: {locationIds, isSelected},
  };
};

export const setDescription = (description) => {
  return {
    type: SET_DESCRIPTION,
    response: description,
  };
};

export const setCommandPayload = (commandPayload) => {
  return {
    type: SET_COMMAND_PAYLOAD,
    response: commandPayload,
  };
};

export const setSelectedCommandIndex = (selectedCommandIndex) => {
  return {
    type: SET_SELECTED_COMMAND_INDEX,
    response: selectedCommandIndex,
  };
};

export const setActiveStep = (activeStep) => {
  return {
    type: SET_ACTIVE_STEP,
    response: activeStep,
  };
};

export const setCustomCommand = (customCommand) => {
  return {
    type: SET_CUSTOM_COMMAND,
    response: customCommand,
  };
};

export const sendCommand = (payload) => {
  return {
    [CALL_API]: {
      types: [SEND_COMMAND_REQUEST, SEND_COMMAND_SUCCESS, SEND_COMMAND_FAILURE],
      authenticated: true,
      endpoint: 'iot/commandSets',
      method: 'POST',
      payload,
    },
  };
};

export const getIotLocations = () => {
  return {
    [CALL_API]: {
      types: [GET_IOT_LOCATIONS_REQUEST, GET_IOT_LOCATIONS_SUCCESS, GET_IOT_LOCATIONS_FAILURE],
      authenticated: true,
      endpoint: 'iot/locations',
      method: 'GET',
    },
  };
};

export const getIotLocation = (locationId) => {
  return {
    [CALL_API]: {
      types: [GET_IOT_LOCATION_REQUEST, GET_IOT_LOCATION_SUCCESS, GET_IOT_LOCATION_FAILURE],
      authenticated: true,
      endpoint: `iot/locations/${locationId}`,
      method: 'GET',
    },
  };
};