import { DialogContainerComponent } from '../../layout/dialogContainer.component';
import { SUBMISSION_DECLINE_REASON } from '../../../types/location.types';
import { SelectFormComponent } from '../../layout/singleSelectForm.component';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const SubmissionDeclineReasonLabels = [
  {
    label: 'Mamava',
    elements:
      [
        { key: SUBMISSION_DECLINE_REASON.MAMAVA_AMAZON_POD, label: 'Amazon Pod' },
        { key: SUBMISSION_DECLINE_REASON.MAMAVA_MILITARY_POD, label: 'Military Pod' },
        { key: SUBMISSION_DECLINE_REASON.MAMAVA_AIRPORT, label: 'Airport' },
        { key: SUBMISSION_DECLINE_REASON.MAMAVA_ENTERPRISE_POD, label: 'Enterprise Pod' },
        { key: SUBMISSION_DECLINE_REASON.MAMAVA_OTHER, label: 'Other' },
        { key: SUBMISSION_DECLINE_REASON.MAMAVA_NOT_ON_APP, label: 'Not on App' },
      ],
  },
  { key: SUBMISSION_DECLINE_REASON.NOT_ENOUGH_INFORMATION, label: 'Not Enough Information' },
  { key: SUBMISSION_DECLINE_REASON.BATHROOM, label: 'Bathroom' },
  { key: SUBMISSION_DECLINE_REASON.DUPLICATE_LOCATION, label: 'Duplicate Location' },
  { key: SUBMISSION_DECLINE_REASON.LOCATION_NOT_ALLOWED, label: 'Location not allowed' },
  { key: SUBMISSION_DECLINE_REASON.BUSINESS_LOCATION, label: 'Business location'},
];

export const LocationDeclineDialogComponent = ({ isOpen, onClose, onSubmit }) => {

  const [selectedOption, setSelectedOption] = useState();

  const handleSubmit = () => {
    onSubmit(selectedOption);
  };

  return (
    <DialogContainerComponent isOpen={isOpen} isSubmitDisabled={!selectedOption} onClose={onClose} onSubmit={handleSubmit} title="Select reason for declining the location">
      <SelectFormComponent inputValues={SubmissionDeclineReasonLabels} onSelectChange={key => setSelectedOption(key)} selectedValue={selectedOption}/>
    </DialogContainerComponent>
  );
};

LocationDeclineDialogComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
