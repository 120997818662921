import { createTheme, adaptV4Theme } from '@mui/material/styles';
import Colors from './styles/colors';

export default createTheme(adaptV4Theme({
  palette: {
    primary: {
      light: Colors.primary.light,
      main: Colors.primary.main,
      dark: Colors.primary.dark,
      contrastText: Colors.primary.contrastText,
    },
    secondary: {
      light: Colors.secondary.light,
      main: Colors.secondary.main,
      dark: Colors.secondary.dark,
      contrastText: Colors.secondary.contrastText,
    },
    title: {
      light: Colors.white,
      main: Colors.white,
      dark: Colors.white,
      contrastText: Colors.secondary.contrastText,
    },
  },
  typography: {
    fontSize: 14,
    // Use the system font instead of the default Roboto font.
    fontFamily: 'GothamRounded-Book, \'Roboto\', Arial, Helvetica, sans-serif',
    caption: {
      fontFamily: 'Open Sans',
    },
  },
  
  components: {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: 0,
        },
        title: {
          color: Colors.primary.main,
        },
      },
    },
  },
}));
