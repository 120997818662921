import { CALL_API } from '../../middleware/api';

export const GET_LOCATION_PREVIEW_REQUEST = 'GET_LOCATION_PREVIEW_REQUEST';
export const GET_LOCATION_PREVIEW_SUCCESS = 'GET_LOCATION_PREVIEW_SUCCESS';
export const GET_LOCATION_PREVIEW_FAILURE = 'GET_LOCATION_PREVIEW_FAILURE';

export const getLocationPreview = (ticketId) => {
  return {
    [CALL_API]: {
      types: [GET_LOCATION_PREVIEW_REQUEST, GET_LOCATION_PREVIEW_SUCCESS, GET_LOCATION_PREVIEW_FAILURE],
      authenticated: true,
      endpoint: `hubspot/tickets/${ticketId}/location`,
      method: 'GET',
    },
  };
};