import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import classnames from 'classnames';

import { GET_IOT_LOCATION_FAILURE, getIotLocation } from './iotCommand.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';
import CommandResponses from './commandDetails/commandResponses.component';
import LoadingOverlay from '../layout/loadingOverlay/loadingOverlay.component';
import LocationDetails from './commandDetails/locationDetails.component';

const LocationDetailsContainer = ({
  classes,
  match,
  selectedLocation,
  isLoading,
  handleToastMessage,
  setPageTitle,
  getIotLocation,
}) => {

  // Set Page Title
  useEffect(() => {
    setPageTitle('IoT Location Details');
  }, []);

  // Fetch Location
  useEffect(() => {
    const fetchData = async () => {
      const id = match.params.id;
      
      const response = await getIotLocation(id);
      if (response.type === GET_IOT_LOCATION_FAILURE) {
        handleToastMessage(`Error getting location: ${response.messages[0]}`, true);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (selectedLocation === null) {
    return (
      <div className={classes.container}>
        <Typography variant="body1">Could Not Load Location</Typography>
      </div>
    );
  }

  return (    
    <div className={classnames([classes.container, 'page-padding-bottom'])}>
      
      <LocationDetails location={selectedLocation} />

      <CommandResponses
        commandResponses={selectedLocation.commandResponses}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedLocation: state.iotCommand.get('selectedLocation'),
    isLoading: state.iotCommand.get('isLoading'),
  };
};

LocationDetailsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getIotLocation: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
};

const styles = () => ({
  container: {
    width: 'calc(100% - 24px)',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 'calc(100% - 13rem)',
  },
});

const prepareForExport = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      getIotLocation,
      handleToastMessage,
      setPageTitle,
    }
  ),
);

export default prepareForExport(LocationDetailsContainer);