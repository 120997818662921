import {isNil} from 'lodash';

export const convertToCSV = (objArray, columns) => {
  let str = '';

  if (columns) {
    columns.forEach((column) => {       
      if (str !== '') {
        str += ',';
      }
      str += column.label;
    });
  }

  str += '\r\n';

  for (let i = 0; i < objArray.length; i++) {
    let line = '';
    if (columns) {
      for (let c = 0; c < columns.length; c++) {
        if (line !== '') {
          line += ',';
        }
  
        line += `"${!isNil(objArray[i][columns[c].id]) ? objArray[i][columns[c].id] : ''}"`;
      }
    } else {
      for (const index in objArray[i]) {
        if (line !== '') line += ',';
  
        line += objArray[i][index];
      }
    }

    str += `${line}\r\n`;
  }

  return str;
};

export const exportCSVFile = (columns, items, fileTitle) => {

  const csv = convertToCSV(items, columns);

  const exportedFilenmae = `${fileTitle || 'export'}.csv`;

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};