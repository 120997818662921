import { Icon, TableCell, TableRow } from '@mui/material';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import CalendarToday from '@mui/icons-material/CalendarToday';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import moment from 'moment';

import { DATE_FORMAT } from '../../utilities/dateUtils';
import { LOCATION_TYPES } from '../../types/location.types';
import DataTable from '../layout/dataTable/dataTable.component';

const TABLE_PADDING = 'dense';

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'createdDate', numeric: false, padding: TABLE_PADDING, label: 'Submitted Date', sortable: true, filtering: true },
  { id: 'locationName', numeric: false, padding: TABLE_PADDING, label: 'Location Name', sortable: true, filtering: true },
  { id: 'formattedLocationType', numeric: false, padding: TABLE_PADDING, label: 'Location Type', sortable: true, filtering: true },
  { id: 'rating', numeric: false, padding: TABLE_PADDING, label: 'Rating', sortable: true, filtering: true },
  { id: 'review', numeric: false, padding: TABLE_PADDING, label: 'Review', sortable: true, filtering: true },
  { id: 'status', numeric: false, padding: TABLE_PADDING, label: 'Status', sortable: true, filtering: true },
];

function SubmittedReviews ({ reviews, history }) {
  
  const renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          hover
          onClick={() => {
            const queryParams = new URLSearchParams();
            queryParams.set('tab', 'REVIEWS');
            history.push({
              pathname: `locations/${row.locationId}`,
              search: queryParams.toString(),
            });
          }}
        >
          <TableCell align="left" width={180} padding={TABLE_PADDING}>{row.formattedCreatedDate}</TableCell>
          <TableCell align="left" width={200} padding={TABLE_PADDING}>{row.locationName}</TableCell>
          <TableCell align="left" padding={TABLE_PADDING} >
            {row.formattedLocationType}
            {row.isSchedulable && (
              <Icon>
                <CalendarToday  style={{color: 'blue'}}/>
              </Icon>
            )}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>{row.rating}</TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>{row.review}</TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>{row.status}</TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const formattedData = useMemo(
    () => reviews.map((row) => {
      row = { ...row }; 
      row.formattedCreatedDate = row.createdDate ? moment(row.createdDate).format(DATE_FORMAT.DATE_TIME) : 'None';
      row.formattedLocationType = row.locationType === LOCATION_TYPES.MAMAVA ? 'Mamava Pod' : 'Lactation Space';
      return row;
    }), [reviews]);
  
  return (
    <DataTable
      data={formattedData}
      title={''}
      columns={columns}
      renderRow={renderRow}
      disableSearch
    />
  );
}

SubmittedReviews.propTypes = {
  reviews: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withRouter,
);

export default prepareForExport(SubmittedReviews);
