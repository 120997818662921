import { CALL_API } from '../../middleware/api';

export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';

export const GET_LOCATION_ENV_STATS_REQUEST = 'GET_LOCATION_ENV_STATS_REQUEST';
export const GET_LOCATION_ENV_STATS_SUCCESS = 'GET_LOCATION_ENV_STATS_SUCCESS';
export const GET_LOCATION_ENV_STATS_FAILURE = 'GET_LOCATION_ENV_STATS_FAILURE';


export const GET_LOCATION_STATUS_REQUEST = 'GET_LOCATION_STATUS_REQUEST';
export const GET_LOCATION_STATUS_SUCCESS = 'GET_LOCATION_STATUS_SUCCESS';
export const GET_LOCATION_STATUS_FAILURE = 'GET_LOCATION_STATUS_FAILURE';

export const GET_LOCATION_OCCUPANCIES_REQUEST = 'GET_LOCATION_OCCUPANCIES_REQUEST';
export const GET_LOCATION_OCCUPANCIES_SUCCESS = 'GET_LOCATION_OCCUPANCIES_SUCCESS';
export const GET_LOCATION_OCCUPANCIES_FAILURE = 'GET_LOCATION_OCCUPANCIES_FAILURE';

export const GET_LOCATION_UNLOCKS_REQUEST = 'GET_LOCATION_UNLOCKS_REQUEST';
export const GET_LOCATION_UNLOCKS_SUCCESS = 'GET_LOCATION_UNLOCKS_SUCCESS';
export const GET_LOCATION_UNLOCKS_FAILURE = 'GET_LOCATION_UNLOCKS_FAILURE';

export const GET_LOCATION_BOOTUPS_REQUEST = 'GET_LOCATION_BOOTUPS_REQUEST';
export const GET_LOCATION_BOOTUPS_SUCCESS = 'GET_LOCATION_BOOTUPS_SUCCESS';
export const GET_LOCATION_BOOTUPS_FAILURE = 'GET_LOCATION_BOOTUPS_FAILURE';

export const SET_FLEET_OVERVIEW_LOCATION_FILTER = 'SET_FLEET_OVERVIEW_LOCATION_FILTER';
export const SET_FLEET_OVERVIEW_COMPANY_FILTER = 'SET_FLEET_OVERVIEW_COMPANY_FILTER';

export const TEMP_RANGE = {
  MAX: 80,
  MAX_ACCEPTABLE: 75,
  MIN_ACCEPTABLE: 60,
  MIN: 55,
};

export const HUMID_RANGE = {
  MAX: 80,
  MAX_ACCEPTABLE: 60,
  MIN_ACCEPTABLE: 30,
  MIN: 20,
};

// Excellent, > -65, Good, -65 to -75, Fair -75 to -85, Poor < -85
export const NETWORK_TYPE = {
  LTE: 'LTE',
  WIFI: 'WIFI',
};

// PCDv1: Excellent, > -65, Good, -65 to -75, Fair -75 to -85, Poor < -85
export const SIGNAL_RANGE_PCDv1 = {
  MIN_EXCELLENT: -65,
  MIN_GOOD: -75,
  MIN_FAIR: -85,
};

// PCDv2: Excellent, > -85, Good, -85 to -95, Fair -95 to -105, Poor < -105
export const SIGNAL_RANGE_PCDv2 = {
  MIN_EXCELLENT: -85,
  MIN_GOOD: -95,
  MIN_FAIR: -105,
};

// Excellent, > -65, Good, -65 to -75, Fair -75 to -85, Poor < -85
export const SIGNAL_QUALITY = {
  EXCELLENT: 'Excellent',
  GOOD: 'Good',
  FAIR: 'Fair',
  POOR: 'Poor',
};

export const ALERT_STATUS = {
  NEW: 'NEW',
  IN_PROCESS: 'IN PROCESS',
  ADDRESSED: 'ADDRESSED',
  EXPIRED: 'EXPIRED',
  RESOLVED: 'RESOLVED',
};

export const ALERT_TYPE = {
  LIGHT: 'Light Status',
  FAN: 'Fan Status',
  ENV: 'Environmental Status',
  LOCK: 'Lock Status',
};

export const CAUSE_OF_LAST_RESET_NAMES = {
  0: 'Power Reset',
  1: 'HARD Reset',
  2: 'WDT Reset',
  3: 'DEEPSLP Reset',
  4: 'SOFT Reset',
  5: 'BRNT Reset',
};

export const CONNECTION_STATUS = {
  ONLINE: 'Online',
  OFFLINE: 'Offline',
  NEVER_CONNECTED: 'Never Connected',
};

export const CONNECTION_EVENT_TYPES = {
  PINGREQ: 'PINGREQ',
  CONNECT: 'CONNECT',
  DISCONNECT: 'DISCONNECT',
};

export const CONNECTION_EVENT_TEXT = {
  PINGREQ: 'Last Ping',
  CONNECT: 'Connected',
  DISCONNECT: 'Disconnected',
};

export const FLEET_OVERVIEW_TABS = {
  ALERTS: 'ALERTS',
  CONNECTION: 'CONNECTION',
  SOFTWARE: 'SOFTWARE',
};

export const getMonitoringLocations = () => {
  return {
    [CALL_API]: {
      types: [GET_LOCATIONS_REQUEST, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAILURE],
      authenticated: true,
      endpoint: 'monitoring/locations',
      method: 'GET',
    },
  };
};

export const getLocationStatus = (locationId) => {
  return {
    [CALL_API]: {
      types: [GET_LOCATION_STATUS_REQUEST, GET_LOCATION_STATUS_SUCCESS, GET_LOCATION_STATUS_FAILURE],
      authenticated: true,
      endpoint: `monitoring/locations/${locationId}/status`,
      method: 'GET',
    },
  };
};

export const getLocationOccupancies = (locationId, fromDate, toDate) => {
  return {
    [CALL_API]: {
      types: [GET_LOCATION_OCCUPANCIES_REQUEST, GET_LOCATION_OCCUPANCIES_SUCCESS, GET_LOCATION_OCCUPANCIES_FAILURE],
      authenticated: true,
      endpoint: `monitoring/locations/${locationId}/occupancies?fromDate=${fromDate}&toDate=${toDate}`,
      method: 'GET',
    },
  };
};

export const getLocationUnlocksHistory = (locationId, fromDate, toDate) => {
  return {
    [CALL_API]: {
      types: [GET_LOCATION_UNLOCKS_REQUEST, GET_LOCATION_UNLOCKS_SUCCESS, GET_LOCATION_UNLOCKS_FAILURE],
      authenticated: true,
      endpoint: `monitoring/locations/${locationId}/unlocksHistory?fromDate=${fromDate}&toDate=${toDate}`,
      method: 'GET',
    },
  };
};

export const getLocationBootups = (locationId, fromDate, toDate) => {
  return {
    [CALL_API]: {
      types: [GET_LOCATION_BOOTUPS_REQUEST, GET_LOCATION_BOOTUPS_SUCCESS, GET_LOCATION_BOOTUPS_FAILURE],
      authenticated: true,
      endpoint: `monitoring/locations/${locationId}/bootups?fromDate=${fromDate}&toDate=${toDate}`,
      method: 'GET',
    },
  };
};

export const setFleetOverviewLocationFilter = (locationFilter) => {
  return {
    type: SET_FLEET_OVERVIEW_LOCATION_FILTER,
    response: locationFilter,
  };
};

export const setFleetOverviewCompanyFilter = (companyFilter) => {
  return {
    type: SET_FLEET_OVERVIEW_COMPANY_FILTER,
    response: companyFilter,
  };
};
