import { Button } from '@mui/material';
import { Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel } from '@mui/material';
import { GoogleMap, Marker, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';

import { DEFAULT_LOCATION } from '../../../utilities/googleMapsUtils';
import Colors from '../../../styles/colors';
const containerStyle = {
  height: '400px',
};
const libraries = ['places'];
export const MapComponent = ({ latitude = DEFAULT_LOCATION.latitude, longitude = DEFAULT_LOCATION.longitude, onClick, onMapMounted, onPlaceSelected, isMarkerShown }) => {

  const [map, setMap] = React.useState(null);
  const [searchBox, setSearchBox] = React.useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDjMeaEphO4dLuUTW88rTsSR7Vnmr1VBPA',
    libraries,
  });

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
    onMapMounted();
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    if (places && places.length) {
      const place = places[0];
      const bounds = place.geometry.viewport;
      onClick({ latLng: place.geometry.location });
      map.fitBounds(bounds);
      onPlaceSelected(place);
    }
  };
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={{
        lat: latitude,
        lng: longitude,
      }}
      defaultCenter={{
        lat: latitude,
        lng: longitude,
      }}
      onClick={onClick}
      options={{ draggableCursor: 'crosshair', zoom: 18 }}
    >
      <StandaloneSearchBox
        onLoad={setSearchBox}
        onPlacesChanged={onPlacesChanged}
      >
        <input
          type="text"
          placeholder="Search..."
          style={{
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '240px',
            height: '32px',
            borderRadius: '3px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            outline: 'none',
            textOverflow: 'ellipses',
            position: 'absolute',
            bottom: 20,
            left: 150,
          }}
        />
      </StandaloneSearchBox>

      {isMarkerShown && (
        <Marker
          position={{
            lat: Number(latitude),
            lng: Number(longitude),
          }}
        />
      )}
    </GoogleMap>
  ) : <></>;
};
MapComponent.propTypes = {
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
  onMapMounted: PropTypes.func.isRequired,
  onPlaceSelected: PropTypes.func.isRequired,
  isMarkerShown: PropTypes.bool.isRequired,
};
const MyMapComponent = MapComponent;

class MapDialog extends Component {
  constructor(props) {
    super(props);
    this.placesRef = React.createRef();
  }

  state = {
    updateAddress: false,
    updateGooglePlace: true,
    latitude: this.props.latitude || 44.4732219,
    longitude: this.props.longitude || -73.21788600000002,
    selectedPlace: {},
  };

  handleUpdate = async () => {
    let address = null;
    if (this.state.updateAddress) {
      address = await this.getAddress();
    }
    let selectedPlaceId = '';
    if (this.state.updateGooglePlace) {
      selectedPlaceId = this.state.selectedPlace.place_id;
      // If place is not set, get it!
      if (!this.state.selectedPlace.place_id) {
        const selectedPlace = await this.placeLookup();
        selectedPlaceId = selectedPlace.place_id;
      }
    }

    this.props.handleUpdate(this.state.latitude, this.state.longitude, address, selectedPlaceId);
    this.props.handleClose();
  };

  handleMapClick = event => {
    this.setState({ selectedPlace: {} });
    this.setState({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    });
  };

  handleMapMounted = () => {
    // UNCOMMENT TO ENABLE GOOGLE PLACE LOOKUP
    //   this.placesService = new window.google.maps.places.PlacesService(this.placesRef.current);
  };

  getAddress = () => {
    let geocoder = new window.google.maps.Geocoder();
    let latlng = { lat: this.state.latitude, lng: this.state.longitude };

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            resolve(results[0]);
          } else {
            resolve({});
          }
        } else {
          resolve({});
        }
      });
    });
  };

  handlePlaceSelected = place => {
    this.setState({ selectedPlace: place });
  };

  placeLookup = () => {
    return Promise.resolve({});
    // UNCOMMENT TO ENABLE GOOGLE PLACE LOOKUP
    //   this.setState({selectedPlace: {}});

    //   return new Promise((resolve, reject) => {
    //     this.placesService.nearbySearch({
    //       location: new window.google.maps.LatLng(this.state.latitude, this.state.longitude),
    //       radius: 5,
    //       type: 'point_of_interest',
    //     }, (results, status) => {
    //       if (status === window.google.maps.places.PlacesServiceStatus.OK) {
    //         this.handlePlaceSelected(results[0]);
    //         resolve(results[0]);
    //       } else {
    //         resolve({});
    //       }
    //     });
    //   });
  };

  handlePlaceCheckboxChange = event => {
    this.setState({ updateGooglePlace: event.target.checked });
  };

  handleAddressCheckboxChange = event => {
    this.setState({ updateAddress: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.dialogWrapper}>
        <Dialog
          open={this.props.isOpen}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.dialogPaper }}
        >
          <DialogContent>
            <MyMapComponent
              isMarkerShown
              latitude={this.state.latitude}
              longitude={this.state.longitude}
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDjMeaEphO4dLuUTW88rTsSR7Vnmr1VBPA"
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height: '400px' }} />}
              mapElement={<div style={{ height: '100%' }} />}
              onClick={this.handleMapClick}
              onPlaceSelected={this.handlePlaceSelected}
              onMapMounted={this.handleMapMounted}
            />
            <div>
              {this.state.selectedPlace.name &&
                <div>
                  Google Place: {this.state.selectedPlace.name}
                </div>
              }
              <div ref={this.placesRef} />
            </div>
          </DialogContent>
          <DialogActions>
            <div style={{ paddingRight: 25 }}>
              {/* UNCOMMENT TO ENABLE GOOGLE PLACE LOOKUP */}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.updateGooglePlace}
                    onChange={this.handlePlaceCheckboxChange}
                  />
                }
                label="Update Google Place"
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.updateAddress}
                    onChange={this.handleAddressCheckboxChange}
                  />
                }
                label="Update address"
              />
            </div>
            <Button onClick={this.props.handleClose} color="primary" variant="contained">
              Cancel
            </Button>
            <Button onClick={() => this.handleUpdate()} color="primary" variant="contained">
              Post
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const styles = theme => ({
  dialogWrapper: {
    position: 'absolute',
    width: '50vh',
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: 'none',
    fontWeight: 'normal',
    border: 0,
    fontSize: 16,
  },
  speedDial: {
    position: 'absolute',
    left: theme.spacing(3),
    top: '0px',
  },
  root: {
    // Mimics the default input display property used by browsers for an input.
    display: 'inline-flex',
    position: 'relative',
    fontFamily: theme.typography.caption.fontFamily,
  },
  textFieldFocus: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: 'none',
    fontWeight: 'normal',
    border: 0,
  },
  dialogPaper: {
    width: '80vw',
  },
  underline: {
    color: Colors.secondary.main,
    borderBottomColor: Colors.secondary.main,
    '&:after': {
      // The source seems to use this but it doesn't work
      borderBottomColor: Colors.secondary.main,
    },
  },
  menu: {
    width: 200,
  },
});

MapDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  classes: PropTypes.object,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(MapDialog);
