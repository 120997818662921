import { DialogContainerComponent } from '../../layout/dialogContainer.component';
import { SUBMISSION_DELETE_REASON } from '../../../types/location.types';
import { SelectFormComponent } from '../../layout/singleSelectForm.component';
import { compose } from 'redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const SubmissionDeleteReasonLabels = [
  { key: SUBMISSION_DELETE_REASON.ROOM_NO_LONGER_EXIST, label: 'Space no longer exists' },
  { key: SUBMISSION_DELETE_REASON.BUILDING_IS_CLOSED, label: 'Building is closed' },
  { key: SUBMISSION_DELETE_REASON.CREATED_IN_ERROR, label: 'Created in error' },
  { key: SUBMISSION_DELETE_REASON.OTHER, label: 'Other' },
];

const LocationDeleteDialog = ({ isOpen, onClose, onSubmit, classes }) => {

  const [selectedOption, setSelectedOption] = useState();

  const handleSubmit = () => {
    onSubmit(selectedOption);
  };

  return (
    <DialogContainerComponent isOpen={isOpen} isSubmitDisabled={!selectedOption} onClose={onClose} onSubmit={handleSubmit} title="Select reason for deleting the location">
      <SelectFormComponent inputValues={SubmissionDeleteReasonLabels} onSelectChange={key => setSelectedOption(key)} selectedValue={selectedOption} />
    </DialogContainerComponent>
  );
};

const styles = () => ({
  radioGroup: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: 30,
  },
});

LocationDeleteDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles),
);

const LocationDeleteDialogComponent = prepareForExport(LocationDeleteDialog);
export { LocationDeleteDialogComponent };