import { CircularProgress, Grid } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { getLocationOccupancies } from '../../monitoring.actions';
import { getUTCDateRange } from '../../../../utilities/dateUtils';
import DateRangePicker from '../../../layout/dateRangePicker.component';
import OccupanciesTable from './occupanciesTable.component';

const Component = ({
  isLoadingOccupancies,
  selectedLocation,
  selectedLocationOccupancies,
  getLocationOccupancies,
}) => {

  const handleUpdateRange = ({fromDate, toDate}) => {
    const utcDateRange = getUTCDateRange(fromDate, toDate);
    getLocationOccupancies(selectedLocation.id, utcDateRange.fromDate, utcDateRange.toDate);
  };

  return (
    <Fragment>
      
      <DateRangePicker defaultRange={30} handleUpdateRange={handleUpdateRange} />
    
      {isLoadingOccupancies && (
        <Grid container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!isLoadingOccupancies && (
        <OccupanciesTable occupancies={selectedLocationOccupancies} locationTimezone={selectedLocation.ianaTimezone}  />
      )}
    </Fragment>
  );
};


const mapStateToProps = (state) => {
  return {
    selectedLocation: state.location.get('selectedLocation'),
    isLoadingOccupancies: state.monitoring.get('isLoadingOccupancies'),
    selectedLocationOccupancies: state.monitoring.get('selectedLocationOccupancies'),
  };
};

Component.propTypes = {
  isLoadingOccupancies: PropTypes.bool.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  selectedLocationOccupancies: PropTypes.array.isRequired,

  getLocationOccupancies: PropTypes.func.isRequired,
};


const prepareForExport = compose(
  connect(
    mapStateToProps,
    {
      getLocationOccupancies,
    },
  ),
);

const Occupancies = prepareForExport(Component);

export default Occupancies;