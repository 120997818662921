import {
  Fab,
  Grid,
  Typography,
} from '@mui/material';
import { compose } from 'redux';
import withStyles from '@mui/styles/withStyles';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import LockIcon from '@mui/icons-material/Lock';
import OpacityIcon from '@mui/icons-material/Opacity';
import PowerIcon from '@mui/icons-material/Power';
import PropTypes from 'prop-types';
import React from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Colors from '../../../styles/colors';

function AmenitiesSection({ disabled, onChange, classes, selectedLocation }) {

  const handleToggle = (name) => (event) => {
    onChange({
      target: {
        name,
        value: !selectedLocation[name],
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Amenities</Typography>
      </Grid>
      <Grid item xs={12}>
        <Fab
          variant="extended"
          aria-label="outlet"
          onClick={handleToggle('amenityOutlet')}
          className={selectedLocation.amenityOutlet ? classes.amenityOn : classes.amenityOff}
          disabled={disabled}
        >
          <PowerIcon className={classes.extendedIcon} />
        Outlet
        </Fab>
        <Fab
          variant="extended"
          aria-label="lock"
          onClick={handleToggle('amenityLock')}
          className={selectedLocation.amenityLock ? classes.amenityOn : classes.amenityOff}
          disabled={disabled}
        >
          <LockIcon className={classes.extendedIcon} />
        Lock
        </Fab>
        <Fab
          variant="extended"
          aria-label="sink"
          onClick={handleToggle('amenitySink')}
          className={selectedLocation.amenitySink ? classes.amenityOn : classes.amenityOff}
          disabled={disabled}
        >
          <OpacityIcon className={classes.extendedIcon} />
        Sink
        </Fab>
        <Fab
          variant="extended"
          aria-label="privacy"
          onClick={handleToggle('amenityPrivacy')}
          className={selectedLocation.amenityPrivacy ? classes.amenityOn : classes.amenityOff}
          disabled={disabled}
        >
          <VisibilityOffIcon className={classes.extendedIcon} />
        Privacy
        </Fab>
        <Fab
          variant="extended"
          aria-label="space"
          onClick={handleToggle('amenitySpace')}
          className={selectedLocation.amenitySpace ? classes.amenityOn : classes.amenityOff}
          disabled={disabled}
        >
          <ChildFriendlyIcon className={classes.extendedIcon} />
        Space
        </Fab>
      </Grid>
    </Grid>
  );
}

const styles = (theme) => ({
  amenityOff: {
    margin: 5,
    opacity: 0.5,
  },
  amenityOn: {
    color: Colors.primary.main,
    backgroundColor: Colors.white,
    margin: 5,
    '&:disabled': {
      color: Colors.primary.main,
      backgroundColor: Colors.white,
      margin: 5,
      borderColor: Colors.primary.light,
      borderWidth: 1,
      borderStyle: 'solid',
    },
  },
});

AmenitiesSection.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
);

export default prepareForExport(AmenitiesSection);