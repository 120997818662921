import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import React, { Component, useEffect } from 'react';

import LocationPin from '../../../images/LocationPin_NonMamava.png';
const containerStyle = {
  height: '300px',
};

const MapComponent = ({ latitude, longitude, onClick, isMarkerShown }) => {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDjMeaEphO4dLuUTW88rTsSR7Vnmr1VBPA',
    libraries: ['places'],
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: latitude,
        lng: longitude,
      }}
      defaultCenter={{
        lat: Number(latitude),
        lng: Number(longitude),
      }}
      onClick={onClick}
      options={{
        gestureHandling: 'none',
        draggableCursor: 'crosshair',
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        scaleControl: false,
        zoomControl: false,
        fullscreenControl: false,
        zoom: 15,
      }}
    >
      {isMarkerShown && (
        <Marker
          icon={LocationPin}
          position={{
            lat: Number(latitude),
            lng: Number(longitude),
          }}
        />
      )}
    </GoogleMap>
  ) : <></>;
};
MapComponent.propTypes = {
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
  isMarkerShown: PropTypes.bool.isRequired,
};
export default ((MapComponent));
