import 'chartjs-plugin-annotation';
import { Chart } from 'react-chartjs-2';
import { CircularProgress, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { CHART_TYPE, getMapChartConfig } from '../../../utilities/chartUtils';

// NOTE: With the way that Chart instances are managed here, it is not currently possible
// to display more than one map on a page. This could be improved in the future.

const MapChartComponent = ({
  data, isLoading, classes, color, onClick, getPointLabel,
}) => {

  const chartCanvas = useRef(null);
  const [chart, setChart] = useState(null);

  const destroyOldMapInstances = () => {
    Chart.helpers.each(Chart.instances, function(instance) {
      if (instance.config.type === CHART_TYPE.CHOROPLETH) {
        instance.destroy();
      }
    });
  };

  const configChart = async (data) => {
    const chartEl = chartCanvas.current ? chartCanvas.current.getContext('2d') : null;
    if (chartEl) {
      const config = await getMapChartConfig({data, color, getPointLabel});
      destroyOldMapInstances();
      setChart(new Chart(chartEl, config));
    }
  };

  useEffect(() => {
    if (!isLoading) {
      configChart(data);
    }

  }, [data, color, isLoading]);
  
  const handleChartClick = (event) => {
    const elementAtEvent = chart.getElementAtEvent(event);
    if (onClick && elementAtEvent.length > 0) {
      const dataIndex = elementAtEvent[0]._index;
      const dataItem = elementAtEvent[0]._chart.data.datasets[0].data[dataIndex];
      if (elementAtEvent[0].feature && dataItem.value > 0) {
        onClick(elementAtEvent[0].feature.properties.name);
      }
    }
  };

  return (
    <div>
      {isLoading && (
        <Grid
          container
          justifyContent="center"
          alignItems="center" 
          className={classes.loadingContainer}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!isLoading &&
        <canvas ref={chartCanvas} onClick={handleChartClick}/>
      }
    </div>
  );
};

const styles = () => ({
  loadingContainer: {
    minHeight: 500,
  },
});

MapChartComponent.propTypes = {
  color: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,

  onClick: PropTypes.func,
  getPointLabel: PropTypes.func,
};

const prepareForExport = compose(
  withStyles(styles),
);

const MapChart = prepareForExport(MapChartComponent);

export default MapChart;