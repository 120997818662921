import { Checkbox, TableCell, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DataTable from '../../layout/dataTable/dataTable.component';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import moment from 'moment';

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'name', numeric: false, padding: 'dense', label: 'Location Name', sortable: true, filtering: true },
  { id: 'hubspotCompanyName', numeric: false, padding: 'dense', label: 'Hubspot Company', sortable: true, filtering: true },
  { id: 'addressOne', numeric: false, padding: 'dense', label: 'Address', sortable: true, filtering: true },
  { id: 'podSoftwareVersion', numeric: true, padding: 'dense', label: 'Version', sortable: true, filtering: true },
  { id: 'podDeviceSerial', numeric: false, padding: 'dense', label: 'POD Serial', sortable: true, filtering: true },
  { id: 'podHardwareIdentifier', numeric: false, padding: 'dense', label: 'PCD MAC ID', sortable: true, filtering: true },
  { id: 'podModemSerial', numeric: false, padding: 'dense', label: 'PCD Serial', sortable: true, filtering: true },
  { id: 'lastEvent', numeric: false, padding: 'dense', label: 'Last Event', sortable: true, filtering: false },
];

const PodTable = ({locations, classes, handleSelectPods}) => {

  const [isAllSelected, setIsAllSelected] = useState(false);

  const renderRow = (location, i) => {

    return (
      <TableRow 
        key={location.id}
        selected={location.isSelected}
        hover={!location.isDisabled}
        className={location.isDisabled ? classes.disabledRow : null}
        onClick={() => {
          if (!location.isDisabled) {
            handleSelectPods({locationIds: [location.id], isSelected: !(location.isSelected)});
          }
        }}
      >
        <TableCell padding="none">
          {!location.isDisabled && (
            <Checkbox
              className={classes.checkbox} 
              checked={location.isSelected}
            />
          )}
        </TableCell>
        <TableCell padding={columns[0].padding}>{location.name}</TableCell>
        <TableCell padding={columns[1].padding}>{location.hubspotCompanyName}</TableCell>
        <TableCell padding={columns[2].padding}>{location.addressOne}</TableCell>
        <TableCell padding={columns[3].padding}>{location.podSoftwareVersion}</TableCell>
        <TableCell padding={columns[4].padding}>{location.podDeviceSerial}</TableCell>
        <TableCell padding={columns[5].padding}>{location.podHardwareIdentifier}</TableCell>
        <TableCell padding={columns[6].padding}>{location.podModemSerial}</TableCell>
        <TableCell padding={columns[7].padding}>{location.lastEvent ? moment(location.lastEvent).format('MMMM D, YYYY h:mma') : ''}</TableCell>
      </TableRow>
    );
  };

  const formattedData = useMemo(
    () => locations.map((location) => {
      const row = { ...location }; 
      row.lastEvent = location.lastEvent ? location.lastEvent : '';

      return row;
    }),
    [locations]
  );

  return (
    <DataTable
      data={formattedData}
      title=""
      columns={columns}
      renderRow={renderRow}
      disableSearch
      defaultSortOrder="desc"
      defaultSortField="podSoftwareVersion"
      onSelectAll={(newIsSelected, filteredLocations) => {
        handleSelectPods({
          locationIds: filteredLocations.filter((location) => !location.isDisabled).map((location) => location.id),
          isSelected: newIsSelected,
        });
        setIsAllSelected(newIsSelected);
      }}
      isAllSelected={isAllSelected}
      onDataChanged={() => {
        setIsAllSelected(false);
      }}
    />
  );
};

const styles = (theme) => ({
  checkbox: {
    width: 'auto',
    height: 'auto',
  },
  disabledRow: {
    '& td': {
      color: theme.palette.text.disabled,
    },
  },
});


PodTable.propTypes = {
  classes: PropTypes.object.isRequired,
  
  locations: PropTypes.array.isRequired,
  handleSelectPods: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(PodTable);
