import 'chartjs-plugin-annotation';
import { CircularProgress, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { HorizontalBar } from 'react-chartjs-2';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getBarChartConfig } from '../../../utilities/chartUtils';
import Colors from '../../../styles/colors';

const BarChartComp = ({
  classes, xRange, title, data, isLoading, aspectRatio,
}) => {

  const [chartOptions, setChartOptions] = useState(null);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const chartConfig = getBarChartConfig({
      data,
      xRange,
      aspectRatio,
    });
    setChartData(chartConfig.data);
    setChartOptions(chartConfig.options);
  }, [data]);

  return (
    <div className={classes.chartWrapper}>
      {title && (
        <div className={classes.chartHeader}>
          <div className={classes.chartTitle}>{title}</div>
        </div>
      )}
      {isLoading && (
        <Grid container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <HorizontalBar
          data={chartData}
          options={chartOptions}
          height={null}
          width={null}
        />
      )}
    </div>
  );
};

const styles = () => ({
  chartWrapper: {
    flex: 1,
  },
  chartHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'GothamRounded-Book',
  },
  chartTitle: {
    margin: 0,
    color: Colors.primary.main,
    fontSize: '14px',
  },
  chartStat: {
    margin: 0,
    color: Colors.primary.main,
    fontSize: '14px',
  },
});


BarChartComp.propTypes = {
  classes: PropTypes.object.isRequired,
  xRange: PropTypes.array,
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  aspectRatio: PropTypes.number,
};

const prepareForExport = compose(
  withStyles(styles),
);

const BarChart = prepareForExport(BarChartComp);

export default BarChart;