import { isNil } from 'lodash';
import moment from 'moment';

export const DATE_FORMAT = {
  UTC: 'YYYY-MM-DD HH:mm:ss UTC',
  EXPORT: 'YYYY-MM-DD HH:mm:ss',
  DATE_TIME: 'M/D/YYYY h:mm:ss a',
  INPUT: 'YYYY-MM-DD',
  DISPLAY: 'MM/DD/YYYY',
};

export const DEFUALT_TIMEZONE = 'America/New_York';

export const formatDuration = (duration, formatString) => moment.utc(duration.asMilliseconds()).format(formatString);

export const formatDurationHourMinSec = (duration, hmsLabels) => {
  if (isNil(duration)) {
    return null;
  }
  // format() function will make HH wrap back to zero if it goes over 24
  if (Math.round(duration.asHours()) <= 24) {
    if (hmsLabels) {
      return formatDuration(duration, 'HH[h] mm[m] ss[s]');
    }
    return formatDuration(duration, 'HH:mm:ss');
  } else {
    if (hmsLabels) {
      return `${Math.round(duration.asHours())}h ${formatDuration(duration, 'mm[m] ss[s]')}`;
    }
    return `${Math.round(duration.asHours())}:${formatDuration(duration, 'mm:ss')}`;
  }
};

export const getUTCDateRange = (fromDate, toDate) => {
  return {
    fromDate: moment(fromDate).startOf('day').utc().format(DATE_FORMAT.UTC),
    toDate: moment(toDate).endOf('day').utc().format(DATE_FORMAT.UTC),   
  };
};

export const getBrowserTimezone = () => 
  Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : DEFUALT_TIMEZONE;