import { createSelector } from 'reselect';

import { ALERT_STATUS, CONNECTION_STATUS } from './monitoring.actions';
import { LIFECYCLE_STATUS } from '../../types/location.types';
import { isOnline } from '../../utilities/monitoringUtils';

const selectLocationsWithAlerts = (state) => state.monitoring.get('locationsWithAlerts');
const selectLocations = (state) => state.monitoring.get('locations');
const selectFleetOverviewFilters = (state) => state.monitoring.get('fleetOverviewFilters');

const countAlertsByStatus = (locationsWithAlerts, status) =>
  locationsWithAlerts.reduce(
    (locationCount, location) => (
      locationCount + location.alerts.reduce(
        (alertCount, alert) => (alert.status === status ? alertCount + 1 : alertCount), 0
      )
    ), 0
  );

export const selectAlertCountsByStatus = createSelector(
  selectLocationsWithAlerts,
  (locationsWithAlerts) => ({
    [ALERT_STATUS.NEW]: countAlertsByStatus(locationsWithAlerts, ALERT_STATUS.NEW),
    [ALERT_STATUS.IN_PROCESS]: countAlertsByStatus(locationsWithAlerts, ALERT_STATUS.IN_PROCESS),
    [ALERT_STATUS.EXPIRED]: countAlertsByStatus(locationsWithAlerts, ALERT_STATUS.EXPIRED),
    [ALERT_STATUS.ADDRESSED]: countAlertsByStatus(locationsWithAlerts, ALERT_STATUS.ADDRESSED),
  })
);

const filterAlertPodsByStatus = (locationsWithAlerts, status) => locationsWithAlerts.filter(
  (location) => location.alerts.findIndex((alert) => alert.status === status) !== -1
);

// FLEET OVERVIEW SELECTORS

const locationHasFilters = (fleetOverviewFilters, location) => {
  let hasLocation = !fleetOverviewFilters.locationFilter;
  let hasCompany = !fleetOverviewFilters.companyFilter;
  if (location.name && fleetOverviewFilters.locationFilter) {
    const lowercaseLocationFilter = fleetOverviewFilters.locationFilter.toLowerCase();
    const lowercaseLocation = location.name.toLowerCase();
    hasLocation = lowercaseLocation.includes(lowercaseLocationFilter);
  }
  if (location.hubspotCompanyName && fleetOverviewFilters.companyFilter) {
    const lowercaseCompanyFilter = fleetOverviewFilters.companyFilter.toLowerCase();
    const lowercaseCompany= location.hubspotCompanyName.toLowerCase();
    hasCompany = lowercaseCompany.includes(lowercaseCompanyFilter);
  }
  return hasLocation && hasCompany;
};

export const selectSoftwareVersions = createSelector(
  selectLocations,
  (locations) => {
    const softwareVersions = locations.reduce((versions, location) => {
      if (location.podSoftwareVersion && versions.indexOf(location.podSoftwareVersion) === -1) {
        versions.push(location.podSoftwareVersion);
      }
      return versions;
    }, []);
    return softwareVersions.sort().reverse();
  }
);

export const fleetOverviewSelectors = {
  selectPublishedLocations: createSelector(
    selectLocations,
    selectFleetOverviewFilters,
    (locations, fleetOverviewFilters) => locations.filter((location) => {
      let hasFilter = locationHasFilters(fleetOverviewFilters, location);
      return hasFilter && location.lifecycleStatus === LIFECYCLE_STATUS.ACTIVE;
    })
  ),
  selectLocationsByConnection: createSelector(
    selectLocations,
    selectFleetOverviewFilters,
    (locations, fleetOverviewFilters) => ({
      [CONNECTION_STATUS.OFFLINE]: 
        locations.filter((location) => {
          let hasFilter = locationHasFilters(fleetOverviewFilters, location);
          return hasFilter && location.lifecycleStatus === LIFECYCLE_STATUS.ACTIVE && location.firstEventDate && !isOnline(location);
        }),
      [CONNECTION_STATUS.ONLINE]:
        locations.filter((location) => {
          let hasFilter = locationHasFilters(fleetOverviewFilters, location);
          return hasFilter && location.lifecycleStatus === LIFECYCLE_STATUS.ACTIVE && isOnline(location);
        }),
      [CONNECTION_STATUS.NEVER_CONNECTED]:
        locations.filter((location) => {
          let hasFilter = locationHasFilters(fleetOverviewFilters, location);
          return hasFilter && location.lifecycleStatus === LIFECYCLE_STATUS.ACTIVE && !location.firstEventDate;
        }),
    })
  ),
  selectAlertPodsByStatus: createSelector(
    selectLocationsWithAlerts,
    selectFleetOverviewFilters,
    (locationsWithAlerts, fleetOverviewFilters) => ({
      [ALERT_STATUS.NEW]:
        filterAlertPodsByStatus(locationsWithAlerts, ALERT_STATUS.NEW)
          .filter((location) => locationHasFilters(fleetOverviewFilters, location)),
      [ALERT_STATUS.IN_PROCESS]:
        filterAlertPodsByStatus(locationsWithAlerts, ALERT_STATUS.IN_PROCESS)
          .filter((location) => locationHasFilters(fleetOverviewFilters, location)),
      [ALERT_STATUS.EXPIRED]:
        filterAlertPodsByStatus(locationsWithAlerts, ALERT_STATUS.EXPIRED)
          .filter((location) => locationHasFilters(fleetOverviewFilters, location)),
      [ALERT_STATUS.ADDRESSED]:
        filterAlertPodsByStatus(locationsWithAlerts, ALERT_STATUS.ADDRESSED)
          .filter((location) => locationHasFilters(fleetOverviewFilters, location)),
    })
  ),
};
