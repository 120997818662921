import { CircularProgress, Grid } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { getLocationUnlocksHistory } from '../../monitoring.actions';
import { getUTCDateRange } from '../../../../utilities/dateUtils';
import DateRangePicker from '../../../layout/dateRangePicker.component';
import UnlockTable from './unlockTable.component';

const Unlocks = ({
  isLoadingUnlocks,
  selectedLocation,
  selectedLocationUnlocks,
  getLocationUnlocksHistory,
}) => {

  const handleUpdateRange = ({fromDate, toDate}) => {
    const utcDateRange = getUTCDateRange(fromDate, toDate);
    getLocationUnlocksHistory(selectedLocation.id, utcDateRange.fromDate, utcDateRange.toDate);
  };

  return <>
    <DateRangePicker defaultRange={30} handleUpdateRange={debounce(handleUpdateRange, 500)} />
  
    {isLoadingUnlocks && (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    )}
    {!isLoadingUnlocks && (
      <UnlockTable unlocksHistory={selectedLocationUnlocks} />
    )}
  </>;
};


const mapStateToProps = (state) => {
  return {
    selectedLocation: state.location.get('selectedLocation'),
    isLoadingUnlocks: state.monitoring.get('isLoadingUnlocks'),
    selectedLocationUnlocks: state.monitoring.get('selectedLocationUnlocks'),
  };
};

Unlocks.propTypes = {
  isLoadingUnlocks: PropTypes.bool.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  selectedLocationUnlocks: PropTypes.array.isRequired,
  getLocationUnlocksHistory: PropTypes.func.isRequired,
};


const prepareForExport = compose(
  connect(
    mapStateToProps,
    {
      getLocationUnlocksHistory,
    },
  ),
);

export default prepareForExport(Unlocks);