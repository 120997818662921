import { Card, CardContent, CardHeader, CircularProgress, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import moment from 'moment';

import { DATE_FORMAT, formatDurationHourMinSec } from '../../../utilities/dateUtils';
import { getLocationStatus } from '../monitoring.actions';
import StatItem from '../statItem.component';

const BootupStatsComponent = ({selectedLocationStatus, classes, getLocationStatus, selectedLocation, isLoading}) => {
  
  useEffect(() => {
    getLocationStatus(selectedLocation.id);
  }, [selectedLocation.id]);

  const uptime = selectedLocationStatus && selectedLocationStatus.uptime ? moment.duration(selectedLocationStatus.uptime.value, 'seconds') : null;
  let uptimeTitle = 'Unknown';
  if (!isNil(uptime)) {
    uptimeTitle = formatDurationHourMinSec(uptime);
  }

  return (
    <Card className={classes.root}>
      <CardHeader 
        title="Bootup Status"
        subheader={selectedLocation.podBuildDate ? 
          `Install date of ${moment(selectedLocation.podBuildDate).format(DATE_FORMAT.DISPLAY)} (${moment(selectedLocation.podBuildDate).fromNow()})` :
          'No Install Date'}
      />
      <CardContent>
        {isLoading && (
          <Grid container justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
        {!isLoading && (
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} className={classes.statWrapper}>
              <StatItem
                title={selectedLocationStatus && selectedLocationStatus.last_wdt_bootup ? moment(selectedLocationStatus.last_wdt_bootup.timestamp).format(DATE_FORMAT.DATE_TIME) : 'Unknown'}
                subtitle="Last Known WDT Bootup"
                className={classes.statItem}
              />
            </Grid>
            <Grid item xs={12} className={classes.statWrapper}>
              <StatItem
                title={uptimeTitle}
                date={selectedLocationStatus && selectedLocationStatus.uptime ? selectedLocationStatus.uptime.timestamp : null}
                subtitle="POD Uptime since last bootup"
              />
            </Grid>
            <Grid item xs={12} className={classes.statWrapper}>
              <StatItem
                title={selectedLocationStatus && selectedLocationStatus.boot ? selectedLocationStatus.boot.value : 'Unknown'}
                date={selectedLocationStatus && selectedLocationStatus.boot ? selectedLocationStatus.boot.timestamp : null}
                subtitle="Lifetime Bootups Count"
              />
            </Grid>
            <Grid item xs={12} className={classes.statWrapper}>
              <StatItem
                title={selectedLocationStatus && selectedLocationStatus.first_bootup ? moment(selectedLocationStatus.first_bootup.timestamp).format(DATE_FORMAT.DATE_TIME) : 'Unknown'}
                subtitle="First Bootup"
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

const styles = () => ({
  root: {
    flex: 1,
  },
  statWrapper: {
    display: 'flex',
    maxWidth: 350,
  },
});

BootupStatsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  locationStatus: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selectedLocation: state.location.get('selectedLocation'),
    isLoading: state.monitoring.get('isLoading'),
    selectedLocationStatus: state.monitoring.get('selectedLocationStatus'),
  };
};

BootupStatsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  selectedLocationStatus: PropTypes.object.isRequired,

  getLocationStatus: PropTypes.func.isRequired,
};

const prepareForExport = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      getLocationStatus,
    },
  ),
);


const BootupStats = prepareForExport(BootupStatsComponent);

export default BootupStats;