import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import DataTable from '../../layout/dataTable/dataTable.component';
import ResponseRow from './responseRow.component';

const CommandResponses = ({
  commandResponses,
}) => {

  const locationColumns = (commandResponses.length > 0 && commandResponses[0].location) ? [
    { id: 'locationName', numeric: false, padding: 'dense', label: 'Location', sortable: true, filtering: true },
    { id: 'hubspotCompanyName', numeric: false, padding: 'dense', label: 'Hubspot Company', sortable: true, filtering: true },
  ] : [];

  const commandSetColumns = (commandResponses.length > 0 && commandResponses[0].commandSet) ? [
    { id: 'commandName', numeric: false, padding: 'dense', label: 'Command Name', sortable: true, filtering: true },
    { id: 'user', numeric: false, padding: 'dense', label: 'User', sortable: true, filtering: true },
    { id: 'sentDate', numeric: false, padding: 'dense', label: 'Command Sent', sortable: true, filtering: false },
  ] : [];

  const columns = [
    { id: '', numeric: false, padding: 'none', label: '', sortable: false, filtering: false },
    ...locationColumns,
    ...commandSetColumns,
    { id: 'deviceReceivedDate', numeric: false, padding: 'dense', label: 'Response Received', sortable: true, filtering: false },
    { id: 'status', numeric: false, padding: 'dense', label: 'Status', sortable: true, filtering: true },
  ];

  const formattedData = useMemo(
    () => commandResponses.map((response) => {
      const formattedResponse = {
        ...response, 
      };

      if (response.location) {
        formattedResponse.locationName = response.location.name;
        formattedResponse.hubspotCompanyName = response.location.hubspotCompanyName;
      }

      if (response.commandSet) {
        formattedResponse.commandName = response.commandSet.commandName;
        formattedResponse.user = response.commandSet.user ? response.commandSet.user.email : '';
        formattedResponse.sentDate = response.commandSet.sentDate ? response.commandSet.sentDate : '';
      }

      return formattedResponse;
    }), [commandResponses]);

  return (
    <DataTable
      data={formattedData}
      columns={columns}
      renderRow={(response) => (
        <ResponseRow response={response} key={`${response.id}_response`} />
      )}
    />
  );
};


CommandResponses.propTypes = {
  commandResponses: PropTypes.array.isRequired,
};

export default CommandResponses;
