import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';

const LocationDetails = ({
  classes,
  location,
}) => {

  return (
    <Paper className={classes.root}>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell size="small">
              Location Name
            </TableCell>
            <TableCell size="small">
              Hubspot Company
            </TableCell>
            <TableCell size="small" className={classes.addressColumn}>
              Address
            </TableCell>
            <TableCell size="small">
              Version
            </TableCell>
            <TableCell size="small">
              POD Serial
            </TableCell>
            <TableCell size="small">
              PCD MAC ID
            </TableCell>
            <TableCell size="small">
              PCD Serial #
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell size="small">
              {location.name}
            </TableCell>
            <TableCell size="small">
              {location.hubspotCompanyName}
            </TableCell>
            <TableCell size="small">
              {location.addressOne}
            </TableCell>
            <TableCell size="small">
              {location.podSoftwareVersion}
            </TableCell>
            <TableCell size="small">
              {location.podDeviceSerial}
            </TableCell>
            <TableCell size="small">
              {location.podHardwareIdentifier}
            </TableCell>
            <TableCell size="small">              
              {location.podModemSerial}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

LocationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  header: {
    verticalAlign: 'bottom',
    '& th': {
      border: 'none',
    },
  },
  addressColumn: {
    width: '20%',
  },
  root: {
    marginTop: 24,
  },
});

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
);

export default prepareForExport(LocationDetails);