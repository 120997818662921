export const PERMISSIONS = {
  ACCESS_LOCATIONS: 'web.accessLocations',
  ACCESS_SUBMISSIONS: 'web.accessSubmissions',
  ACCESS_USERS: 'web.accessUsers',
  ACCESS_COMMAND_CENTER: 'web.accessCommandCenter',
  ACCESS_PROMOTIONS: 'web.accessPromotions',
  ACCESS_PROMOTIONS_APP_SLOTS: 'web.accessPromotionAppSlots',
  USERS_DELETE: 'users.delete',
  EVENTS_READ: 'events.read',
  REVIEWS_READ: 'reviews.read',
};