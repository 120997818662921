export const AdminEnabledFields = [
  'hubspotObjectId',
  'hubspotCompanyId',
  'orgType',
  'orgType_subCategory',
  'amazonProjectType',
  'amazonNetworkType',
  'amazonSiteCode',
  'ttLockConnectionString',
  'ttLockHardwareIdentifier',
];