import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarIcon from '@mui/icons-material/Star';

class Star extends Component {
  render() {
    let { total, rating, marginTop } = this.props;
    let stars = [];
    for (let x = 0; x < total; x++) {
      if (rating > x) {
        if (rating - x > .5) {
          stars.push(<StarIcon color="inherit" key={`star_full_${x}`} style={{ marginTop: marginTop ? marginTop : 0 }} />);
        } else {
          stars.push(<StarHalfIcon color="inherit" key={`star_full_${x}`} style={{ marginTop: marginTop ? marginTop : 0 }} />);
        }
      } else {
        stars.push(<StarBorderIcon color="inherit" key={`star_empty_${x}`} style={{ marginTop: marginTop ? marginTop : 0 }} />);
      }
    }

    return (
      <div style={{ display: 'flex', margin: 5 }}>
        {stars.map((star) => {
          return star;
        })}
      </div>
    );
  }
}

Star.propTypes = {
  total: PropTypes.number,
  rating: PropTypes.number,
  marginTop: PropTypes.number,
  updateStarRating: PropTypes.func,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
});

export default withStyles(styles)(Star);
