import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PodTable from './podsTable.component';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { selectMarkedPods } from '../iotCommand.selectors';
import { setPodsSelected } from '../iotCommand.actions';

const SelectPods = ({
  classes,
  isLoading,
  markedPods,
  selectedPods,
  setPodsSelected,
}) => (
  <div>
    {isLoading && <div className="loader" />}
    
    {!isLoading && (
      <Fragment>
        <Typography variant="subtitle1" className={classes.title}>      
          {`${selectedPods.length} Pod${selectedPods.length !== 1 ? 's' : ''} Selected`}
        </Typography>
    
        <PodTable 
          locations={markedPods}
          handleSelectPods={setPodsSelected}
        />

      </Fragment>
    )}
  </div>
);

const mapStateToProps = (state) => {
  return {
    isLoading: state.iotCommand.get('isLoading'),
    selectedPods: state.iotCommand.get('selectedPods'),
    markedPods: selectMarkedPods(state),
  };
};

const styles = (theme) => ({
  title: {
    marginLeft: 24,
  },
});

SelectPods.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,

  markedPods: PropTypes.array.isRequired,
  selectedPods: PropTypes.array.isRequired,

  setPodsSelected: PropTypes.func.isRequired,
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    {
      setPodsSelected,
    }
  )
);

export default prepareForExport(SelectPods);