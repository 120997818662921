import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { RESPONSE_FAILURE, RESPONSE_SUCCESS } from '../iotCommand.actions';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const CommandSetDetails = ({
  classes,
  commandSet,
}) => {

  return (
    <Paper className={classes.root}>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell>
              Description
            </TableCell>
            <TableCell>
              Command
            </TableCell>
            <TableCell>
              User
            </TableCell>
            <TableCell>
              Date Sent
            </TableCell>
            <TableCell>
              Total
            </TableCell>
            <TableCell>
              Success
            </TableCell>
            <TableCell>
              Failure
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {commandSet.description}
            </TableCell>
            <TableCell>
              {commandSet.commandName}
            </TableCell>
            <TableCell>
              {commandSet.user ? commandSet.user.email : ''}
            </TableCell>
            <TableCell>
              {commandSet.sentDate ? moment(commandSet.sentDate).format('MMMM D, YYYY h:mma') : ''}
            </TableCell>
            <TableCell>
              {commandSet.commandResponses.length}
            </TableCell>
            <TableCell>
              {commandSet[RESPONSE_SUCCESS]}
            </TableCell>
            <TableCell>              
              {commandSet[RESPONSE_FAILURE]}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

CommandSetDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  commandSet: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  header: {
    verticalAlign: 'bottom',
    '& th': {
      border: 'none',
    },
  },
  root: {
    marginTop: 24,
  },
});

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
);

export default prepareForExport(CommandSetDetails);