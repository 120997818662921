import { Badge } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';
import ToggleButton from '@mui/lab/ToggleButton';
import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';
import classNames from 'classnames';

import Colors from '../../styles/colors';

const ButtonGroupComponent = ({exclusive, buttons, selectedButton, handleSelectButton, classes, counts, size}) => (
  <ToggleButtonGroup exclusive={exclusive} value={selectedButton} onChange={(event, status ) => handleSelectButton(status)} classes={{ root: classes.toggleButtonGroup }}>      
    {buttons.map((buttonContent, index) => (
      <ToggleButton 
        key={index}
        value={buttonContent} 
        classes={{
          root: size === 'small' ? classNames(classes.toggleButton, classes.toggleButtonSmall) : classes.toggleButton, 
          selected: classes.selectedToggleButton,
        }}
      >
        <Badge
          badgeContent={counts && counts[buttonContent] ? counts[buttonContent] : null}
          color="error"
        >
          {buttonContent}
        </Badge>
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
);

const styles = (theme) => ({
  toggleButtonGroup: {
    boxShadow: 'none',
  },
  toggleButton: {
    padding: 12,
    color: Colors.primary.main,
    backgroundColor: Colors.white, 
    borderColor: Colors.primary.main,
    borderWidth: 1,
    borderRightWidth: 0,
    borderStyle: 'solid',
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&:last-child': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      borderRightWidth: 1,
    },
  },
  toggleButtonSmall: {
    fontSize: '0.7rem',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  selectedToggleButton: {
    //Using important here due to a bug in the order styles are applied to the selected toggle button
    color: `${Colors.white} !important`,
    backgroundColor: `${Colors.primary.main} !important`,
  },
});


ButtonGroupComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  buttons: PropTypes.array.isRequired,
  exclusive: PropTypes.bool.isRequired,
  counts: PropTypes.object,
  selectedButton: PropTypes.any,
  
  handleSelectButton: PropTypes.func.isRequired,
};

const prepareForExport = compose(
  withStyles(styles),
);

const ButtonGroup = prepareForExport(ButtonGroupComponent);
export default ButtonGroup;