import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

import { CUSTOM_COMMAND_INDEX } from './iotCommand.actions';
import { locationIsMqttEnabled } from '../../utilities/iotUtils';

const selectAvailableLocations = (state) => state.iotCommand.get('availableLocations');
const selectSelectedPods = (state) => state.iotCommand.get('selectedPods');

const selectDescription = (state) => state.iotCommand.get('description');
const selectCommandPayload = (state) => state.iotCommand.get('commandPayload');
const selectSelectedCommandIndex = (state) => state.iotCommand.get('selectedCommandIndex');
const selectCommandConfig = (state) => state.iotCommand.get('commandConfig');
const selectCustomCommand = (state) => state.iotCommand.get('customCommand');

export const selectMarkedPods = createSelector(
  selectAvailableLocations,
  selectSelectedPods,
  (availableLocations, selectedPods) => availableLocations.map((location) => ({
    ...location, 
    isSelected: selectedPods.findIndex((selectedPod) => selectedPod.id === location.id) !== -1,
    isDisabled: !locationIsMqttEnabled(location),
  }))
);

// ---- COMMAND FORM

export const selectCommandPayloadErrors = createSelector(
  selectCommandPayload,
  selectSelectedCommandIndex,
  selectCommandConfig,
  (commandPayload, selectedCommandIndex, commandConfig) => {
    const selectedCommand = selectedCommandIndex !== -1 ? commandConfig[selectedCommandIndex] : null;
    if (!selectedCommand || !selectedCommand.payloadOptions) {
      return [];
    }
    return selectedCommand.payloadOptions.map((option) => {
      let hasError = false;
      let errorMessage = '';

      if (option.max !== undefined && commandPayload[option.key] > option.max) {
        hasError = true;
        errorMessage = `Value must be ${option.max} or less`;
      }
      if (option.min !== undefined && commandPayload[option.key] < option.min) {
        hasError = true;
        errorMessage = `Value must be at least ${option.min}`;
      }
      return {
        hasError,
        errorMessage,
      };
    });
  }
);

export const selectCustomCommandError = createSelector(
  selectCustomCommand,
  selectSelectedCommandIndex,
  (customCommand, selectedCommandIndex) => {
    if (selectedCommandIndex !== CUSTOM_COMMAND_INDEX) {
      return '';
    }
    // Require custom command content if custom command was selected
    if (isEmpty(customCommand)) {
      return 'Command is required';
    }
    // Only allow valid JSON
    try {
      JSON.parse(customCommand);
    } catch (err) {
      return 'Only valid JSON is allowed';
    }
    
    return '';
  });

export const selectCommandFormIsValid = createSelector(
  selectDescription,
  selectCommandPayloadErrors,
  selectSelectedCommandIndex,
  selectCustomCommandError,
  (description, commandPayloadErrors, selectedCommandIndex, customCommandError) => {
    return (
      description !== '' && 
      selectedCommandIndex !== -1 && 
      isEmpty(customCommandError) &&
      commandPayloadErrors.findIndex((error) => error.hasError) === -1
    );
  }
);

export const selectSelectedCommand = createSelector(
  selectSelectedCommandIndex,
  selectCommandConfig,
  (selectedCommandIndex, commandConfig) => selectedCommandIndex >= 0 ? commandConfig[selectedCommandIndex] : null
);

export const selectGeneratedCommand = createSelector(
  selectSelectedCommand,
  selectCustomCommand,
  selectCommandPayload,
  (selectedCommand, customCommand, commandPayload) => {
    if (!selectedCommand) {
      return (customCommand);
    } else {
      const newGeneratedCommand = {
        ...selectedCommand.command,
        p: !isEmpty(commandPayload) ? commandPayload : selectedCommand.command.p,
      };
      return (JSON.stringify(newGeneratedCommand, null, 2));
    }
  }
);
