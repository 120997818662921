import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { GET_LOCATIONS_SUCCESS, SEARCH_LOCATIONS_SUCCESS, clearSearchFilters, getLocations, getLocationsBySearchCriteria, setSearchFilters } from './locations.actions';
import { HARDWARE_TYPES, LIFECYCLE_STATUS, LOCATION_TYPES } from '../../types/location.types';
import { compose } from 'redux';
import LoadingOverlay from '../layout/loadingOverlay/loadingOverlay.component';

export const SearchCriteriaPropertyNameToLabel = {
  locationName: 'Location Name',
  address: 'Address',
  city: 'City',
  state: 'State',
  country: 'Country',
  hubspotCompanyName: 'Company (From Hubspot)',
  lifecycleStatus: 'Lifecycle status',
  lockSerial: 'Lock Serial Number',
  podDeviceSerial: 'Pod Serial Number',
  podHardwareIdentifier: 'Pod Hardware ID',
  podModemSerial: 'PCD Serial Number',
  podHardwareType: 'Pod Hardware Type',
  type: 'Search by Location Type',
};

function LocationSearch({classes, onSearchSuccess, isLoading}) {
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.locations.get('searchFilters'));

  const onSearchFieldChange = (searchFieldName, value) => {
    let newVal = ({
      ...searchState,
      [searchFieldName]: value,
    });
    dispatch(setSearchFilters(newVal));
  };

  const handleFilteredSearch = async (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      e.preventDefault();
      const response = await dispatch(getLocationsBySearchCriteria(searchState));
      if (response.type === SEARCH_LOCATIONS_SUCCESS) {
        onSearchSuccess();
      }
    }
  };

  const handleClearSearchFilters = async () => {
    await dispatch(clearSearchFilters());
  };

  const isPristine = Object.values(searchState).every((val) => !val) ;

  const typeOptions = [
    {
      value: LOCATION_TYPES.MAMAVA,
      label: 'Mamava (Search includes All Pods & VaRooms)',
    },
    {
      value: LOCATION_TYPES.LOCATION,
      label: 'Location (Search includes All Non-Mamava locations)',
    },
    {
      value: '',
      label: 'All',
    },
  ];
  
  return (
    <Card className={classes.card}>
      <CardContent
        onKeyDown={(e) => handleFilteredSearch(e)}
      >
        <Grid container spacing={4} justifyContent="center">
          {isLoading &&
            <LoadingOverlay />
          }
          <Grid container item row-spacing={2}>
            <Grid container item xs={6} className={classes.containerSection}>
              <Grid container item xs={11.5} spacing={2}>
                <Grid item xs={12}> 
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.locationName}
                    variant="outlined"
                    name="locationName"
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    value={searchState.locationName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.address}
                    variant="outlined"
                    name="address"
                    value={searchState.address}
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.city}
                    variant="outlined"
                    name="city"
                    value={searchState.city}
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.state}
                    variant="outlined"
                    name="state"
                    value={searchState.state}
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.country}
                    variant="outlined"
                    name="country"
                    value={searchState.country}
                    onChange={e => onSearchFieldChange(e.target.name, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.hubspotCompanyName}
                    variant="outlined"
                    name="hubspotCompanyName"
                    value={searchState.hubspotCompanyName}
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox name="includeTestLocation" defaultChecked={searchState.includeTestLocation} onClick={e => onSearchFieldChange(e.target.name, !searchState[e.target.name])} color="default" />}
                    label="Include Test Locations"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} className={classes.containerSection}>
              <Grid container spacing={2}>
                
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.podDeviceSerial}
                    variant="outlined"
                    name="podDeviceSerial"
                    value={searchState.podDeviceSerial}
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.podHardwareIdentifier}
                    variant="outlined"
                    name="podHardwareIdentifier"
                    value={searchState.podHardwareIdentifier}
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.podModemSerial}
                    variant="outlined"
                    name="podModemSerial"
                    value={searchState.podModemSerial}
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.lockSerial}
                    variant="outlined"
                    name="lockSerial"
                    value={searchState.lockSerial}
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.lifecycleStatus}
                    variant="outlined"
                    name="lifecycleStatus"
                    value={searchState.lifecycleStatus}
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    select
                    fullWidth
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value={LIFECYCLE_STATUS.PREBUILT}>Prebuilt</MenuItem>
                    <MenuItem value={LIFECYCLE_STATUS.WAITING_TO_ACTIVATE}>Waiting to activate</MenuItem>
                    <MenuItem value={LIFECYCLE_STATUS.ACTIVE}>Active</MenuItem>
                    <MenuItem value={LIFECYCLE_STATUS.REJECTED}>Rejected</MenuItem>
                    <MenuItem value={LIFECYCLE_STATUS.DECOMMISSIONED}>Decommissioned</MenuItem>
                    <MenuItem value={LIFECYCLE_STATUS.TEMPORARILY_DOWN}>Location Temporarily Down</MenuItem>
                    <MenuItem value={LIFECYCLE_STATUS.UNDER_REVIEW}>Under Review</MenuItem>
                    <MenuItem value={LIFECYCLE_STATUS.ACTIVATION_ON_HOLD}>Installation on Hold</MenuItem>
                    <MenuItem value={LIFECYCLE_STATUS.DEPRECATED}>Deprecated</MenuItem>
                    <MenuItem value={LIFECYCLE_STATUS.LOCATION_UNKNOWN}>Location Unknown</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.podHardwareType}
                    variant="outlined"
                    name="podHardwareType"
                    value={searchState.podHardwareType}
                    onChange={(e) => onSearchFieldChange(e.target.name, e.target.value)}
                    select
                    fullWidth
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value={HARDWARE_TYPES.NO_TECH_POD}>No-Tech Pod</MenuItem>
                    <MenuItem value={HARDWARE_TYPES.PCD_PAIRED_WITH_TTLOCK}>Smart Pod with TTLock</MenuItem>
                    <MenuItem value={HARDWARE_TYPES.SMART_LOCK}>SmartLock</MenuItem>
                    <MenuItem value={HARDWARE_TYPES.SMART_POD}>SmartPod</MenuItem>
                    <MenuItem value={HARDWARE_TYPES.TTLOCK_POD}>TTLock Pod</MenuItem>
                    <MenuItem value={HARDWARE_TYPES.VAROOM_TTLOCK}>VaRoom TTLock</MenuItem>
                    <MenuItem value={HARDWARE_TYPES.ZTU_POD}>ZTU Pod</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={SearchCriteriaPropertyNameToLabel.type}
                    variant="outlined"
                    name="type"
                    value={searchState.type} 
                    onChange={e => onSearchFieldChange(e.target.name, e.target.value)}
                    select
                    fullWidth
                  >
                    {typeOptions.map(e => (
                      <MenuItem 
                        key={e.value} 
                        value={e.value}
                      >
                        {e.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="center" direction="row" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilteredSearch}
              >
                Search
              </Button>
            </Grid>
            <Grid item >
              <Button
                variant="contained"
                color="primary"
                onClick={handleClearSearchFilters}
                disabled={isPristine}
              >
                Clear Search
              </Button>
            </Grid>
          </Grid>
         
          <Grid item >
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const styles = (theme) => ({
  card: {
    margin: 10,
  },
  containerSection: {
    alignItems: 'flex-start',
  },
});

LocationSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  
  onSearchSuccess: PropTypes.func.isRequired,
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
);

export default prepareForExport(LocationSearch);