import { CALL_API } from '../../middleware/api';

export const GET_SUBMITTED_LOCATIONS_REQUEST = 'GET_SUBMITTED_LOCATIONS_REQUEST';
export const GET_SUBMITTED_LOCATIONS_SUCCESS = 'GET_SUBMITTED_LOCATIONS_SUCCESS';
export const GET_SUBMITTED_LOCATIONS_FAILURE = 'GET_SUBMITTED_LOCATIONS_FAILURE';

export const GET_SUBMITTED_REVIEWS_REQUEST = 'GET_SUBMITTED_REVIEWS_REQUEST';
export const GET_SUBMITTED_REVIEWS_SUCCESS = 'GET_SUBMITTED_REVIEWS_SUCCESS';
export const GET_SUBMITTED_REVIEWS_FAILURE = 'GET_SUBMITTED_REVIEWS_FAILURE';

export const GET_SUBMITTED_PHOTOS_REQUEST = 'GET_SUBMITTED_PHOTOS_REQUEST';
export const GET_SUBMITTED_PHOTOS_SUCCESS = 'GET_SUBMITTED_PHOTOS_SUCCESS';
export const GET_SUBMITTED_PHOTOS_FAILURE = 'GET_SUBMITTED_PHOTOS_FAILURE';

export const getSubmittedLocations = () => {
  return {
    [CALL_API]: {
      types: [GET_SUBMITTED_LOCATIONS_REQUEST, GET_SUBMITTED_LOCATIONS_SUCCESS, GET_SUBMITTED_LOCATIONS_FAILURE],
      authenticated: true,
      endpoint: 'locations/?isUserSubmitted=true',
      method: 'GET',
    },
  };
};

export const getSubmittedReviews = () => {
  return {
    [CALL_API]: {
      types: [GET_SUBMITTED_REVIEWS_REQUEST, GET_SUBMITTED_REVIEWS_SUCCESS, GET_SUBMITTED_REVIEWS_FAILURE],
      authenticated: true,
      endpoint: 'reviews/?statuses[]=SUBMITTED&statuses[]=UNDER_REVIEW',
      method: 'GET',
    },
  };
};

export const getSubmittedPhotos = () => {
  return {
    [CALL_API]: {
      types: [GET_SUBMITTED_PHOTOS_REQUEST, GET_SUBMITTED_PHOTOS_SUCCESS, GET_SUBMITTED_PHOTOS_FAILURE],
      authenticated: true,
      endpoint: 'photos/?status=SUBMITTED',
      method: 'GET',
    },
  };
};