import { Button, Fab, Input, MenuItem, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import {  clearLocations, getLocations } from './locations.actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isNil, slice } from 'lodash';
import { setPageTitle } from '../layout/layout.actions';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import Colors from '../../styles/colors';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { LIFECYCLE_STATUS } from '../../types/location.types';
import { LOCATION_IS_SCHEDULABLE, LOCATION_TYPES } from '../../types/location.types';
import { LifecycleStatusToLabelMap } from '../location/editComponents/lifecycleStatusSection.component';
import { LocationSearchCriterias } from './locationSearchCriterias.component';
import { SEARCH_MODES } from './locations.reducer';
import { isFalsyOrEmpty } from '../../utilities/locationUtils';
import LocationSearch from './locationSearch.component';

const lifecycleStatusFilterOptions = [
  { value: '', displayName: 'All'},
  { value: LIFECYCLE_STATUS.PREBUILT, displayName: 'Prebuilt'},
  { value: LIFECYCLE_STATUS.WAITING_TO_ACTIVATE, displayName: 'Waiting to activate'},
  { value: LIFECYCLE_STATUS.ACTIVE, displayName: 'Active'},
  { value: LIFECYCLE_STATUS.REJECTED, displayName: 'Rejected'},
  { value: LIFECYCLE_STATUS.ACTIVATION_ON_HOLD, displayName: 'Installation on Hold' },
  { value: LIFECYCLE_STATUS.DECOMMISSIONED, displayName: 'Decommissioned' },
  { value: LIFECYCLE_STATUS.DEPRECATED, displayName: 'Deprecated' },
  { value: LIFECYCLE_STATUS.LOCATION_UNKNOWN, displayName: 'Location Unknown'},
];

const locationTypeFilterOptions = [
  { value: '', displayName: 'All'}, 
  { value: LOCATION_TYPES.MAMAVA, displayName: 'Mamava'}, 
  { value: LOCATION_TYPES.LOCATION, displayName: 'Location'},
];

const scheduleEnabledOptions = [
  { value: '', displayName: 'All' },
  { value: LOCATION_IS_SCHEDULABLE.ENABLED, displayName: 'Enabled' },
  { value: LOCATION_IS_SCHEDULABLE.DISABLED, displayName: 'Disabled' },
];

const DATA_COLUMNS = [
  { fieldName: 'name', displayName: 'Location Name' },
  { fieldName: 'hubspotCompanyName', displayName: 'Hubspot Company Name' },
  { fieldName: 'addressOne', displayName: 'Address' },
  { fieldName: 'city', displayName: 'City' },
  { fieldName: 'state', displayName: 'State' },
  { fieldName: 'country', displayName: 'Country' },
  { fieldName: 'podDeviceSerial', displayName: 'Pod Serial Number' },
  { fieldName: 'podHardwareIdentifier', displayName: 'Pod Hardware Mac ID' },
  { fieldName: 'podModemSerial', displayName: 'PCD Serial #' },
  { fieldName: 'lockSerial', displayName: 'Lock Serial #' },
  { fieldName: 'lifecycleStatus', displayName: 'Lifecycle status', valueTransformation: (str, loc) => LifecycleStatusToLabelMap[str][loc.type], filterOptions: lifecycleStatusFilterOptions },
  { fieldName: 'type', displayName: 'Pod vs lactation space', filterOptions: locationTypeFilterOptions },
  { fieldName: 'isSchedulable', displayName: 'Schedule Enabled', valueTransformation: (isScheduled, loc) => isScheduled ? 'Enabled' : 'Disabled', filterOptions: scheduleEnabledOptions },
];

class LocationsContainer extends Component {
  constructor(props) {
    super(props);

    [].map((key) => (this[key] = this[key].bind(this)));
  }
  state = {
    order: 'asc',
    orderBy: 'name',
    currentLocations: [],
    filteredLocations: [],
    page: 0,
    rowsPerPage: 10,
    filters: {
      name: '',
      hubspotCompanyName: '',
      addressOne: '',
      state: '',
      country: '',
      podDeviceSerial: '',
      podHardwareIdentifier: '',
      podModemSerial: '',
      lockSerial: '',
      isSchedulable: '',
    },
  };

  async UNSAFE_componentWillMount() {
    this.props.setPageTitle('Locations');
    let rowsPerPage = localStorage.getItem('rowsPerPage');
    if (parseInt(rowsPerPage, 10) >= 0) {
      this.setState({ rowsPerPage: parseInt(rowsPerPage, 10) });
    }
    await this.loadSearchFilters();
    this.buildCurrentLocations();
  }

  buildCurrentLocations() {
    const { page, rowsPerPage } = this.state;
    let locations = this.filterArray(this.props.locations, this.state.filters);

    this.setState({
      currentLocations: slice(locations, page * rowsPerPage, (page + 1) * rowsPerPage),
      filteredLocations: locations,
    });
  }

  filterArray(array, filters) {
    let currentArray = array;
    if (array) {
      currentArray = array
        .map((record) => {
          let returnRecord = record;
          if (filters && Object.keys(filters).length > 0) {
            Object.keys(filters).map((filter) => {
              if (returnRecord) {
                const isSearchFieldMatchLocationField =  (!record  || !record[filter]?.toString()?.toLowerCase()?.includes(filters[filter]?.toString()?.toLowerCase()));
                if (!isFalsyOrEmpty(filters[filter]) && isSearchFieldMatchLocationField) {
                  returnRecord = undefined;
                }
              }
              return returnRecord;
            });
          }
          return returnRecord;
        })
        .filter((record) => (record ? true : false));
    }
    return currentArray;
  }

  convertType(type) {
    if (type === 'MAMAVA') {
      return 'Mamava Pod';
    } else if (type === 'LOCATION') {
      return 'Lactation Space';
    } else {
      return 'Unknown';
    }
  }

  handleUpdateFilters(filter, value) {
    let filters = this.state.filters;
    filters[filter] = value;
    this.setState({ state: { filters } }, this.buildCurrentLocations);
    this.saveSearchFilters();
  }

  handleFilterChange = (field, value) => {
    this.handleUpdateFilters(field, value);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, this.buildCurrentLocations);
  };

  handleChangeRowsPerPage = (event) => {
    localStorage.setItem('rowsPerPage', event.target.value);
    this.setState({ rowsPerPage: event.target.value }, this.buildCurrentLocations);
  };

  // save filter criteria in query params
  saveSearchFilters = () => {
    localStorage.setItem('locationFilters', JSON.stringify(this.state.filters));
  };

  loadSearchFilters = () => {
    const filterPromise = new Promise((resolve, reject) => {
      const locationFilters = localStorage.getItem('locationFilters');
      if (!isNil(locationFilters)) {
        try {
          const updateFilters = Object.assign({}, this.state.filters);
          const parsed = JSON.parse(locationFilters);
          Object.keys(parsed).forEach((key) => {
            if (parsed[key] !== '') {
              updateFilters[key] = parsed[key];
            }
          });
          this.setState({
            filters: updateFilters,
          }, () => resolve());
        } catch (e) {
          console.warn('Warning: could not load search filters from localStorage with error: ', e);
          reject();
        }
      } else {
        // Just resolve if location filters is empty
        resolve();
      }
    });
    return filterPromise;
  };

  handleClearFilters = () => {
    localStorage.removeItem('locationFilters');
    this.setState(
      {
        filters: {
          name: '',
          hubspotCompanyName: '',
          addressOne: '',
          state: '',
          country: '',
          podDeviceSerial: '',
          podHardwareIdentifier: '',
          podModemSerial: '',
          lockSerial: '',
          isSchedulable: undefined,
        },
      },
      this.buildCurrentLocations
    );
  };

  handleSearchSuccess = () => {
    this.buildCurrentLocations();
  };

  goToSearch = () => {
    this.props.clearLocations();
  };

  render() {
    let { classes } = this.props;
    // Force the screen to go to SEARCH view if user has chosen to view search
    // Otherwise, just show search screen if location list is empty
    if (this.props.searchMode === SEARCH_MODES.RESULTS) {
      return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', maxHeight: '85vh', paddingTop: 10, overflowY: 'auto' }}>
          {this.props.isLoading && <div className="loader" />}
          {this.props.locations.length === 0 && <Typography style={{padding: 20}} variant="h6">No locations found with the current search criterias.</Typography>}
          {!this.props.isLoading && this.props.locations.length > 0 && (
            <div className={classes.tableWrapper} id="tableWrapper">
              <Table>
                <TableHead style={{ backgroundColor: 'white' }}>
                  <TableRow>
                    {DATA_COLUMNS.map((column, i) => {
                      if (column.fieldName === 'type' || column.fieldName === 'lifecycleStatus' || column.fieldName === 'isSchedulable' ) {
                        return (
                        <TableCell className={classes.filterCell} key={i}>
                          <div>{column.displayName}</div>
                          <TextField
                            id={column.id}
                            name={column.fieldName}
                            value={this.state.filters[column.fieldName]}
                            onChange={(e) => this.handleFilterChange(column.fieldName, e.target.value)}
                            select
                            variant='standard'
                          >
                            {column.filterOptions.map((option) => (
                              <MenuItem value={option.value} key={option.displayName}>{option.displayName}</MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        )}
                      return (
                        <TableCell className={classes.filterCell} key={i}>
                          <div>{column.displayName}</div>
                          <div>
                            <Input
                              margin="none"
                              id={`filter_${column.fieldName}`}
                              variant="caption"
                              placeholder="filter"
                              className={classes.textField}
                              classes={{
                                root: classes.root,
                              }}
                              fullWidth
                              value={this.state.filters[column.fieldName]}
                              onChange={(e) => this.handleFilterChange(column.fieldName, e.target.value)}
                              type={column.type ? column.type : 'text'}
                            />
                          </div>
                          {i === 0 && (
                            <Button
                              variant="text"
                              size="small"
                              color="primary"
                              style={{ position: 'absolute' }}
                              onClick={this.handleClearFilters}
                            >
                              CLEAR FILTERS
                            </Button>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {this.state.currentLocations.map((location, i) => {
                    return (
                      <TableRow
                        key={location.id}
                        className="clickable"
                        onClick={() => {
                          this.props.history.push({
                            pathname: `locations/${location.id}`,
                            state: { location },
                          });
                        }}
                      >
                        {DATA_COLUMNS.map((column, i) => {
                          return (
                            <TableCell className={classes.cell} component="td" scope="row" key={i}>
                              {column.valueTransformation ? column.valueTransformation(location[column.fieldName], location) : location[column.fieldName]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}

          {!this.props.isLoading && this.props.locations.length > 0 && (
            <div style={{ flex: 1, paddingRight: 100 }}>
              <TablePagination
                component="div"
                count={this.state.filteredLocations.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                style={{ flex: 0 }}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          )}
          {!this.props.isLoading && (
            <div style={{ flex: 0, margin: 10, display: 'flex', gap: 50, alignItems: 'center'}}>
              <Button
                margin="10"
                variant="contained"
                color="primary"
                onClick={() => this.goToSearch()}
              >
                Return to Search
              </Button>
              <div>
                <LocationSearchCriterias />
              </div>
              <Fab
                color="primary"
                aria-label="Add"
                className={classes.button}
                onClick={() => this.props.history.push('/locations/create')}
              >
                <AddIcon />
              </Fab>
            </div>
          )}
        </div>
      );
    }
    return (
      <div>
        <LocationSearch onSearchSuccess={() => this.handleSearchSuccess()} isLoading={this.props.isLoading} />
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.button}
          onClick={() => this.props.history.push('/locations/create')}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    flex: 1,
  },
  tableWrapper: {
    padding: '0rem',
    overflowX: 'auto',
  },
  tableBody: {
    overflowY: 'auto',
  },
  button: {
    margin: theme.spacing(1),
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  filterCell: {
    position: 'sticky',
    top: 0,
    backgroundColor: Colors.white,
    paddingBottom: '2rem',
  },
  textField: {
    boxShadow: 'none',
    fontWeight: 'normal',
    border: 0,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 0,
    fontSize: 10,
  },
  cell: {
    color: Colors.primary.main,
  },
  input: {
    height: 20,
  },
  link: {
    color: Colors.primary.main,
    textDecoration: 'none',
  },
});

LocationsContainer.propTypes = {
  locations: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
  searchMode: PropTypes.object.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  clearLocations: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    locations: state.locations.get('locations'),
    isLoading: state.locations.get('isLoading'),
    errors: state.locations.get('errors'),
    searchMode: state.locations.get('searchMode'),
  };
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(
    mapStateToProps,
    {
      setPageTitle,
      getLocations,
      clearLocations,
    }
  )
);

export default prepareForExport(LocationsContainer);
