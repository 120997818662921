import { Button, Grid, Step, StepLabel, Stepper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { GET_COMMAND_CONFIG_FAILURE, GET_PODS_FAILURE, getCommandConfig, getSmartPods, sendCommand, setActiveStep } from './iotCommand.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';
import { selectCommandFormIsValid, selectGeneratedCommand, selectSelectedCommand } from './iotCommand.selectors';
import Colors from '../../styles/colors';
import IotCommandForm from './commandCreate/commandForm.container';
import ReviewCommand from './commandCreate/reviewCommand.container';
import SelectPods from './commandCreate/selectPods.container';
import { withTheme } from '@emotion/react';

const STEP_SELECT_PODS = 0;
const STEP_SELECT_COMMAND = 1;
const STEP_RUN_COMMAND = 2;
const NUMBER_OF_STEPS = 3;

const stepNames = {
  [STEP_SELECT_COMMAND]: 'Select Command',
  [STEP_SELECT_PODS]: 'Select Pods',
  [STEP_RUN_COMMAND]: 'Run',
};

const IotCommandCreateContainer = ({
  classes,
  handleToastMessage,
  getCommandConfig,
  setPageTitle, 
  getSmartPods,
  selectedPods,
  setActiveStep,
  activeStep,
  commandFormIsValid,
  generatedCommand,
  sendCommand,
  selectedCommand,
  description,
  history,
}) => {

  // --- INITIALIZE PAGE --- //

  // Set Page Title
  useEffect(() => {
    setPageTitle('IoT Command Center');
  }, []);

  // Fetch Pods & Config
  useEffect(() => {
    const fetchData = async () => {
      const configResponse = await getCommandConfig();
      if (configResponse.type === GET_COMMAND_CONFIG_FAILURE) {
        handleToastMessage(`Error getting command config: ${configResponse.messages[0]}`, true);
      }

      const response = await getSmartPods();
      if (response.type === GET_PODS_FAILURE) {
        handleToastMessage(`Error getting pods: ${response.messages[0]}`, true);
      }
    };
    fetchData();
  }, []);

  // --- STEPS --- //

  // Calculate steps complete
  const stepsComplete = useMemo(() => {
    const newStepsComplete = Array(NUMBER_OF_STEPS).fill(false);
    newStepsComplete[STEP_SELECT_COMMAND] = commandFormIsValid;
    newStepsComplete[STEP_SELECT_PODS] = selectedPods.length > 0;
    newStepsComplete[STEP_RUN_COMMAND] = newStepsComplete[STEP_SELECT_COMMAND] && newStepsComplete[STEP_SELECT_PODS];
    return newStepsComplete;
  }, [commandFormIsValid, selectedPods]);

  const runCommand = () => {
    if (window.confirm(`Are you sure you want to send this command to ${selectedPods.length} Pod${selectedPods.length > 1 ? 's':''}?`)) {
      sendCommand({
        locationIds: selectedPods.map((location) => location.id),
        description,
        commandName: selectedCommand ? selectedCommand.name : 'Custom Command',
        command: generatedCommand,
      });
      history.push({
        pathname: '/iotCommands/commandSet/new',
      });
    }
  };

  // Validate that new step is allowed before changing value
  const handleChangeActiveStep = (newStep) => {
    if (newStep >= NUMBER_OF_STEPS || newStep < 0) {
      return;
    }

    if (newStep < activeStep) {
      // Can go back
      setActiveStep(newStep);
    } else {
      // Can go forward if all the steps before are complete
      const prevStepsComplete = stepsComplete.slice(0, newStep).every((step) => step);
      if (prevStepsComplete) {
        setActiveStep(newStep);
      }
    }
  };

  // --- RENDER --- //

  // Render step
  const renderedStep = useMemo(() => {
    switch (activeStep) {
      case STEP_SELECT_COMMAND:
        return (
          <IotCommandForm />
        );
      case STEP_SELECT_PODS:
        return (
          <SelectPods />
        );
      case STEP_RUN_COMMAND:
        return (
          <ReviewCommand />
        );
      default:
        return (
          <div>Undefined step</div>
        );
    }
  }, [activeStep]);

  // Render component
  return (
    <div className={classNames(classes.container, classes.fullHeight)}>
      <Grid
        container
        spacing={3}
        direction="row"
        alignContent="space-between"
        className={classes.fullHeight}
      >
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {Array(NUMBER_OF_STEPS).fill(0).map((_, i) => (
              <Step 
                key={stepNames[i]}
                onClick={() => handleChangeActiveStep(i)}
                classes={{completed: classes.completedStep}}
              >
                <StepLabel>{stepNames[i]}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {renderedStep}

        </Grid>

        <Grid item xs={12} className={classes.buttonContainer}>
          <Button
            disabled={activeStep === 0}
            onClick={() => handleChangeActiveStep(activeStep - 1)}
            className={classes.button}
          >
            Back
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (activeStep === STEP_RUN_COMMAND) {
                // Run command
                runCommand();
              } else {
                handleChangeActiveStep(activeStep + 1);
              }
            }}
            className={classes.button}
            disabled={!stepsComplete[activeStep]}
          >
            {activeStep < NUMBER_OF_STEPS - 1 ? 'Next' : 'Run'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

IotCommandCreateContainer.defaultProps = {
  activeStep: 0,
  commandFormIsValid: false,
};

IotCommandCreateContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  
  activeStep: PropTypes.number,
  generatedCommand: PropTypes.string.isRequired,
  selectedPods: PropTypes.array.isRequired,
  selectedCommand: PropTypes.object,
  description: PropTypes.string,
  commandFormIsValid: PropTypes.bool,
  
  setActiveStep: PropTypes.func.isRequired,
  getCommandConfig: PropTypes.func.isRequired,
  sendCommand: PropTypes.func.isRequired,
  getSmartPods: PropTypes.func.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  container: {
    width: 'calc(100% - 24px)',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  stepper: {
    marginTop: 16,
  },
  fullHeight: {
    height: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  completedStep: {
    '& svg': {
      color: `${Colors.success.main} !important`,
    },
  },
});

const mapStateToProps = (state) => {
  return {
    activeStep: state.iotCommand.get('activeStep'),
    selectedPods: state.iotCommand.get('selectedPods'),
    commandFormIsValid: selectCommandFormIsValid(state),
    generatedCommand: selectGeneratedCommand(state),
    selectedCommand: selectSelectedCommand(state),
    description: state.iotCommand.get('description'),
  };
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true}),
  withRouter,
  connect(
    mapStateToProps,
    {
      setPageTitle,
      getCommandConfig,
      getSmartPods,
      handleToastMessage,
      setActiveStep,
      sendCommand,
    }
  ),
);

export default prepareForExport(IotCommandCreateContainer);