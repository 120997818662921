import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';

import StatItem from '../../statItem.component';

const TtlockConnectionStats = ({ locationStatus, classes }) => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} className={classes.statWrapper}>
        <StatItem
          title={locationStatus.batteryStatus?.value ? `${locationStatus.batteryStatus.value}%` : 'Unknown'}
          date={locationStatus.batteryStatus ? locationStatus.batteryStatus.timestamp : null}
          subtitle="Battery Status"
        />
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  statWrapper: {
    display: 'flex',
  },
});

TtlockConnectionStats.propTypes = {
  classes: PropTypes.object.isRequired,
  locationStatus: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles)
);

export default prepareForExport(TtlockConnectionStats);