import { TableCell, TableRow } from '@mui/material';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import moment from 'moment';

import { CAUSE_OF_LAST_RESET_NAMES } from '../monitoring.actions';
import { DATE_FORMAT } from '../../../utilities/dateUtils';
import DataTable from '../../layout/dataTable/dataTable.component';

const TABLE_PADDING = 'dense';

const resetNames =  [
  ...Object.values(CAUSE_OF_LAST_RESET_NAMES),
  'Unknown',
];

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'causeOfLastReset', numeric: false, padding: TABLE_PADDING, label: 'Reset Reason', sortable: true, filtering: true,filterOptions: resetNames, filterId: 'formattedCauseOfLastReset' },
  { id: 'timestamp', numeric: false, padding: TABLE_PADDING, label: 'Timestamp', sortable: true, filtering: false },
];

const renderRow = (row, i) => {

  return (
    <Fragment key={i}>
      <TableRow
        tabIndex={-1}
        hover
      >
        <TableCell align="left" padding={TABLE_PADDING}>{row.formattedCauseOfLastReset}</TableCell>
        <TableCell align="left" padding={TABLE_PADDING}>{moment(row.timestamp).format(DATE_FORMAT.DATE_TIME)}</TableCell>
      </TableRow>
    </Fragment>
  );
};


const BootupsTable = ({bootups}) => {

  const formattedData = useMemo(
    () => bootups.map((row) => {
      row = { ...row }; 
      row.formattedCauseOfLastReset = isNil(row.causeOfLastReset) ? 'Unknown' : CAUSE_OF_LAST_RESET_NAMES[row.causeOfLastReset];
      return row;
    }), [bootups]);

  return (
    <DataTable
      data={formattedData}
      title={''}
      columns={columns}
      renderRow={renderRow}
      disableSearch
      defaultSortOrder="asc"
    />
  );
};

BootupsTable.propTypes = {
  bootups: PropTypes.array.isRequired,
};


export default BootupsTable;
