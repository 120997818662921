import { Button, Chip, Grid, TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { handleToastMessage, setDirtyForm } from '../../layout/layout.actions';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const MultipleCodesInput = ({ 
  onCodeChange, 
  classes, 
  codes, 
  isHubspotPreviewMode,
  inputLabel,
  errorMessage,
  codeInsertionValidation,
  isDisabled,
  disabledErrorMessage,
  chipHeaderLabel,
}) => {

  const [newCode, setNewCode] = useState('');
  const dispatch = useDispatch();

  const addCode = async () => {
    dispatch(setDirtyForm(true));
    const filteredCodes = codes.filter(code => !!code); // Filter legacy pin codes containing empty string.
    if (!filteredCodes.includes(newCode) && !isEmpty(newCode) && codeInsertionValidation(filteredCodes)) {
      filteredCodes.push(newCode.toString());
      await onCodeChange(filteredCodes);
      clearInputField();
    } else {
      dispatch(handleToastMessage(errorMessage));
    }
  };

  const removeCode = codeToDelete => {
    dispatch(setDirtyForm(true));
    const updatedCodes = codes.filter(code => code !== codeToDelete);
    onCodeChange(updatedCodes);
  };

  const clearInputField = () => {
    setNewCode('');
  };

  const renderChips = () => {
    return codes.map(code => {
      if (!isEmpty(code)) {
        return (
          <Chip
            key={`code_${code}`}
            label={code}
            onDelete={
              !isHubspotPreviewMode && !isDisabled
                ? () => {
                  removeCode(code);
                }
                : undefined
            }
            color="primary"
            className={classes.chip}
            clickable={!isHubspotPreviewMode && !isDisabled}
          />
        );
      }
      return null;
    });
  };

  return (
    <>
      <Grid item xs={10}>
        <TextField
          disabled={isDisabled}
          name="code"
          label={inputLabel}
          inputProps={{ maxLength: 120, style: {textTransform: 'uppercase' }}}
          fullWidth
          value={(isDisabled ? disabledErrorMessage : newCode || '')}
          onChange={e => setNewCode(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={2}>
        {!isDisabled && <Button variant="contained" color="primary" onClick={addCode}>
          Add
        </Button> }
      </Grid>
      <Grid item xs={12} className={classes.codes}>
        {codes && !isEmpty(chipHeaderLabel) && (
          <Grid item xs={12} className={classes.subTitle}>
            <Typography variant="subtitle1">{chipHeaderLabel}</Typography>
          </Grid>
        )}
        {codes && renderChips()}
      </Grid>
    </>
  );
};

MultipleCodesInput.propTypes = {
  classes: PropTypes.object.isRequired,
  codes: PropTypes.array.isRequired,
  inputLabel: PropTypes.string.isRequired,
  isHubspotPreviewMode: PropTypes.bool,
  onCodeChange: PropTypes.func.isRequired,
  codeInsertionValidation: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  disabledErrorMessage: PropTypes.string.isRequired,
  chipHeaderLabel: PropTypes.string,
};

const styles = theme => ({
  codes: {
    flexWrap: 'wrap',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  chip: {
    margin: 2,
    fontSize: 12,
    flex: 0,
  },
  subTitle: {
    marginBottom: theme.spacing(2),
  },
});

const prepareForExport = compose(withStyles(styles, { withTheme: true }));

export default prepareForExport(MultipleCodesInput);
