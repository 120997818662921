import { Grid, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { 
  FLEET_OVERVIEW_TABS,
  GET_LOCATIONS_FAILURE,
  getMonitoringLocations,
  setFleetOverviewCompanyFilter,
  setFleetOverviewLocationFilter,
} from '../monitoring.actions';
import { handleToastMessage } from '../../layout/layout.actions';
import { setPageTitle } from '../../layout/layout.actions';
import Colors from '../../../styles/colors';
import ConnectionChart from './connectionChart.container';
import FleetMapCard from './fleetMapCard.container';

const FleetOverviewContainer = ({
  classes,
  locations,
  locationFilter,
  companyFilter,

  getMonitoringLocations,
  setPageTitle,
  setFleetOverviewLocationFilter,
  setFleetOverviewCompanyFilter,
  location,
}) => {
  
  const [selectedTab, setSelectedTab] = useState('CONNECTION');
  const [selectedButton, setSelectedButton] = useState('');

  const fetchData = async () => {
    const locationsResponse = await getMonitoringLocations();
    if (locationsResponse.type === GET_LOCATIONS_FAILURE) {
      handleToastMessage(`Error getting pods: ${locationsResponse.messages[0]}`, true);
    }
  };

  useEffect(() => {
    setPageTitle('Fleet Overview');
    if (!locations || locations.length < 1) {
      fetchData();
    }

    // Set tab
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('tab')) {
      setSelectedTab(queryParams.get('tab'));
    }
    if (queryParams.get('button')) {
      setSelectedButton(queryParams.get('button'));
    }
  }, []);

  const handleSelectTab = (tab) => {
    if (tab !== selectedTab) {
      // Set tab in url
      window.history.replaceState(null, null, `/monitoring/?tab=${tab}&button=${selectedButton}`);
      setSelectedTab(tab);
      setSelectedButton('');
    }
  };

  const handleSelectButton = (button) => {
    // Set button in url
    window.history.replaceState(null, null, `/monitoring/?tab=${selectedTab}&button=${button}`);
    setSelectedButton(button);
  };

  return (
    <div className={classes.container}>
      <div className={classes.gridContainer}>
        <Grid container spacing={2}>

          <Grid
            item
            container
            xs={12}
            spacing={2}
          >
            <Grid item>
              <TextField
                label="Location Name"
                type="text"
                value={locationFilter}
                onChange={(e) => setFleetOverviewLocationFilter(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                label="Hubspot Company"
                type="text"
                value={companyFilter}
                onChange={(e) => setFleetOverviewCompanyFilter(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={4}
            spacing={2}
          >
            <Grid
              item
              xs={12}
              className={classNames(classes.chartWrapper)}
            >
              <ConnectionChart onViewMap={() => handleSelectTab(FLEET_OVERVIEW_TABS.CONNECTION)}/>
              <div className={classes.borderOverlay} />
            </Grid>

          </Grid>

          <Grid 
            item 
            xs={8} 
            className={classNames(
              classes.mapWrapper, 
              classes.mapWrapperBottomSelected
            )}
          >
            <FleetMapCard fleetTab={selectedTab} onSelectButton={handleSelectButton} selectedButton={selectedButton} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.primary.background,
  },
  gridContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(2)})`,
  },
  mapWrapper: {
    display: 'flex',
    border: 'solid',
    borderColor: Colors.primary.main,
    borderRadius: theme.spacing(1),
  },
  mapWrapperTopSelected: {
    borderTopLeftRadius: 0,
  },
  mapWrapperBottomSelected: {
    borderBottomLeftRadius: 0,
  },
  chartWrapper: {
    position: 'relative',
    border: 'solid',
    borderColor: Colors.primary.background,
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
  },
  // Covers a portion of the map border to give the illusion of a tab
  borderOverlay: {
    width: 10,
    top: 0,
    bottom: 0,
    position: 'absolute',
    right: -9,
  },
  selectedChartWrapper: {
    borderColor: Colors.primary.main,
    '& $borderOverlay': {
      backgroundColor: Colors.primary.background,
    },
  },
});

FleetOverviewContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  locationFilter: PropTypes.string,
  companyFilter: PropTypes.string,

  setFleetOverviewLocationFilter: PropTypes.func.isRequired,
  setFleetOverviewCompanyFilter: PropTypes.func.isRequired,
  getLocationsWithAlerts: PropTypes.func.isRequired,
  getMonitoringLocations: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    locations: state.monitoring.get('locations'),
    fleetOverviewTab: state.monitoring.get('fleetOverviewTab'),
    locationFilter: state.monitoring.get('fleetOverviewFilters').locationFilter,
    companyFilter: state.monitoring.get('fleetOverviewFilters').companyFilter,
  };
};

const prepareForExport = compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      setPageTitle,
      getMonitoringLocations,
      setFleetOverviewLocationFilter,
      setFleetOverviewCompanyFilter,
    }
  ),
);

const FleetOverview = prepareForExport(FleetOverviewContainer);

export default FleetOverview;
