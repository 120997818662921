import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { GET_COMMAND_SETS_FAILURE, GET_IOT_LOCATIONS_FAILURE, getCommandSets, getIotLocations } from './iotCommand.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';
import ButtonGroup from '../layout/buttonGroup.component';
import CommandSetTable from './commandList/commandSetTable.component';
import LoadingOverlay from '../layout/loadingOverlay/loadingOverlay.component';
import LocationsTable from './commandList/locationTable.component';

const TAB = {
  LOCATIONS: 'LOCATIONS',
  COMMANDS: 'COMMANDS',
};

const styles = () => ({
  container: {
    width: 'calc(100% - 24px)',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 'calc(100% - 5rem)',
  },
  buttonContainer: {
    paddingTop: 24,
    textAlign: 'right',
  },
});

const CommandsContainer = ({
  classes,
  isLoading,
  commandSets,
  setPageTitle,
  getCommandSets,
  history,
  handleToastMessage,
  getIotLocations,
  locations,
  match,
}) => {

  const [currentTab, setCurrentTab] = useState(TAB.COMMANDS);
  
  // Set Page Title
  useEffect(() => {
    setPageTitle('IoT Command Center');
  }, []);

  // Fetch Command Sets or Locations based on the url
  useEffect(() => {
    const fetchData = async () => {
      if (match.path === '/iotCommands/locations'){
        const locationsResponse = await getIotLocations();
        if (locationsResponse.type === GET_IOT_LOCATIONS_FAILURE) {
          handleToastMessage(`Error getting iot locations: ${locationsResponse.messages[0]}`, true);
        }
      } else {
        const response = await getCommandSets();
        if (response.type === GET_COMMAND_SETS_FAILURE) {
          handleToastMessage(`Error getting command sets: ${response.messages[0]}`, true);
        }
      }
    };
    fetchData();
  }, []);

  // Check the URL to determine if the current tab is locations
  useEffect(() => {
    if (match.path === '/iotCommands/locations'){
      setCurrentTab(TAB.LOCATIONS);
    }
  }, []);
    
  if (isLoading === true) {
    return <LoadingOverlay />;
  }

  return (
    <div className={classnames([classes.container, 'page-padding-bottom'])}>
      <div className={classes.buttonContainer}>
        <ButtonGroup
          buttons={[TAB.COMMANDS, TAB.LOCATIONS]}
          exclusive={true}
          selectedButton={currentTab}
          handleSelectButton={(selectedButton) => {
            if (selectedButton === TAB.COMMANDS) {
              history.replace('/iotCommands');
            } else if (selectedButton === TAB.LOCATIONS) {
              history.replace('/iotCommands/locations');
            }
          }}
        />
      </div>

      {currentTab === TAB.COMMANDS && (
        <CommandSetTable
          data={commandSets}
          history={history}
        />
      )}

      {currentTab === TAB.LOCATIONS && (
        <LocationsTable
          data={locations}
          history={history}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    commandSets: state.iotCommand.get('commandSets'),
    locations: state.iotCommand.get('locations'),
    isLoading: state.iotCommand.get('isLoading'),
  };
};

CommandsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  getCommandSets: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  commandSets: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  getIotLocations: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles),
  withRouter,
  connect(
    mapStateToProps,
    {
      setPageTitle,
      getCommandSets,
      handleToastMessage,
      getIotLocations,
    }
  ),
);

export default prepareForExport(CommandsContainer);