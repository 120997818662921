import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { isNil } from 'lodash';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import React from 'react';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';

import { NETWORK_TYPE, SIGNAL_QUALITY } from '../../monitoring.actions';
import { OCCUPANCY_TYPES } from '../../../../types/location.types';
import { getLastEventText, getSignalQuality, isOnline } from '../../../../utilities/monitoringUtils';
import Colors from '../../../../styles/colors';
import ConnectionStatItem from '../connectionStatItem.component';

const ConnectionStats = ({location, locationStatus, classes}) => {

  // Connection
  let connectionTitle = 'POD Offline';
  let connectionIcon = CloudOffIcon;
  let connectionSubtitle = '';
  const connectionDate = location.lastEvent;
  let connectionColor = Colors.charts.signalStrengthStatus.poor;
  if (isOnline(location)) {
    connectionTitle = 'POD Online';
    connectionIcon = CloudIcon;
    connectionColor = Colors.charts.signalStrengthStatus.excellent;
  }
  connectionSubtitle = `${getLastEventText(location)} on`;

  // Occupancy
  let occupancyTitle = 'Occupancy Unknown';
  let occupancyIcon = HelpIcon;
  let occupancyDate = null;
  let occupancyColor = '';
  if (locationStatus.status) {
    occupancyDate = locationStatus.status.timestamp;
    switch (locationStatus.status.value) {
      case OCCUPANCY_TYPES.AVAILABLE:
        occupancyTitle = 'Available';
        occupancyIcon = AccountBoxIcon;
        occupancyColor = Colors.charts.signalStrengthStatus.excellent;
        break;
      case OCCUPANCY_TYPES.OCCUPIED:
        occupancyTitle = 'Occupied';
        occupancyIcon = AccountBoxIcon;
        occupancyColor = Colors.charts.signalStrengthStatus.poor;
        break;
      default:
        break;
    }
  }

  // Signal
  let signalTitle = 'Signal Unknown';
  let signalSubtitle = '';
  let signalIcon = HelpIcon;
  let signalDate = '';
  let signalColor = '';
  if (locationStatus.signalStrength && !isNil(locationStatus.signalStrength.value)) {
    signalDate = locationStatus.signalStrength.timestamp;
    const signalQuality = getSignalQuality(locationStatus.signalStrength.value, location);

    signalTitle = `${signalQuality} Connection`;
    signalSubtitle = `${locationStatus.network.value} Connection`;

    signalColor = Colors.charts.signalStrengthStatus[signalQuality?.toLowerCase()];

    if (locationStatus.network.value === NETWORK_TYPE.LTE) {
      switch (signalQuality) {
        case SIGNAL_QUALITY.EXCELLENT:
          signalIcon = SignalCellular4BarIcon;
          break;
        case SIGNAL_QUALITY.GOOD:
          signalIcon = SignalCellular3BarIcon;
          break;
        case SIGNAL_QUALITY.FAIR:
          signalIcon = SignalCellular2BarIcon;
          break;
        case SIGNAL_QUALITY.POOR:
          signalIcon = SignalCellular1BarIcon;
          break;
        default: 
          break;
      }
    } else if (locationStatus.network.value === NETWORK_TYPE.WIFI) {
      switch (signalQuality) {
        case SIGNAL_QUALITY.EXCELLENT:
          signalIcon = SignalWifi4BarIcon;
          break;
        case SIGNAL_QUALITY.GOOD:
          signalIcon = SignalWifi3BarIcon;
          break;
        case SIGNAL_QUALITY.FAIR:
          signalIcon = SignalWifi2BarIcon;
          break;
        case SIGNAL_QUALITY.POOR:
          signalIcon = SignalWifi1BarIcon;
          break;
        default:
          break;
      }
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} className={classes.statWrapper}>
        <ConnectionStatItem 
          title={connectionTitle}
          datePrefix={connectionSubtitle}
          icon={connectionIcon}
          date={connectionDate}
          color={connectionColor}
        />
      </Grid>
      <Grid item xs={4} className={classes.statWrapper}>
        <ConnectionStatItem 
          title={occupancyTitle}
          icon={occupancyIcon}
          date={occupancyDate}
          color={occupancyColor}
        />
      </Grid>
      <Grid item xs={4} className={classes.statWrapper}>
        <ConnectionStatItem 
          title={signalTitle}
          subtitle={signalSubtitle}
          icon={signalIcon}
          date={signalDate}
          color={signalColor}
        />
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  statWrapper: {
    display: 'flex',
  },
});

ConnectionStats.propTypes = {
  classes: PropTypes.object.isRequired,
  locationStatus: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles)
);

export default prepareForExport(ConnectionStats);
