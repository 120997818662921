import { TableCell, TableRow, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { DATE_FORMAT } from '../../utilities/dateUtils';
import { GET_LOCATIONS_FAILURE, getMonitoringLocations } from './monitoring.actions';
import { exportCSVFile } from '../../utilities/csvUtils';
import { getLastEventDate, getLastEventText, isOnline } from '../../utilities/monitoringUtils';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';

import Colors from '../../styles/colors';
import DataTable from '../layout/dataTable/dataTable.component';
import LoadingOverlay from '../layout/loadingOverlay/loadingOverlay.component';

const TABLE_PADDING = 'dense';

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'name', numeric: false, padding: TABLE_PADDING, label: 'Name', sortable: true, filtering: true },
  { id: 'hubspotCompanyName', numeric: false, padding: TABLE_PADDING, label: 'Hubspot Company', sortable: true, filtering: true },
  { id: 'address', numeric: false, padding: TABLE_PADDING, label: 'Address', sortable: true, filtering: true },
  { id: 'city', numeric: false, padding: TABLE_PADDING, label: 'City', sortable: true, filtering: true },
  { id: 'state', numeric: false, padding: TABLE_PADDING, label: 'State', sortable: true, filtering: true },
  { id: 'country', numeric: false, padding: TABLE_PADDING, label: 'Country', sortable: true, filtering: true },
  { id: 'podDeviceSerial', numeric: false, padding: TABLE_PADDING, label: 'Pod Serial', sortable: true, filtering: true },
  { id: 'podModemSerial', numeric: false, padding: TABLE_PADDING, label: 'PCD Serial', sortable: true, filtering: true },
  { id: 'podSoftwareVersion', numeric: false, padding: TABLE_PADDING, label: 'PCD Software Version', sortable: true, filtering: true },
  {
    id: 'firstEventDate',
    numeric: false,
    padding: TABLE_PADDING,
    label: 'First Connection Received',
    sortable: true,
    filtering: true,
    filterOptions: ['false', 'true'],
    filterId: 'hasFirstEvent',
  },
  {
    id: 'lastEventDate',
    numeric: false,
    padding: TABLE_PADDING,
    label: 'Last Connection Received',
    sortable: true,
    filtering: true,
    filterOptions: ['OFFLINE', 'online'],
    filterId: 'connectionStatus',
  },
  { id: 'lifecycleStatus', numeric: false, padding: TABLE_PADDING, label: 'Lifecycle Status', sortable: true, filtering: true },
  { id: 'appDisplayLevel', numeric: false, padding: TABLE_PADDING, label: 'App Display Level', sortable: true, filtering: true },
];

const LocationsContainer = ({ locations, getMonitoringLocations, isLoading, classes, location, history, setPageTitle }) => {
  const [defaultFilters, setDefaultFilters] = useState({});
  const [defaultSortField, setDefaultSortField] = useState(null);
  const [defaultSortOrder, setDefaultSortOrder] = useState('asc');

  // Set Page Title
  // Fetch Locations
  useEffect(() => {
    setPageTitle('Overview Data');
    const fetchData = async () => {
      const locationsResponse = await getMonitoringLocations();
      if (locationsResponse.type === GET_LOCATIONS_FAILURE) {
        handleToastMessage(`Error getting locations: ${locationsResponse.messages[0]}`, true);
      }
    };
    if (!locations || locations.length < 1) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramFilters = {};
    queryParams.forEach((value, key) => {
      paramFilters[key] = value;
    });
    if (paramFilters['defaultSortField']) {
      setDefaultSortField(paramFilters['defaultSortField']);
      delete paramFilters['defaultSortField'];
      setDefaultSortOrder(paramFilters['defaultSortOrder']);
      delete paramFilters['defaultSortOrder'];
    }
    setDefaultFilters(paramFilters);
  }, [location]);

  const formattedData = useMemo(
    () =>
      locations.map(row => {
        row = { ...row };
        row.address = `${row.addressOne} ${row.addressTwo}`;
        row.connectionStatus = isOnline(row) ? 'online' : 'OFFLINE';
        row.hasFirstEvent = row.firstEventDate ? 'true' : 'false';
        row.formattedFirstEventDate = row.firstEventDate ? moment(row.firstEventDate).format(DATE_FORMAT.DATE_TIME) : 'Never connected';
        row.lastEventDate = getLastEventDate(row);
        row.formattedLastEventDate = row.lastEventDate
          ? moment(row.lastEventDate).format(DATE_FORMAT.DATE_TIME)
          : 'Never connected';
        row.lastEventText = getLastEventText(row);
        row.lastConnectionTextForExport = row.lastConnectionEventType ? row.lastConnectionEventType : row.lastEvent ? 'DATA' : '';
        return row;
      }),
    [locations]
  );

  if (isLoading === true) {
    return <LoadingOverlay />;
  }

  const handleExport = filteredData => () => {
    const csvColumns = columns.filter(column => column.id !== 'firstEventDate' && column.id !== 'lastEvent');
    const stateIndex = columns.findIndex(column => column.id === 'state');
    csvColumns.splice(stateIndex + 1, 0, { id: 'zip', label: 'Zip' });
    exportCSVFile(
      [
        ...csvColumns,
        { id: 'podHardwareIdentifier', label: 'Pod Mac ID' },
        { id: 'connectionStatus', label: 'Connection Status' },
        { id: 'formattedFirstEventDate', label: 'First Connection Received' },
        { id: 'formattedLastEventDate', label: 'Last Connection Received' },
        { id: 'lastConnectionTextForExport', label: 'Last Connection Event Type' },
      ],
      filteredData,
      `locations_${moment().format('YYYY-MM-DD')}`
    );
  };

  const goToLocation = location => {
    history.push({
      pathname: `/locations/${location.id}`,
      search: 'tab=OVERVIEW',
    });
  };

  // get custom row render component
  const renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow tabIndex={-1} onClick={() => goToLocation(row)} hover className={classes.row}>
          <TableCell align="left" padding={TABLE_PADDING} className={classes.podName}>
            {row.name}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>
            {row.hubspotCompanyName}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>
            {row.address}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>
            {row.city}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>
            {row.state}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>
            {row.country}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>
            {row.podDeviceSerial}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>
            {row.podModemSerial}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>
            {row.podSoftwareVersion}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING} className={row.firstEventDate ? null : classes.error}>
            {row.formattedFirstEventDate}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING} className={isOnline(row) ? null : classes.error}>
            <Tooltip title={row.formattedLastEventDate}>
              <div>
                {row.lastEventDate && (
                  <Fragment>
                    <div>{row.lastEventText}</div>
                    <div>{moment(row.lastEventDate).fromNow()}</div>
                  </Fragment>
                )}
                {!row.lastEventDate && <div>Never connected</div>}
              </div>
            </Tooltip>
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>
            {row.lifecycleStatus}
          </TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>
            {row.appDisplayLevel}
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <DataTable
      data={formattedData}
      title={''}
      columns={columns}
      renderRow={renderRow}
      disableSearch
      defaultSortOrder={defaultSortOrder}
      onExportClick={handleExport}
      defaultFilters={defaultFilters}
      defaultSortField={defaultSortField}
    />
  );
};

const styles = () => ({
  error: {
    color: Colors.error.main,
  },
  podName: {
    fontFamily: 'GothamRounded-Bold',
    color: Colors.primary.main,
    '&:hover': {
      opacity: 0.6,
    },
  },
  row: {
    cursor: 'pointer',
  },
});

const mapStateToProps = state => {
  return {
    locations: state.monitoring.get('locations'),
    isLoading: state.monitoring.get('isLoading'),
  };
};

LocationsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,

  handleToastMessage: PropTypes.func.isRequired,
  getMonitoringLocations: PropTypes.func.isRequired,

  // Injected by React Router
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    setPageTitle,
    handleToastMessage,
    getMonitoringLocations,
  })
);

export default prepareForExport(LocationsContainer);
