import { HARDWARE_FIELDS } from '../modules/location/editComponents/hardwareFields';
import { HARDWARE_TYPES } from '../types/location.types';
import { isEmpty } from 'lodash';

export const AMAZON_FIELDS = [
  {
    columns: 12,
    label: 'Amazon Project Type',
    key: 'amazonProjectType',
  },
  {
    columns: 6,
    label: 'Amazon Network Type',
    key: 'amazonNetworkType',
  },
  {
    columns: 6,
    label: 'Amazon Site Code',
    key: 'amazonSiteCode',
  },
];

export const isTTLockType = location => location.podHardwareType === HARDWARE_TYPES.PCD_PAIRED_WITH_TTLOCK || location.podHardwareType === HARDWARE_TYPES.VAROOM_TTLOCK || location.podHardwareType === HARDWARE_TYPES.TTLOCK_POD; 

export const isInitializedTTLock = location => !!location[HARDWARE_FIELDS.TTLOCK_CONNECTION_STRING];

export const isFalsyOrEmpty = value => {
  return value === undefined || value === null || (typeof value === 'string' && isEmpty(value));
};

export const getParticleConsoleUrl = location => {
  if (!location[HARDWARE_FIELDS.POD_MODEM_SERIAL]) {
    return null;
  }
  // https://mamava.atlassian.net/browse/MA-13111
  // Switch particle console url according to PCD serial number
  const REGEX_STARTS_WITH_INT_PREFIX = /^PCD-INT-/;
  const particleProductId = (location[HARDWARE_FIELDS.POD_MODEM_SERIAL]?.match(REGEX_STARTS_WITH_INT_PREFIX) && process.env.REACT_APP_PARTICLE_PRODUCT_INT_ID) ? process.env.REACT_APP_PARTICLE_PRODUCT_INT_ID : process.env.REACT_APP_PARTICLE_PRODUCT_ID;
  return `https://console.particle.io/${particleProductId}/devices?deviceName=${location[HARDWARE_FIELDS.POD_MODEM_SERIAL]}`;
};