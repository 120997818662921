const Colors = {
  primary: {
    light: '#1D19AC',
    main: '#070D80',
    dark: '#777',
    contrastText: '#fff',
    background: '#EEEEEE',
  },
  secondary: {
    light: 'rgb(238, 238, 238)',
    main: '#BAB7B7',
    dark: '#ba000d',
    contrastText: '#000',
  },
  white: '#fff',
  info: {
    light: '#64b5f6',
    main: '#2196f3',
  },
  success: {
    light: '#81c784',
    main: '#4caf50',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
  },
  charts: {
    lineChartBackground: 'rgb(7, 13, 128, 0.5)',
    scale: {
      low: 'rgb(16,46,209,0.15)',
      med: 'rgba(0,128,0,0.15)',
      high: 'rgb(198,110,144,0.15)',
    },
    signalStrength: {
      excellent: 'rgb(76, 175, 79, 0.15)',
      good: 'rgb(255, 247, 0, 0.15)',
      fair: 'rgb(255, 174, 0, 0.15)',
      poor: 'rgb(255, 0, 0, 0.15)',
    },
    signalStrengthStatus: {
      all: 'rgba(7, 13, 128)',
      excellent: 'rgba(76, 175, 79)',
      good: 'rgba(255, 174, 0)',
      fair: 'rgba(255, 174, 0)',
      poor: 'rgba(255, 0, 0)',
    },
  },
};

export default Colors;
