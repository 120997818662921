import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import moment from 'moment';

import { setDirtyForm } from '../layout/layout.actions';
import AmenitiesSection from './editComponents/amenitiesSection.component';
import HardwareSection from './editComponents/hardwareSection.component';
import LifecycleStatusSection from './editComponents/lifecycleStatusSection.component';

function EditPrebuiltLocation({ classes, onChange, selectedLocation }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDirtyForm(false));
  }, []);
  return (
    <Card className={classes.detailsCard} id="detailsCard">
      <CardContent>
        <Grid container spacing={2}>

          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LifecycleStatusSection
                  onChange={onChange}
                  selectedLocation={selectedLocation}
                />
              </Grid>
              {
                selectedLocation.deprecatedDate &&
                <Grid item xs={12}>
                  <Typography variant="body2">Deprecated Date: {moment(selectedLocation.deprecatedDate).utc().format('MM/DD/YYYY')}</Typography>
                </Grid>
              }
            </Grid>

            <Divider
              className={classes.divider}
              sx={{
                opacity: "0.6"
              }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Descriptions</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="details"
                  label="Internal Mamava notes"
                  multiline
                  rows="4"
                  fullWidth
                  value={selectedLocation.details || ''}
                  onChange={onChange}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Divider
              className={classes.divider}
              sx={{
                opacity: "0.6"
              }} />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AmenitiesSection
                  onChange={onChange}
                  selectedLocation={selectedLocation}
                />
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={6}>

            <Grid container spacing={2}>
              
              <Divider
                className={classes.divider}
                sx={{
                  opacity: "0.6"
                }} />

              <HardwareSection
                isAdminMode={true}
                onChange={onChange}
                selectedLocation={selectedLocation}
              />
            </Grid>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
}


EditPrebuiltLocation.propTypes = {
  selectedLocation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const styles = theme => ({
  detailsCard: {
    margin: 10,
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
});
export default withStyles(styles, { withTheme: true })(EditPrebuiltLocation);
