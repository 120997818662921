import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { AMAZON_FIELDS } from '../../../utilities/locationUtils';
import { AdminEnabledFields } from './editLocation.settings';
import { InputGroup } from './inputGroup.component';
import { ORG_TYPE_OPTIONS, SUBCATEGORY_OPTIONS } from '../../../types/location.types';

export default function CompanySection({ selectedLocation, isAdminMode, isHubspotPreviewMode, onChange }) {
  return (
    <Grid container spacing={2}>
      <InputGroup disableAllChildren={isHubspotPreviewMode} disabledFieldNames={!isAdminMode && AdminEnabledFields}>
        <Grid item xs={6}>
          <TextField
            label="Hubspot ID"
            variant="outlined"
            name="hubspotObjectId"
            fullWidth
            value={selectedLocation.hubspotObjectId}
            onChange={onChange}
          />
        </Grid>
        {selectedLocation.hubspotObjectId && (
          <Grid item xs={6}>
            <a href={`${selectedLocation.hubspotTicketUrl}`} target="_blank">
              <Typography variant="subtitle">
                Link to Hubspot Ticket
              </Typography>
            </a>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            label="Company (From Hubspot)"
            variant="outlined"
            name="hubspotCompanyId"
            disabled={selectedLocation.accessCodes?.length >0}
            fullWidth
            value={isAdminMode ? selectedLocation.hubspotCompanyId : selectedLocation.hubspotCompanyName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <InputLabel shrink>Org Type</InputLabel>
            <Select
              aria-label="orgType"
              name="orgType"
              value={selectedLocation.orgType}
              onChange={onChange}
              renderValue={value => value}
              input={
                <OutlinedInput labelWidth={170} />
              }
            >
              {ORG_TYPE_OPTIONS.map(orgTypeOption => (
                <MenuItem value={orgTypeOption}>
                  {orgTypeOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
          >
            <InputLabel shrink>Subcategory</InputLabel>
            <Select
              aria-label="orgType_subCategory"
              name="orgType_subCategory"
              value={selectedLocation.orgType_subCategory}
              onChange={onChange}
              renderValue={value => value}
              input={
                <OutlinedInput labelWidth={170} />
              }
            >
              {SUBCATEGORY_OPTIONS.map(orgTypeOption => (
                <MenuItem value={orgTypeOption}>
                  {orgTypeOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        
        {selectedLocation.hubspotCompanyName === 'Amazon' &&
          AMAZON_FIELDS.map(field => (
            <Grid item xs={field.columns ? field.columns : 12}>
              <TextField
                key={field.label}
                label={field.label}
                variant="outlined"
                name={field.key}
                fullWidth
                value={selectedLocation[field.key]}
                onChange={onChange}
              />
            </Grid>
          ))}
      </InputGroup>
    </Grid>
  );
}

CompanySection.propTypes = {
  selectedLocation: PropTypes.object.isRequired,
  isAdminMode: PropTypes.bool,
  isHubspotPreviewMode: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
