import { Card, CardContent } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { CONNECTION_STATUS } from '../monitoring.actions';
import { fleetOverviewSelectors } from '../monitoring.selectors';
import { getConnectionColor, getFilterUrlParams } from '../../../utilities/monitoringUtils';
import { roundUpToNextMultipleOfX } from '../../../utilities/dataUtils';
import BarChart from '../../layout/charts/barChart.component';
import FleetOverviewCardHeader from './fleetOverviewCardHeader.component';

const CHART_ASPECT_RATIO = 3.5;

const ConnectionChartContainer = ({
  history,
  locationsByConnection,
  publishedLocations,
  isLoading,
  onViewMap,
  fleetOverviewFilters,
}) => {

  const goToLocationList = () => {
    history.push({
      pathname: '/monitoring/locations',
      search: `?isPublished=true${getFilterUrlParams(fleetOverviewFilters)}`,
    });
  };

  const chartData = useMemo(() => (
    [{
      value: locationsByConnection[CONNECTION_STATUS.NEVER_CONNECTED].length,
      color: getConnectionColor(CONNECTION_STATUS.NEVER_CONNECTED),
      label: CONNECTION_STATUS.NEVER_CONNECTED,
    }, {
      value: locationsByConnection[CONNECTION_STATUS.OFFLINE].length,
      color: getConnectionColor(CONNECTION_STATUS.OFFLINE),
      label: CONNECTION_STATUS.OFFLINE,
    }, {
      value: locationsByConnection[CONNECTION_STATUS.ONLINE].length,
      color: getConnectionColor(CONNECTION_STATUS.ONLINE),
      label: CONNECTION_STATUS.ONLINE,
    }]
  ), [locationsByConnection, isLoading]);

  const maxLocationCount = Math.max(locationsByConnection[CONNECTION_STATUS.NEVER_CONNECTED].length, locationsByConnection[CONNECTION_STATUS.OFFLINE].length, locationsByConnection[CONNECTION_STATUS.ONLINE].length);
  // Round up
  const chartMax = roundUpToNextMultipleOfX(maxLocationCount + 1, 5);

  return (
    <Card>
      <FleetOverviewCardHeader
        title="SmartPod Published Pods"
        subheader={`${publishedLocations.length} Published Pods`}
        onViewDetails={goToLocationList}
        onViewMap={onViewMap}
      />
      <CardContent>
        <BarChart
          isLoading={isLoading}
          data={chartData}
          aspectRatio={CHART_ASPECT_RATIO}
          xRange={[0, chartMax]}
        />
      </CardContent>
    </Card>
  );
};

ConnectionChartContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  publishedLocations: PropTypes.array.isRequired,
  locationsByConnection: PropTypes.object.isRequired,
  fleetOverviewFilters: PropTypes.object.isRequired,

  onViewMap: PropTypes.func.isRequired,
  // Injected by React Router
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.monitoring.get('isLoading'),
    locationsByConnection: fleetOverviewSelectors.selectLocationsByConnection(state),
    publishedLocations: fleetOverviewSelectors.selectPublishedLocations(state),
    fleetOverviewFilters: state.monitoring.get('fleetOverviewFilters'),
  };
};

const prepareForExport = compose(
  withRouter,
  connect(
    mapStateToProps,
  ),
);

const ConnectionChart = prepareForExport(ConnectionChartContainer);

export default ConnectionChart;