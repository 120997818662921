
import { CUSTOM_COMMAND_INDEX, EMPTY_COMMAND_INDEX } from '../iotCommand.actions';
import { Grid, MenuItem, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const CommandFormBasics = ({
  readonly,
  description,
  selectedCommandIndex,
  
  setDescription,
  setSelectedCommandIndex,
  commandConfig,
}) => (
  <Grid 
    item
    container 
    spacing={2}
  >
    <Grid item xs={12}>
      <TextField
        disabled={readonly}
        margin="normal"
        label="Description"
        placeholder="Command set description"
        fullWidth
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </Grid>

    <Grid item xs={12}>
      <TextField
        disabled={readonly}
        fullWidth
        margin="normal"
        select
        label="Command"
        value={selectedCommandIndex}
        InputLabelProps={{ shrink: selectedCommandIndex !== EMPTY_COMMAND_INDEX }}
        onChange={(e) => {
          setSelectedCommandIndex(e.target.value);
        }}
      >
        {[
          commandConfig.map((command, commandIndex) => (
            <MenuItem
              value={commandIndex}
              key={command.name}
            >{command.name}</MenuItem>
          )),
          <MenuItem
            value={CUSTOM_COMMAND_INDEX}
            key="custom"
          >
            Custom Command
          </MenuItem>,
        ]}
      </TextField>
    </Grid>
  </Grid>
);

CommandFormBasics.defaultProps = {
  readonly: false,
  setDescription: () => {},
  setSelectedCommandIndex: () => {},
};

CommandFormBasics.propTypes = {
  readonly: PropTypes.bool,
  commandConfig: PropTypes.array.isRequired,
  
  description: PropTypes.string,
  selectedCommandIndex: PropTypes.number.isRequired,

  setDescription: PropTypes.func,
  setSelectedCommandIndex: PropTypes.func,
};

export default CommandFormBasics;