import { Button, CardHeader } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';

import Colors from '../../../styles/colors';

const FleetOverviewCardHeaderComponent = ({classes, onViewDetails, title, subheader, onViewMap}) => {
  return (
    <CardHeader
      title={title}
      subheader={subheader}
      action={
        <div>
          <Button
            onClick={onViewMap}
            size="small"
            className={classes.button}
          >
            View On Map
          </Button>
          <Button 
            onClick={onViewDetails}
            size="small"
            variant="outlined"
            className={classes.button}
          >
            View Details
          </Button>
        </div>
      }
      classes={{title: classes.cardTitle}}
    />
  );
};

const styles = () => ({
  cardTitle: {
    fontSize: '1.2rem',
    lineHeight: 1,
  },
  button: {
    color: Colors.primary.main,
    fontSize: '0.7rem',
  },
});

FleetOverviewCardHeaderComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  subheader: PropTypes.string,

  onViewDetails: PropTypes.func.isRequired,
  onViewMap: PropTypes.func.isRequired,
};

const prepareForExport = compose(
  withStyles(styles)
);

const FleetOverviewCardHeader = prepareForExport(FleetOverviewCardHeaderComponent);
export default FleetOverviewCardHeader;