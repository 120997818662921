import {
  HIDE_TOAST_MESSAGE,
  SET_DIRTY_FORM,
  SET_PAGE_TITLE,
  SET_RESPONSIVE_BREAKPOINT,
  SET_TOAST_POSITION,
  SHOW_TOAST_MESSAGE,
  TOGGLE_DRAWER_MENU,
} from './layout.actions';
import Immutable from 'immutable';
import responsiveBreakpoints from './responsiveBreakpoints';

const initialState = Immutable.Map({
  pageTitle: 'Home',
  responsiveBreakpoint: responsiveBreakpoints.LARGE,

  isShowToast: false,
  toastMessage: '',
  isToastFailure: false,
  toastPosition: '0',
  isDrawerMenuOpen: false,
  hasDirtyForm: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_TITLE:
      console.log('page title action', action);
      return state
        .set('pageTitle', action.payload)
        .set('breadcrumb', action.breadcrumb)
        .set('breadcrumbTitle', action.breadcrumbTitle);

    case SET_RESPONSIVE_BREAKPOINT:
      return state.set('responsiveBreakpoint', action.payload);

    case SHOW_TOAST_MESSAGE:
      return state
        .set('isShowToast', true)
        .set('toastMessage', action.message)
        .set('isToastFailure', action.isFailure);

    case HIDE_TOAST_MESSAGE:
      return state.set('isShowToast', false);

    case SET_TOAST_POSITION:
      return state.set('toastPosition', action.position);

    case TOGGLE_DRAWER_MENU:
      return state.set('isDrawerMenuOpen', !state.get('isDrawerMenuOpen'));

    case SET_DIRTY_FORM:
      return state.set('hasDirtyForm', action.payload.flag);

    default:
      return state;
  }
}
