import { TableCell, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import moment from 'moment';

import { ACTION_TYPES } from '../../../../types/event.types';
import { DATE_FORMAT } from '../../../../utilities/dateUtils';
import { exportCSVFile } from '../../../../utilities/csvUtils';
import DataTable from '../../../layout/dataTable/dataTable.component';

const TABLE_PADDING = 'dense';

const columnsDefinitions = [
  { id: 'timestamp', numeric: false, padding: TABLE_PADDING, label: 'Timestamp', sortable: true, filtering: false },
  { id: 'email', numeric: false, padding: TABLE_PADDING, label: 'Email', sortable: true, filtering: false },
  { id: 'entryMethod', numeric: false, padding: TABLE_PADDING, label: 'Entry Method', sortable: true, filtering: false },
  { id: 'isSuccessfulUnlock', numeric: false, padding: TABLE_PADDING, label: 'Success/Failure', sortable: true, filtering: false },
];

const formatEntryMethod = (entryMethod, pinCode) => {
  switch (entryMethod) {
    case ACTION_TYPES.PIN_UNLOCK:
    case ACTION_TYPES.PIN_FAIL:
      return `Pin ${pinCode}`;
    case ACTION_TYPES.APP_UNLOCK:
      return 'App Unlock';
    default:
      return 'Unknown';
  }
};

const formatIsSuccessfullLabel = isSuccessfulUnlock => {
  return isSuccessfulUnlock === null ? '' : isSuccessfulUnlock ? 'Success' : 'Failure';
};

const renderRow = (row, i) => {

  return (
    <Fragment key={i}>
      <TableRow
        tabIndex={-1}
        hover
      >
        <TableCell align="left" padding={TABLE_PADDING}>{row.timestamp}</TableCell>
        <TableCell align="left" padding={TABLE_PADDING}>{row.email}</TableCell>
        <TableCell align="left" padding={TABLE_PADDING}>{row.entryMethod}</TableCell>
        <TableCell align="left" padding={TABLE_PADDING}>{row.isSuccessfulUnlock}</TableCell>
      </TableRow>
    </Fragment>
  );
};

const handleExport = filteredData => () => {
  const csvColumns = columnsDefinitions.filter(column => column.id !== 'timestamp');

  exportCSVFile([
    ...csvColumns,
    {id: 'formattedTimestamp', label: 'Timestamp'},
  ], filteredData, `unlocks_${moment().format('YYYY-MM-DD')}`);
};

const UnlocksTable = ({ unlocksHistory }) => {
  const formattedData = useMemo(
    () => unlocksHistory.map(row => {
      return {
        timestamp: moment(row.timestamp).format(DATE_FORMAT.DATE_TIME),
        email: row.email,
        entryMethod: formatEntryMethod(row.entryMethod, row.pinEntered),
        isSuccessfulUnlock: formatIsSuccessfullLabel(row.isSuccessfulUnlock),
        formattedTimestamp: moment(row.timestamp).format(DATE_FORMAT.EXPORT),
      };
    }), [unlocksHistory]);

  return (
    <DataTable
      data={formattedData}
      title={''}
      columns={columnsDefinitions}
      renderRow={renderRow}
      disableSearch
      defaultSortOrder="asc"
      onExportClick={handleExport}
    />
  );
};

const styles = () => ({
});

UnlocksTable.propTypes = {
  classes: PropTypes.object.isRequired,
  unlocksHistory: PropTypes.array.isRequired,
};

const prepareForExport = compose(
  withStyles(styles)
);

export default prepareForExport(UnlocksTable);
