import { findIndex } from 'lodash';
import Immutable from 'immutable';

import { 
  CLEAR_LOCATIONS,
  CLEAR_SEARCH_FILTERS, 
  GET_LOCATIONS_FAILURE, 
  GET_LOCATIONS_REQUEST, 
  GET_LOCATIONS_SUCCESS, 
  SEARCH_LOCATIONS_FAILURE,
  SEARCH_LOCATIONS_REQUEST,
  SEARCH_LOCATIONS_SUCCESS,
  SET_SEARCH_FILTERS } from './locations.actions';
import {
  CREATE_LOCATION_SUCCESS,
  DELETE_LOCATION_SUCCESS,
  UPDATE_LOCATION_SUCCESS,
} from '../location/location.actions';
import { LOCATION_TYPES } from '../../types/location.types';

export const SEARCH_MODES = {
  SEARCH: 'SEARCH',
  RESULTS: 'RESULTS',
};

const initialState = Immutable.Map({
  locations: [],
  searchFilters: {
    locationName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    hubspotCompanyName: '',
    podDeviceSerial: '',
    podHardwareIdentifier: '',
    podModemSerial: '',
    lifecycleStatus: '',
    lockSerial: '',
    podHardwareType: '',
    type: LOCATION_TYPES.MAMAVA,
  },
  searchMode: SEARCH_MODES.SEARCH,
  isLoading: false,
  errors: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LOCATIONS_SUCCESS:
      return state
        .set('locations', action.response)
        .set('isLoading', false)
        .set('searchMode', SEARCH_MODES.RESULTS);

    case GET_LOCATIONS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_LOCATIONS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case SEARCH_LOCATIONS_SUCCESS:
      return state
        .set('locations', action.response)
        .set('isLoading', false)
        .set('searchMode', SEARCH_MODES.RESULTS);

    case SEARCH_LOCATIONS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case SEARCH_LOCATIONS_REQUEST:
      return state.set('errors', []).set('isLoading', true);


    case CREATE_LOCATION_SUCCESS: {
      let locations = [...state.get('locations')];
      let newLocation = { ...action.response };
      locations.push(newLocation);

      return state.set('locations', locations);
    }

    case UPDATE_LOCATION_SUCCESS: {
      let locations = [...state.get('locations')];
      let locationId = action.actionMetadata;

      let idx = findIndex(locations, { id: locationId });
      if (idx > -1) {
        locations[idx] = Object.assign(locations[idx], action.payload);
      }

      return state.set('locations', locations);
    }

    case DELETE_LOCATION_SUCCESS: {
      let locations = [...state.get('locations')];

      let idx = findIndex(locations, { id: action.actionMetadata.locationId });
      if (idx > -1) {
        locations.splice(idx, 1);
      }

      return state.set('locations', locations);
    }
    case SET_SEARCH_FILTERS: {
      return state.set('searchFilters', action.searchFilters);
    }
    case CLEAR_SEARCH_FILTERS: {
      return state.set('searchFilters', initialState.get('searchFilters'));
    }
    case CLEAR_LOCATIONS: {
      return state
        .set('locations', initialState.get('locations'))
        .set('searchMode', SEARCH_MODES.SEARCH);
    }

    default:
      return state;
  }
}
