import { COUNTRY_NAMES, HARDWARE_TYPES, LIFECYCLE_STATUS, LOCATION_TYPES } from '../../types/location.types';

import { HARDWARE_FIELDS_ALL } from './editComponents/hardwareFields';
import { shouldShowHardwareField } from './editComponents/hardwareFields';

const isCountryThatRequiresZipAndState = location => location.country === COUNTRY_NAMES.UNITED_STATES || location.country === COUNTRY_NAMES.CANADA;

export const defaultLocationValidation = loc => ({
  name: true,
  description: true,
  addressOne: true,
  city: true,
  state: isCountryThatRequiresZipAndState(loc),
  zip: isCountryThatRequiresZipAndState(loc),
  podHardwareType: true,
  podDeviceSerial: false,
  podModemSerial: false,
  podHardwareIdentifier: false,
  ttLockHardwareIdentifier: false,
  pcdSim: false,
  pinCodes: false,
  country: false,
  lockSerial: true,
});

const lactationSpaceLocationValidation = loc => ({
  name: true,
  description: true,
  addressOne: true,
  city: true,
  state: isCountryThatRequiresZipAndState(loc),
  zip: isCountryThatRequiresZipAndState(loc),
  podHardwareType: false,
  country: false,
});

const prebuiltLocationValidation = {
  name: false,
  description: false,
  addressOne: false,
  city: false,
  state: false,
  zip: false,
  podHardwareType: true,
  ttLockConnectionString: false,
  ttLockHardwareIdentifier: false,
  ttLockName: false,
  country: false,
};

const prebuiltSmartPodLocationValidation = {
  ...prebuiltLocationValidation,
  podDeviceSerial: true,
  podModemSerial: true,
  podHardwareIdentifier: true,
  pcdSim: true,
  pinCodes: true,
};

const prebuiltTTLOCKLocationValidation = {
  ...prebuiltLocationValidation,
  pinCodes: true,
  lockSerial: false,
};

const prebuiltPCDPairedTTLOCKLocationValidation = {
  ...prebuiltLocationValidation,
  ...prebuiltSmartPodLocationValidation,
  ...prebuiltTTLOCKLocationValidation,
};

const prebuiltVaroomTTLOCKLocationValidation = {
  ...prebuiltLocationValidation,
  lockSerial: true,
};

const hasValueValidationFunction = (loc, fieldName) => {
  const value = loc[fieldName];
  if (!value || (value && !value.length)) {
    return false;
  }
  return true;
};

export const visibilityBasedValidationFunction = (loc, fieldName) => {
  const value = loc[fieldName];
  const hasValue = value?.length > 0;
  const isHardwareField = HARDWARE_FIELDS_ALL.indexOf(fieldName) != -1;
  const shouldShowField = shouldShowHardwareField(fieldName, loc);

  if (isHardwareField && !shouldShowField) {
    return true;
  }

  return hasValue;
};

export const locationValidator = location => {
  let validationStrategy = defaultLocationValidation(location);
  switch (location.lifecycleStatus) {
    case LIFECYCLE_STATUS.PREBUILT:
      switch (location.podHardwareType) {
        case HARDWARE_TYPES.PCD_PAIRED_WITH_TTLOCK:
          validationStrategy = prebuiltPCDPairedTTLOCKLocationValidation;
          break;
        case HARDWARE_TYPES.VAROOM_TTLOCK:
          validationStrategy = prebuiltVaroomTTLOCKLocationValidation;
          break;
        default:
          validationStrategy = prebuiltLocationValidation;
      }
      break;
    default:
      switch (location.type) {
        case LOCATION_TYPES.LOCATION:
          validationStrategy = lactationSpaceLocationValidation(location);
          break;
        default:
          validationStrategy = defaultLocationValidation(location);
      }
  }

  return {
    type: validationStrategy,
    isLocationValid: (location, validationFunction = hasValueValidationFunction) => {
      return !Object.keys(validationStrategy)
        .filter(key => validationStrategy[key] === true)
        .some(key => {
          const isValid = validationFunction(location, key);
          return !isValid;
        });
    },
    isFieldValid: fieldName => {
      if (validationStrategy[fieldName]) {
        const isValid = hasValueValidationFunction(location, fieldName);
        return isValid;
      }
      return true;
    },
  };
};
