import { TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { DATE_FORMAT } from '../../utilities/dateUtils';

const DateRangePickerComp = ({ classes, defaultRange, handleUpdateRange }) => {
  const [toDate, setToDate] = useState(moment().format(DATE_FORMAT.INPUT));
  const [fromDate, setFromDate] = useState(moment().subtract(defaultRange, 'days').format(DATE_FORMAT.INPUT));

  useEffect(() => {
    handleUpdateRange({fromDate, toDate});
  }, [fromDate, toDate]);

  return (
    <div className={classes.dateRange}>
      <TextField
        id="fromDate"
        type="date"
        className={classes.dateInput}
        defaultValue={fromDate}
        onChange={(e) => setFromDate(e.target.value)}
        inputProps={{ max: toDate }}
      />
      <span className={classes.dateSeperator}>-</span>
      <TextField
        id="toDate"
        type="date"
        className={classes.dateInput}
        defaultValue={toDate}
        onChange={(e) => setToDate(e.target.value)}
        inputProps={{ min: fromDate }}
      />
    </div>
  );
};

const styles = () => ({
  dateRange: {
    display: 'flex',
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontFamily: 'GothamRounded-Book',
  },
  dateInput: {
    width: 155,
  },
  dateSeperator: {
    alignSelf: 'center',
    margin: 10,
  },
});

DateRangePickerComp.propTypes = {
  classes: PropTypes.object.isRequired,
  defaultRange: PropTypes.number.isRequired,

  handleUpdateRange: PropTypes.func.isRequired,
};

const prepareForExport = compose(
  withStyles(styles),
);

const DateRangePicker = prepareForExport(DateRangePickerComp);

export default DateRangePicker;