import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ALERT_STATUS, CONNECTION_STATUS, FLEET_OVERVIEW_TABS } from '../monitoring.actions';
import { STATE_ABBREV, filterDataByState } from '../../../utilities/chartUtils';
import { fleetOverviewSelectors, selectSoftwareVersions } from '../monitoring.selectors';
import { getAlertColor, getConnectionColor, getFilterUrlParams } from '../../../utilities/monitoringUtils';
import Colors from '../../../styles/colors';
import MapCard from './mapCard.component';

const ALL_PUBLISHED = 'All Published';

const TITLES = {
  [FLEET_OVERVIEW_TABS.CONNECTION]: 'SmartPod Published Pods',
};

// Only alerts and connection are configured here
// because software buttons need to be dynamic
const BUTTONS = {
  [FLEET_OVERVIEW_TABS.ALERTS]: [
    ALERT_STATUS.NEW,
    ALERT_STATUS.IN_PROCESS,
    ALERT_STATUS.ADDRESSED,
  ],
  [FLEET_OVERVIEW_TABS.CONNECTION]: [
    ALL_PUBLISHED,
    ...Object.values(CONNECTION_STATUS), 
  ],
};

const ConnectionMapContainer = ({
  fleetTab,
  isLoading,
  fleetOverviewFilters,
  selectedButton,
  onSelectButton,
  history,
  // ALERTS
  alertsByStatus,
  alertPodsByStatus,
  // CONNECTION
  publishedLocations,
  locationsByConnection,
  // SOFTWARE
  softwareVersions,
  locationsBySoftwareVersion,
}) => {

  const [title, setTitle] = useState(TITLES[FLEET_OVERVIEW_TABS.ALERTS]);
  const [buttons, setButtons] = useState(BUTTONS[FLEET_OVERVIEW_TABS.ALERTS]);

  useEffect(() => {
    if (fleetTab) {

      setTitle(TITLES[fleetTab]);
      if (fleetTab === FLEET_OVERVIEW_TABS.SOFTWARE) {
        setButtons(softwareVersions);
        if (!selectedButton) {
          onSelectButton(softwareVersions[0]);
        }
      } else {
        setButtons(BUTTONS[fleetTab]);
        if (!selectedButton) {
          onSelectButton(BUTTONS[fleetTab][0]);
        }
      }
    }
  }, [fleetTab, softwareVersions]);

  // This creates a multiline chart label that shows when you hover over each point on the graph.
  // We only do a custom label for alerts at this point
  const getPointLabel = useCallback(
    // ALERTS
    fleetTab === FLEET_OVERVIEW_TABS.ALERTS ?
      (tooltipItem, tooltipData) => { 
        const pointLabel = [];
        
        if (tooltipData.datasets[0].data[tooltipItem.index]) {
          pointLabel[0] = `${tooltipData.datasets[0].data[tooltipItem.index].description}:`;
        }
        pointLabel.push(`${tooltipData.datasets[0].data[tooltipItem.index].value} Alerts`);
        const podCountValue = alertPodsByStatus[selectedButton] ? 
          filterDataByState(alertPodsByStatus[selectedButton], tooltipData.datasets[0].data[tooltipItem.index].description).length : 0;

        pointLabel.push(`${podCountValue} Pods with Alerts`);
        return pointLabel;
      } : null,
    [fleetTab, alertPodsByStatus, selectedButton]
  );

  const data = useMemo(() => {
    // ALERTS
    if (fleetTab === FLEET_OVERVIEW_TABS.ALERTS) {
      if (alertsByStatus[selectedButton]) {
        return alertsByStatus[selectedButton];
      }
      return [];
    }
    // CONNECTION
    if (fleetTab === FLEET_OVERVIEW_TABS.CONNECTION) {
      if (selectedButton === ALL_PUBLISHED) {
        return publishedLocations;
      } 
      if (locationsByConnection[selectedButton]) {
        return locationsByConnection[selectedButton];
      }
      return [];
    }
    // SOFTWARE
    if (fleetTab === FLEET_OVERVIEW_TABS.SOFTWARE) {
      if (locationsBySoftwareVersion[selectedButton]) {
        return locationsBySoftwareVersion[selectedButton];
      }
      return [];
    }
    return [];
  }, [fleetTab, selectedButton, publishedLocations, locationsByConnection, alertsByStatus, locationsBySoftwareVersion]);

  const handleClickState = (state) => {
    const abbrev = STATE_ABBREV[state];

    // CONNECTION
    if (fleetTab === FLEET_OVERVIEW_TABS.CONNECTION) {
      if (selectedButton === ALL_PUBLISHED) {
        history.push({
          pathname: '/monitoring/locations',
          search: `?isPublished=true&state=${abbrev}${getFilterUrlParams(fleetOverviewFilters)}`,
        });
      } else if (selectedButton === CONNECTION_STATUS.NEVER_CONNECTED) {
        history.push({
          pathname: '/monitoring/locations',
          search: `?isPublished=true&state=${abbrev}&hasFirstEvent=false${getFilterUrlParams(fleetOverviewFilters)}`,
        });
      } else if (selectedButton === CONNECTION_STATUS.OFFLINE) {
        history.push({
          pathname: '/monitoring/locations',
          search: `?isPublished=true&state=${abbrev}&hasFirstEvent=true&connectionStatus=OFFLINE${getFilterUrlParams(fleetOverviewFilters)}`,
        });
      } else if (selectedButton === CONNECTION_STATUS.ONLINE) {
        history.push({
          pathname: '/monitoring/locations',
          search: `?isPublished=true&state=${abbrev}&connectionStatus=online${getFilterUrlParams(fleetOverviewFilters)}`,
        });
      }
    }

    // SOFTWARE
    else if (fleetTab === FLEET_OVERVIEW_TABS.SOFTWARE) {
      history.push({
        pathname: '/monitoring/locations',
        search: `?state=${abbrev}&podSoftwareVersion=${selectedButton}${getFilterUrlParams(fleetOverviewFilters)}`,
      });
    }
  };

  const getColor = (selectedButton) => {
    // ALERTS
    if (fleetTab === FLEET_OVERVIEW_TABS.ALERTS) {
      return getAlertColor(selectedButton);
    }
    // CONNECTION    
    if (fleetTab === FLEET_OVERVIEW_TABS.CONNECTION) {
      return getConnectionColor(selectedButton);
    }
    // SOFTWARE (default)
    return Colors.charts.signalStrengthStatus.all;
  };

  return (
    <MapCard
      title={title}
      buttons={buttons}
      selectedItem={selectedButton}
      setSelectedItem={(button) => onSelectButton(button)}
      data={data}
      onClick={handleClickState}
      color={getColor(selectedButton)}
      isLoading={isLoading}
      getPointLabel={getPointLabel}
    />
  );
};

ConnectionMapContainer.propTypes = {
  fleetTab: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedButton: PropTypes.string.isRequired,
  onSelectButton: PropTypes.func.isRequired,
  fleetOverviewFilters: PropTypes.object.isRequired,
  // ALERTS
  alertsByStatus: PropTypes.object.isRequired,
  alertPodsByStatus: PropTypes.object.isRequired,
  // CONNECTION    
  publishedLocations: PropTypes.array.isRequired,
  locationsByConnection: PropTypes.object.isRequired,
  // SOFTWARE
  softwareVersions: PropTypes.array.isRequired,
  locationsBySoftwareVersion: PropTypes.object.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.monitoring.get('isLoading'),
    fleetOverviewFilters: state.monitoring.get('fleetOverviewFilters'),
    // ALERTS
    alertPodsByStatus: fleetOverviewSelectors.selectAlertPodsByStatus(state),
    // CONNECTION    
    locationsByConnection: fleetOverviewSelectors.selectLocationsByConnection(state),
    publishedLocations: fleetOverviewSelectors.selectPublishedLocations(state),
    // SOFTWARE
    softwareVersions: selectSoftwareVersions(state),
  };
};

const prepareForExport = compose(
  withRouter,
  connect(
    mapStateToProps,
  ),
);

const ConnectionMap = prepareForExport(ConnectionMapContainer);

export default ConnectionMap;