import * as ChartGeo from 'chartjs-chart-geo';
import { Chart } from 'react-chartjs-2';
import Colors from '../styles/colors';

const ANNOTATION = {
  TYPE: 'box',
  DRAWTIME: 'beforeDatasetsDraw',
};

export const CHART_TYPE = {
  LINE: 'line',
  HORIZONTAL_BAR: 'horizontalBar',
  PIE: 'pie',
  CHOROPLETH: 'choropleth', // Shaded map
};

const SCALE_IDS = {
  X: 'x-axis-0',
  Y: 'y-axis-0',
};

export const getBarChartConfig = ({data, xRange = [], aspectRatio = 5} = {}) => {
  const values = data.map((item) => item.value);
  const colors = data.map((item) => item.color);
  const labels = data.map((item) => item.label);

  const config = {
    type: CHART_TYPE.HORIZONTAL_BAR,
    data: {
      labels,
      datasets: data && data.length > 0 ? 
        [{
          backgroundColor: colors,
          data: values,
        }] : [],
    },
    options: {
      aspectRatio,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              min: xRange[0],
              max: xRange[xRange.length - 1],
            },
          },
        ],
      },
    },
  };

  return config;
};

export const getLineChartConfig = ({data, xRange = [], yRange = [], getPointLabel, aspectRatio = 5} = {}) => {
  const config = {
    type: CHART_TYPE.LINE,
    data: {
      datasets: data && data.length > 0 ? 
        [
          {
            backgroundColor: Colors.charts.lineChartBackground,
            lineTension: 0,
            data,
            fill: 'start', // Makes it fill below negative values as well
          },
        ] : [],
    },
    options: {
      aspectRatio,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'day',
            },
            ticks: {
              min: xRange[0],
              max: xRange[xRange.length - 1],
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              precision: 0,
              suggestedMin: yRange[0],
              suggestedMax: yRange[yRange.length - 1],
            },
          },
        ],
      },
      annotation: {
        drawTime: ANNOTATION.DRAWTIME,
        annotations: getAnnotations(xRange, yRange),
      },
    },
  };

  // Add point label callback for custom multiline tooltips
  if (getPointLabel) {
    config.options.tooltips = {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: getPointLabel, 
      },
    };
  }

  return config;
};

export const getPieChartConfig = ({data, aspectRatio = 5, getPointLabel} = {}) => {
  const values = data.map((item) => item.value);
  const colors = data.map((item) => item.color);
  const labels = data.map((item) => item.label);

  const config = {
    type: CHART_TYPE.PIE,
    data: {
      labels,
      datasets: data && data.length > 0 ? 
        [{
          backgroundColor: colors,
          data: values,
          pointStyle: 'circle',
        }] : [],
    },
    options: {
      aspectRatio,
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
        },
      },
    },
  };

  // Add point label callback for custom multiline tooltips
  if (getPointLabel) {
    config.options.tooltips = {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: getPointLabel, 
      },
    };
  }

  return config;
};

export const getMapChartConfig = async ({data, color = Colors.charts.signalStrengthStatus.all, getPointLabel}) => {
  const states = await getStates();

  const statesData = states.map((state) => {
    return {
      feature: state,
      value: filterDataByState(data, state.properties.name).length,
      description: state.properties.name,
    };
  });

  const config = {
    type: CHART_TYPE.CHOROPLETH,
    data: {
      labels: statesData.map((d) => d.description),
      datasets: [{
        outline: NATION_FEATURE,
        showOutline: true,
        data: statesData,
      }],
    },
    options: {
      legend: {
        display: false,
      },
      scale: {
        projection: 'albersUsa',
      },
      geo: {
        colorScale: {
          display: true,
          interpolate: (value) => calculateColorForValue(color, value),
          missing: 'white',
          position: 'bottom',
        },
      },
    },
  };

  // Add point label callback for custom multiline tooltips
  if (getPointLabel) {
    config.options.tooltips = {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: getPointLabel, 
      },
    };
  }

  return config;
};

/* MAP CHART HELPERS */

let STATE_FEATURES;
let NATION_FEATURE;
const getStates = async () => {
  // Only make query if states is not yet defined
  if (STATE_FEATURES) {
    return STATE_FEATURES;
  }
  const us = await fetch('https://unpkg.com/us-atlas/states-10m.json').then((r) => r.json());
  NATION_FEATURE = ChartGeo.topojson.feature(us, us.objects.nation).features[0];
  STATE_FEATURES = ChartGeo.topojson.feature(us, us.objects.states).features;
  return STATE_FEATURES;
};

export const STATE_ABBREV = {
  'Alabama': 'AL',
  'Alaska': 'AK',
  'Arizona': 'AZ',
  'Arkansas': 'AR',
  'California': 'CA',
  'Connecticut': 'CT',
  'Colorado': 'CO',
  'Delaware': 'DE',
  'District of Columbia': 'DC',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Pennsylvania': 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY',
};

// For a value between 0-1, generates a color rgba(x,x,x,0.x)
// with opacity according to the value
// Color param must be rgba(x, x, x) format
const calculateColorForValue = (color, value) => {
  if (!value) {
    return Colors.white;
  }
  return `${color.substr(0, color.length - 1)}, ${value})`;
};

export const filterDataByState = (data, stateName) => data ? (data.filter((item) => STATE_ABBREV[stateName] && 
  item.state && item.state.match(new RegExp(STATE_ABBREV[stateName], 'i')))) : [];

/* END MAP CHART HELPERS */

// Marks chart with ranges for high / low / medium values
const getAnnotations = (xRange, yRange) => {

  if (yRange.length < 4) {
    return [];
  }

  if (yRange.length === 4) {
    return [
      {
        type: ANNOTATION.TYPE,
        xScaleID: SCALE_IDS.X,
        yScaleID: SCALE_IDS.Y,
        xMin: xRange[0],
        xMax: xRange[1],
        yMin: yRange[0],
        yMax: yRange[1],
        borderWidth: 0,
        backgroundColor: Colors.charts.scale.low,
      },
      {
        type: ANNOTATION.TYPE,
        xScaleID: SCALE_IDS.X,
        yScaleID: SCALE_IDS.Y,
        xMin: xRange[0],
        xMax: xRange[1],
        yMin: yRange[1],
        yMax: yRange[2],
        borderWidth: 0,
        backgroundColor: Colors.charts.scale.med,
      },
      {
        type: ANNOTATION.TYPE,
        xScaleID: SCALE_IDS.X,
        yScaleID: SCALE_IDS.Y,
        xMin: xRange[0],
        xMax: xRange[1],
        yMin: yRange[2],
        yMax: yRange[3],
        borderWidth: 0,
        backgroundColor: Colors.charts.scale.high,
      },
    ];
  }
  // Signal strength range colors. Maybe come up with better config later?
  else if (yRange.length > 4) {
    return [
      {
        type: ANNOTATION.TYPE,
        xScaleID: SCALE_IDS.X,
        yScaleID: SCALE_IDS.Y,
        xMin: xRange[0],
        xMax: xRange[1],
        yMin: yRange[0],
        yMax: yRange[1],
        borderWidth: 0,
        backgroundColor: Colors.charts.signalStrength.excellent,
      },
      {
        type: ANNOTATION.TYPE,
        xScaleID: SCALE_IDS.X,
        yScaleID: SCALE_IDS.Y,
        xMin: xRange[0],
        xMax: xRange[1],
        yMin: yRange[1],
        yMax: yRange[2],
        borderWidth: 0,
        backgroundColor: Colors.charts.signalStrength.good,
      },
      {
        type: ANNOTATION.TYPE,
        xScaleID: SCALE_IDS.X,
        yScaleID: SCALE_IDS.Y,
        xMin: xRange[0],
        xMax: xRange[1],
        yMin: yRange[2],
        yMax: yRange[3],
        borderWidth: 0,
        backgroundColor: Colors.charts.signalStrength.fair,
      },
      {
        type: ANNOTATION.TYPE,
        xScaleID: SCALE_IDS.X,
        yScaleID: SCALE_IDS.Y,
        xMin: xRange[0],
        xMax: xRange[1],
        yMin: yRange[3],
        yMax: yRange[4],
        borderWidth: 0,
        backgroundColor: Colors.charts.signalStrength.poor,
      },
    ];
  }
};

// This is called in index.js to automatically display a "No data" message if the chart has no data
export const configChartNoDataMessage = () => {
  Chart.pluginService.register({
    afterDraw: (chart, easing) => {
      if (chart.data.datasets.length === 0) {
        // No data is present
        const ctx = chart.chart.ctx;
        const width = chart.chart.width;
        const height = chart.chart.height;
        chart.clear();
        
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = '16px normal "Helvetica Nueue"';
        ctx.fillText('No data to display', width / 2, height / 2);
        ctx.restore();
      }
    },
  });
};
