import { Button, Card, CardContent, Fade, FormControl, InputLabel, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { handleToastMessage, setDirtyForm } from '../layout/layout.actions';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Colors from '../../styles/colors';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { PROMOTION_TEXT_FIELDS } from '../promotions/promotions.actions';
import { getMedia, removeMedia, uploadMediaAsFile } from '../../utilities/mediaStorage';

class PromotionForm extends Component {
  static getPromotion(promotion) {
    if (!promotion) {
      promotion = {};
    }

    const out = {};
    PROMOTION_TEXT_FIELDS.forEach((field) => {
      out[field.name] = promotion[field.name] || '';
    });
    return out;
  }

  constructor(props) {
    super(props);

    ['resolveImageRef', 'isFormValid', 'handleSubmit', 'addImage', 'removeImage'].map((key) => (this[key] = this[key].bind(this)));

    let state = PromotionForm.getPromotion();

    if (!isEmpty(props.promotion)) {
      // let category = find(props.promotionCategories, { id: props.promotion.categoryId });
      state = PromotionForm.getPromotion(props.promotion);
      this.resolveImageRef(state);
    }
    state.resolvedImageRef = null;
    state.imageRefsToRemove = [];

    this.state = state;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.promotion !== this.props.promotion) {
      this.setState(PromotionForm.getPromotion(nextProps.promotion), () => this.resolveImageRef());
    }
  }

  async resolveImageRef(state) {
    const imageRef = (state || this.state || {}).imageRef;
    const resolvedImageRef = (state || this.state || {}).resolvedImageRef;

    // Resolve imageRef if present
    if (imageRef && !resolvedImageRef) {
      const resolvedImageRef = await getMedia(imageRef);
      this.setState({ resolvedImageRef });
    }
  }

  isFormValid() {
    return PROMOTION_TEXT_FIELDS.every((field) => field.optional || !isEmpty(this.state[field.name]));
  }

  async handleSubmit(e) {
    e.preventDefault();
    let promotionData = PromotionForm.getPromotion(this.state);

    if (this.state.imageRefsToRemove.length) {
      // An image was populated, then removed, so we need to delete all of them
      for (let i = 0, len = this.state.imageRefsToRemove.length; i < len; i++) {
        // eslint-disable-next-line no-await-in-loop
        await removeMedia(this.state.imageRefsToRemove[i]);
      }
    }
    this.props.submitAction(promotionData);
  }

  async addImage(e) {
    this.setState({ imageLoading: true });
    const file = e.target.files[0];
    const path = `promotions/images/${Date.now()}/${file.name}`;
    try {
      let { imageRef, resolvedImageRef } = await uploadMediaAsFile(file, path);
      this.setState({
        imageLoading: false,
        imageRef,
        resolvedImageRef,
      });
    } catch (e) {
      // Present toast!
      this.props.handleToastMessage('An error occurred while uploading the file; please try again.', true);
    }
  }

  async removeImage() {
    try {
      const imageRefsToRemove = [...this.state.imageRefsToRemove, this.state.imageRef];
      this.setState({ resolvedImageRef: null, imageRef: '', imageRefsToRemove });
    } catch (e) {
      this.props.handleToastMessage(`An error occurred while removing the file; please try again.\n${e}`, true);
    }
  }

  render() {
    let { classes } = this.props;
    return (
      <Fade id="formFade" in={true}>
        <div id="formColumnDiv" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div id="formRowDiv" style={{ display: 'flex', flexDirection: 'row', flex: 1, margin: 10 }}>
            <Card style={{ flex: 1 }}>
              <CardContent>
                {PROMOTION_TEXT_FIELDS.map((field) => {
                  return (
                    <Fragment key={field.name}>
                      <div style={{ paddingTop: 0, paddingBottom: 0, display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                          <TextField
                            id={field.name}
                            label={field.label}
                            className={classes.textField}
                            style={{ marginTop: 5, marginBottom: 5, marginLeft: 0, marginRight: 0, fontSize: 12 }}
                            fullWidth
                            value={this.state[field.name]}
                            onChange={(e) => {
                              const state = {};
                              state[field.name] = e.target.value;
                              return this.setState(state);
                            }}
                          />
                        </div>
                      </div>
                    </Fragment>
                  );
                })}

                <Fragment>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink htmlFor="imageDiv">
                      Image
                    </InputLabel>
                  </FormControl>

                  <div id="imageDiv" style={{ flexDirection: 'column' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                      <div style={{ flex: 0 }}>
                        {this.state.imageRef &&
                          this.state.resolvedImageRef && (
                          <div style={{ flex: 0, display: 'flex' }}>
                            <div style={{ flex: 1, flexDirection: 'row' }}>
                              <img src={this.state.resolvedImageRef} style={{ height: 100 }} alt="promotion" />
                            </div>
                          </div>
                        )}
                      </div>
                      <div style={{ flex: 0, margin: 0 }}>
                        {this.state.imageRef &&
                          this.state.resolvedImageRef && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: '1rem' }}
                            onClick={() => {
                              this.removeImage();
                            }}
                          >
                              Remove Image
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={{ marginRight: '1rem' }}
                          onClick={() => {
                            this.upload.click();
                          }}
                        >
                          Set Image
                        </Button>

                        <input
                          type="file"
                          id="addImage"
                          style={{ display: 'none' }}
                          className="show-for-sr"
                          ref={(ref) => (this.upload = ref)}
                          onChange={this.addImage}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.imageLoading && <div className="loader" />}
                </Fragment>

                <div style={{ marginTop: '4rem' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: '1rem' }}
                    type="submit"
                    onClick={this.handleSubmit}
                    disabled={!this.isFormValid()}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </Fade>
    );
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: Colors.primary.background,
  },
  detailsCard: {
    margin: 10,
    flex: 1,
    display: 'flex',
    overflow: 'scroll',
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 75,
  },
});

PromotionForm.propTypes = {
  classes: PropTypes.object.isRequired,

  history: PropTypes.object.isRequired,
  promotion: PropTypes.object,
  submitAction: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  hasDirtyForm: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  return {
    hasDirtyForm: state.layout.get('hasDirtyForm'),
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        handleToastMessage,
        setDirtyForm,
      }
    )(PromotionForm)
  )
);
