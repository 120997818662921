import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import MainLayout from './modules/layout/main.layout';
import React from 'react';

import useAuth0Store from './hooks/useAuth0Store.hook';

const Root = () => {
  const store = useAuth0Store();

  if (!store) {
    return null;
  }

  return (
    <Provider store={store}>
      <Route path="/" component={MainLayout} />
    </Provider>
  );
};

export default Root;
