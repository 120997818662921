import Immutable from 'immutable';

import {
  GET_LOCATIONS_FAILURE,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATION_BOOTUPS_FAILURE,
  GET_LOCATION_BOOTUPS_REQUEST,
  GET_LOCATION_BOOTUPS_SUCCESS,
  GET_LOCATION_OCCUPANCIES_FAILURE,
  GET_LOCATION_OCCUPANCIES_REQUEST,
  GET_LOCATION_OCCUPANCIES_SUCCESS,
  GET_LOCATION_STATUS_FAILURE,
  GET_LOCATION_STATUS_REQUEST,
  GET_LOCATION_STATUS_SUCCESS,
  GET_LOCATION_UNLOCKS_FAILURE,
  GET_LOCATION_UNLOCKS_REQUEST,
  GET_LOCATION_UNLOCKS_SUCCESS,
  SET_FLEET_OVERVIEW_COMPANY_FILTER,
  SET_FLEET_OVERVIEW_LOCATION_FILTER,
} from './monitoring.actions';

const initialState = Immutable.Map({
  isLoading: false,
  isLoadingConnectionStat: false,
  isLoadingOccupancies: false,
  isLoadingUnlocks: false,
  isLoadingBootups: false,
  errors: [],
  
  selectedLocationConnectionStat: {},
  selectedLocationStatus: {},
  selectedLocationOccupancies: [],
  selectedLocationUnlocks: [],
  selectedLocationBootups: [],
  locationsWithAlerts: [],
  locations: [],

  fleetOverviewFilters: {
    locationFilter: '',
    companyFilter: '',
  },
});

export default function(state = initialState, action) {
  switch (action.type) {

    case GET_LOCATIONS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_LOCATIONS_SUCCESS:
      return state.set('locations', action.response).set('isLoading', false);

    case GET_LOCATIONS_REQUEST:
      return state.set('isLoading', true);

    case GET_LOCATION_STATUS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_LOCATION_STATUS_SUCCESS:
      return state
        .set('selectedLocationStatus', action.response)
        .set('isLoading', false);
  
    case GET_LOCATION_STATUS_REQUEST:
      return state.set('isLoading', true);

    case GET_LOCATION_OCCUPANCIES_FAILURE:
      return state.set('errors', action.messages).set('isLoadingOccupancies', false);

    case GET_LOCATION_OCCUPANCIES_SUCCESS:
      return state
        .set('selectedLocationOccupancies', action.response)
        .set('isLoadingOccupancies', false);
  
    case GET_LOCATION_OCCUPANCIES_REQUEST:
      return state.set('isLoadingOccupancies', true);

    case GET_LOCATION_UNLOCKS_FAILURE:
      return state.set('errors', action.messages).set('isLoadingUnlocks', false);

    case GET_LOCATION_UNLOCKS_SUCCESS:
      return state
        .set('selectedLocationUnlocks', action.response)
        .set('isLoadingUnlocks', false);

    case GET_LOCATION_UNLOCKS_REQUEST:
      return state.set('isLoadingUnlocks', true);

    case GET_LOCATION_BOOTUPS_FAILURE:
      return state.set('errors', action.messages).set('isLoadingBootups', false);

    case GET_LOCATION_BOOTUPS_SUCCESS:
      return state
        .set('selectedLocationBootups', action.response)
        .set('isLoadingBootups', false);
  
    case GET_LOCATION_BOOTUPS_REQUEST:
      return state.set('isLoadingBootups', true);

    case SET_FLEET_OVERVIEW_LOCATION_FILTER: {
      return state.set('fleetOverviewFilters', {
        ...state.get('fleetOverviewFilters'),
        locationFilter: action.response,
      });
    }

    case SET_FLEET_OVERVIEW_COMPANY_FILTER: {
      return state.set('fleetOverviewFilters', {
        ...state.get('fleetOverviewFilters'),
        companyFilter: action.response,
      });
    }

    default:
      return state;
  }
}