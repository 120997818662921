import { Button, Fab, Input, NativeSelect, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { first, get, isEmpty, slice } from 'lodash';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { UPDATE_PROMOTION_SLOT_SUCCESS, getPromotionSlots, updatePromotionSlot } from './promotionSlots.actions';
import { getPromotions } from '../promotions/promotions.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';
import Colors from '../../styles/colors';

class PromotionSlotsContainer extends Component {
  constructor(props) {
    super(props);

    [].map((key) => (this[key] = this[key].bind(this)));
  }

  state = {
    order: 'asc',
    orderBy: 'calories',
    currentPromotionSlots: [],
    filteredPromotionSlots: [],
    page: 0,
    rowsPerPage: 10,
    filters: {},
  };

  async UNSAFE_componentWillMount() {
    this.props.setPageTitle('PromotionSlots');
    await this.props.getPromotionSlots();
    await this.props.getPromotions();

    let rowsPerPage = localStorage.getItem('rowsPerPage');
    if (parseInt(rowsPerPage, 10) >= 0) {
      this.setState({ rowsPerPage: parseInt(rowsPerPage, 10) });
    }
    this.buildCurrentPromotionSlots();
  }

  buildCurrentPromotionSlots() {
    let { page, rowsPerPage } = this.state;
    let promotionSlots = this.filterArray(this.props.promotionSlots, this.state.filters);
    this.setState({
      currentPromotionSlots: slice(promotionSlots, page * rowsPerPage, (page + 1) * rowsPerPage),
      filteredPromotionSlots: promotionSlots,
    });
  }

  initializeList(locations) {
    return locations.map((location) => {
      location.typeDisplayable = this.convertType(location.type);
      location.promotionSlotName = location.promotionSlot ? location.promotionSlot.name : '';
      return location;
    });
  }

  filterArray(array, filters) {
    let currentArray = array;
    if (array) {
      currentArray = this.initializeList(currentArray);
      currentArray = array
        .map((record) => {
          let returnRecord = record;
          if (filters && Object.keys(filters).length > 0) {
            Object.keys(filters).map((filter) => {
              if (returnRecord) {
                if (
                  !isEmpty(filters[filter]) &&
                  (!record || !record[filter] || !record[filter].toLowerCase().includes(filters[filter].toLowerCase()))
                ) {
                  returnRecord = undefined;
                }
              }
              return returnRecord;
            });
          }
          return returnRecord;
        })
        .filter((record) => (record ? true : false));
    }
    return currentArray;
  }

  convertType(type) {
    if (type === 'MAMAVA') {
      return 'Lactation Suite';
    } else if (type === 'LOCATION') {
      return 'Designated Location';
    } else {
      return 'Unknown';
    }
  }

  handleUpdateFilters(filter, value) {
    let filters = this.state.filters;
    filters[filter] = value;
    this.setState({ state: { filters } }, this.buildCurrentPromotionSlots);
  }

  handleLocationFilterChange = (event) => {
    this.handleUpdateFilters('name', event.target.value);
  };

  handlePromotionSlotFilterChange = (event) => {
    this.handleUpdateFilters('promotionSlotName', event.target.value);
  };

  handleStatusFilterChange = (event) => {
    this.handleUpdateFilters('status', event.target.value);
  };

  handleStateFilterChange = (event) => {
    this.handleUpdateFilters('state', event.target.value);
  };

  handleCityFilterChange = (event) => {
    this.handleUpdateFilters('city', event.target.value);
  };

  handleTypeFilterChange = (event) => {
    this.handleUpdateFilters('typeDisplayable', event.target.value);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, this.buildCurrentPromotionSlots);
  };

  handleChangeRowsPerPage = (event) => {
    localStorage.setItem('rowsPerPage', event.target.value);
    this.setState({ rowsPerPage: event.target.value }, this.buildCurrentPromotionSlots);
  };

  async updatePromotionSlot(promotionSlot, e) {
    const data = {
      name: promotionSlot.name,
      activePromotionId: e.target.value || null,
    };
    const response = await this.props.updatePromotionSlot(promotionSlot.id, data);

    if (response.type === UPDATE_PROMOTION_SLOT_SUCCESS) {
      this.props.handleToastMessage('App Slot updated.');
      await this.props.getPromotionSlots();
      this.buildCurrentPromotionSlots();
    } else {
      const message = first(get(response, 'messages', []));
      this.props.handleToastMessage(`Failed to update App Slot: ${message}`, true);
    }
  }

  render() {
    let { classes } = this.props;

    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '75vh', paddingTop: 10, overflowY: 'auto' }}>
        {this.props.isLoading && <div className="loader" />}
        {!this.props.isLoading && (
          <div className={classes.tableWrapper} id="tableWrapper">
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.head}>
                    <div>App Page</div>
                    <div>
                      <Input
                        margin="none"
                        id="filterPromotionSlotName"
                        label="Message"
                        // color="secondary"
                        variant="caption"
                        placeholder="filter"
                        inputProps={{ maxLength: 120 }}
                        className={classes.textField}
                        style={{ marginTop: 5, marginBottom: 5, marginLeft: 0, marginRight: 0, fontSize: 12 }}
                        classes={{
                          // textFieldInput: classes.textField,
                          underline: classes.underline,
                          root: classes.root,
                        }}
                        value={this.state.filters.name}
                        onChange={this.handleLocationFilterChange}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div>ID</div>
                  </TableCell>
                  <TableCell className={classes.head}>
                    <div>Promotion</div>
                    <div>
                      <Input
                        margin="none"
                        id="filterPromotionSlotActivePromotion"
                        label="Message"
                        // color="secondary"
                        variant="caption"
                        placeholder="filter"
                        inputProps={{ maxLength: 120 }}
                        className={classes.textField}
                        style={{ marginTop: 5, marginBottom: 5, marginLeft: 0, marginRight: 0, fontSize: 12 }}
                        classes={{
                          // textFieldInput: classes.textField,
                          underline: classes.underline,
                          root: classes.root,
                        }}
                        // classes={{ focus: classes.textFieldFocus}}
                        value={this.state.filters.activePromotion}
                        onChange={this.handlePromotionSlotFilterChange}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.currentPromotionSlots.map((promotionSlot, i) => {
                  return (
                    <TableRow key={promotionSlot.id}>
                      <TableCell className={classes.cell} component="td" scope="row">
                        {promotionSlot.name}
                      </TableCell>
                      <TableCell className={classes.cell} component="td" scope="row">
                        {promotionSlot.id}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <NativeSelect
                          value={(promotionSlot.activePromotion && promotionSlot.activePromotion.id) || ''}
                          onChange={this.updatePromotionSlot.bind(this, promotionSlot)}
                        >
                          <option value="">-- None --</option>
                          {this.props.promotions &&
                            this.props.promotions.length &&
                            this.props.promotions.map((promotion, i) => {
                              return (
                                <option key={i} value={promotion.id}>
                                  {promotion.name}
                                </option>
                              );
                            })}
                        </NativeSelect>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}

        {!this.props.isLoading && (
          <div style={{ flex: 1, paddingRight: 100 }}>
            <TablePagination
              component="div"
              count={this.state.filteredPromotionSlots.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              style={{ flex: 0 }}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </div>
        )}
        {!this.props.isLoading && (
          <div style={{ flex: 0 }}>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.button}
              onClick={() => this.props.history.push('/promotionSlots/create')}
            >
              <AddIcon />
            </Fab>
          </div>
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    flex: 1,
    marginTop: theme.spacing(3),
    margin: 20,
  },
  table: {
    minWidth: 1024,
    padding: 20,
  },
  tableWrapper: {
    padding: '1rem',
    overflowX: 'auto',
  },
  button: {
    margin: theme.spacing(1),
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  head: {
    position: 'sticky',
    backgroundColor: Colors.white,
    top: 0,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: 'none',
    fontWeight: 'normal',
    border: 0,
    fontSize: 16,
  },
  cell: {
    color: Colors.primary.main,
    // backgroundColor: Colors.primary.background,
  },
  input: {
    height: 20,
  },
  link: {
    color: Colors.primary.main,
    textDecoration: 'none',
    // fontWeight: '800',
  },
  underline: {
    // color: Colors.secondary.main,
    // borderBottomColor: Colors.secondary.main,
    '&:after': {
      // The source seems to use this but it doesn't work
      // borderBottomColor: Colors.secondary.main,
    },
  },
  textFieldFocus: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: 'none',
    fontWeight: 'normal',
    border: 0,
  },
});

PromotionSlotsContainer.propTypes = {
  promotions: PropTypes.array.isRequired,
  promotionSlots: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,

  setPageTitle: PropTypes.func.isRequired,
  getPromotions: PropTypes.func.isRequired,
  getPromotionSlots: PropTypes.func.isRequired,
  updatePromotionSlot: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    promotions: state.promotions.get('promotions'),
    promotionSlots: state.promotionSlots.get('promotionSlots'),
    isLoading: state.promotionSlots.get('isLoading'),
    errors: state.promotionSlots.get('errors'),
  };
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(
    mapStateToProps,
    {
      setPageTitle,
      getPromotions,
      getPromotionSlots,
      updatePromotionSlot,
      handleToastMessage,
    }
  ),
);

export default prepareForExport(PromotionSlotsContainer);
