import { Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/CheckRounded';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { RESPONSE_FAILURE, RESPONSE_PENDING, RESPONSE_SUCCESS } from '../iotCommand.actions';
import Colors from '../../../styles/colors';

const DEFAULT_MESSAGE = {
  [RESPONSE_SUCCESS]: 'Command successfully received.',
  [RESPONSE_PENDING]: 'Command still pending.',
  [RESPONSE_FAILURE]: 'Error sending command.',
};

const ResponseRow = ({ classes, response }) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow 
        key={response.id} 
        onClick={() => setOpen(!open)}
        hover
      >
        <TableCell padding="none">
          <IconButton size="large">
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        {response.location && (
          <Fragment>
            <TableCell size="small">
              {response.location.name}
            </TableCell>
            <TableCell size="small">
              {response.location.hubspotCompanyName}
            </TableCell>
          </Fragment>
        )}
        {response.commandSet && (
          <Fragment>
            <TableCell size="small">
              {response.commandSet.commandName}
            </TableCell>
            <TableCell size="small">
              {response.commandSet.user ? response.commandSet.user.email : ''}
            </TableCell>
            <TableCell size="small">
              {response.commandSet.sentDate ? moment(response.commandSet.sentDate).format('MMMM D, YYYY h:mma') : ''}
            </TableCell>
          </Fragment>
        )}
        <TableCell className={classes.receivedDate} size="small">
          {response.deviceReceivedDate ? moment(response.deviceReceivedDate).format('MMMM D, YYYY h:mma') : ''}
        </TableCell>
        <TableCell size="small">
          <div className={classes.status}>
            {response.status === RESPONSE_PENDING && (
              <div className={classNames(classes.statusIndicator, classes.pending)}>
                <ArrowForwardIcon />
              </div>
            )}
            {response.status === RESPONSE_SUCCESS && (
              <div className={classNames(classes.statusIndicator, classes.success)}>
                <CheckIcon />
              </div>
            )}
            {response.status === RESPONSE_FAILURE && (
              <div className={classNames(classes.statusIndicator, classes.fail)}>
                <ClearIcon />
              </div>
            )}
            <Typography className={classes.statusLabel}>
              {response.status}
            </Typography>
          </div>
        </TableCell>
      </TableRow>
      <TableRow key={`${response.id}_message`} className={classes.expandRow}>
        <TableCell 
          colSpan={3 + (response.commandSet ? 3 : 0) + (response.location ? 2 : 0)}
          size="small"
        >
          <Collapse in={open}>
            <Typography variant="body1" className={classes.message} >
              {response.iotMessage ? response.iotMessage : DEFAULT_MESSAGE[response.status]}
            </Typography>
            {response.deviceMessage && (
              <div className={classes.deviceMessage}>
                <pre>{JSON.stringify(response.deviceMessage, null, 2)}</pre>
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>

    </Fragment>
  );
};

const styles = (theme) => ({
  expandRow: {
    height: 'auto',
    '& td': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  receivedDate: {
    whiteSpace: 'nowrap',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  statusLabel: {
    paddingLeft: 6,
  },
  statusIndicator: {
    height: 25,
    width: 25,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  pending: {
    backgroundColor: Colors.info.main,
  },
  success: {
    backgroundColor: Colors.success.main,
  },
  fail: {
    backgroundColor: Colors.error.main,
  },
  message: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  deviceMessage: {
    overflow: 'scroll',
    maxWidth: 950,
  },
});


ResponseRow.propTypes = {
  response: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};


const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
);

export default prepareForExport(ResponseRow);