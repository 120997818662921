import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { DELETE_USER_SUCCESS, deleteUser } from './users.actions';
import { PERMISSIONS } from '../auth/constants';
import { handleToastMessage } from '../layout/layout.actions';
import { useCheckPermission } from '../../hooks/useCheckPermission';

export default function DeleteUserButton({ user, onDeleted, className }) {
  const { isAuthorized } = useCheckPermission();
  const dispatch = useDispatch();

  const onClick = async () => {
    const confirmedEmail = prompt('Please enter the email of the user to confirm deletion');
    if (confirmedEmail === null) {
      return;
    }

    if (user.email === confirmedEmail) {
      const response = await dispatch(deleteUser(user.id));
      if (response.type === DELETE_USER_SUCCESS) {
        dispatch(handleToastMessage(`User deleted (${user.email})`));
        onDeleted();
      } else {
        dispatch(handleToastMessage('Delete user failed'));
      }
    } else {
      dispatch(handleToastMessage('Incorrect email, user was not deleted'));
    }
  };
  
  if (!isAuthorized(PERMISSIONS.USERS_DELETE)) {
    return null;
  }

  return (
    <Button
      variant="contained"
      color={'error'}
      className={className}
      onClick={onClick}
    >
      Delete User
    </Button>
  );
}

DeleteUserButton.propTypes = {
  className: PropTypes.string,
  onDeleted: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
};
