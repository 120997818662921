import * as Sentry from '@sentry/browser';
import * as firebase from 'firebase/app';
import { Auth0Provider } from '@auth0/auth0-react';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { configChartNoDataMessage } from './utilities/chartUtils';
import { render } from 'react-dom';
import React from 'react';

import './index.css';
import { unregister } from './registerServiceWorker';
import Root from './root.container';
import theme from './theme';


const APP_VERSION = process.env.REACT_APP_VERSION || 'no version set';
console.log(`Mamava Version ${APP_VERSION}`);


const basename = process.env.PUBLIC_URL || '/';
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const auth0Scope = process.env.REACT_APP_AUTH0_SCOPE;

Sentry.init({ 
  dsn: process.env.REACT_APP_SENTRY_KEY, 
  environment: process.env.REACT_APP_FIREBASE_ENV,
  release: APP_VERSION,
});


//Default font family for all chartjs charts ( has to be a system font )
Chart.defaults.global.defaultFontFamily = 'Avenir Book';

let appStarted = false;

// Initialize Firebase
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};
firebase.initializeApp(config);

if (!appStarted && auth0Domain) {
  startApp();
  appStarted = true;
}


configChartNoDataMessage();

function startApp() {
  render(
    <Router basename={basename}>
      {/* TODO Replace dev keys */}
      {/* https://auth0.com/docs/authenticate/identity-providers/social-identity-providers/devkeys */}
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        authorizationParams={{
          audience: auth0Audience,
          redirect_uri: window.location.origin,
          scope: auth0Scope
        }}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Root />
          </ThemeProvider>
        </StyledEngineProvider>
      </Auth0Provider>
    </Router>,
    document.getElementById('root')
  );
}

unregister();