import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { CREATE_PROMOTION_SLOT_SUCCESS, createPromotionSlot } from '../promotionSlots/promotionSlots.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';
import Form from './form.component';

class PromotionSlotCreateContainer extends Component {
  constructor(props) {
    super(props);

    this.handleCreate = this.handleCreate.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.setPageTitle('New PromotionSlot');
  }

  async handleCreate(promotionSlotData) {
    let response = await this.props.createPromotionSlot(promotionSlotData);

    if (response.type === CREATE_PROMOTION_SLOT_SUCCESS) {
      this.props.handleToastMessage('PromotionSlot Created.');
      this.props.history.goBack();
    } else {
      this.props.handleToastMessage('Failed to create promotionSlot.', true);
    }
  }

  render() {
    return (
      <div className="row page-padding-bottom">
        <div className="small-12 columns">
          <Form submitAction={this.handleCreate} />
        </div>
      </div>
    );
  }
}

PromotionSlotCreateContainer.propTypes = {
  createPromotionSlot: PropTypes.func.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {};
};

const prepareForExport = compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      createPromotionSlot,
      handleToastMessage,
      setPageTitle,
    }
  ),
);

export default prepareForExport(PromotionSlotCreateContainer);