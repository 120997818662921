import { isNil } from 'lodash';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IcomoonReact from 'icomoon-react';
import React from 'react';
import moment from 'moment';

import {
  ALERT_STATUS,
  ALERT_TYPE,
  CONNECTION_STATUS,
  SIGNAL_QUALITY,
  SIGNAL_RANGE_PCDv1,
  SIGNAL_RANGE_PCDv2,
} from '../modules/monitoring/monitoring.actions';
import Colors from '../styles/colors';
import iconSet from '../images/monitoring/monitoring.json';
import { PCD_HARDWARE_TYPES } from '../types/location.types';

export const isOnline = location => {
  if (!location) {
    return false;
  }
  if (!location.lastEvent) {
    return false;
  }
  const hoursSince = moment().diff(location.lastEvent, 'hours');
  return hoursSince < 1;
};

export const getLastEventDate = location => {
  if (!location) {
    return null;
  }
  return location.lastEvent;
};

export const getLastEventText = location => {
  if (!location) {
    return 'Never Connected';
  }
  if (location.lastEvent) {
    return 'Last Event';
  }
  return 'Never Connected';
};

// PCDv1: Excellent, > -65, Good, -65 to -75, Fair -75 to -85, Poor < -85
// PCDv2: Excellent, > -85, Good, -85 to -95, Fair -95 to -105, Poor < -105

export const getSignalQuality = (signalStrength, location) => {
  if (isNil(signalStrength)) {
    return null;
  }

  let minExcellent, minGood, minFair;

  switch (location?.pcdHardwareType) {
    case PCD_HARDWARE_TYPES.PYCOM: 
      minExcellent = SIGNAL_RANGE_PCDv1.MIN_EXCELLENT;
      minGood = SIGNAL_RANGE_PCDv1.MIN_GOOD;
      minFair = SIGNAL_RANGE_PCDv1.MIN_FAIR;
      break;
    case PCD_HARDWARE_TYPES.PARTICLE: 
      minExcellent = SIGNAL_RANGE_PCDv2.MIN_EXCELLENT;
      minGood = SIGNAL_RANGE_PCDv2.MIN_GOOD;
      minFair = SIGNAL_RANGE_PCDv2.MIN_FAIR;
      break;
    default:
      return null;
  }

  if (signalStrength >= minExcellent) {
    return SIGNAL_QUALITY.EXCELLENT;
  } else if (signalStrength >= minGood) {
    return SIGNAL_QUALITY.GOOD;
  } else if (signalStrength >= minFair) {
    return SIGNAL_QUALITY.FAIR;
  } else {
    return SIGNAL_QUALITY.POOR;
  }
};

// Filter to 8 - 48 minutes for “Mom Occupancies”
export const isMomOccupancy = occupancy => {
  if (isNil(occupancy.occ_time)) {
    return null;
  }
  const occ_minutes = moment.duration(occupancy.occ_time, 'seconds').asMinutes();
  return occ_minutes >= 5 && occ_minutes <= 90;
};

export const getAlertIcon = (type, color, className) => {
  let icon = '';
  if (type.startsWith(ALERT_TYPE.LIGHT)) {
    icon = 'lights';
  } else if (type.startsWith(ALERT_TYPE.FAN)) {
    icon = 'fan';
  } else if (type.startsWith(ALERT_TYPE.ENV)) {
    icon = 'environmental';
  } else if (type.startsWith(ALERT_TYPE.LOCK)) {
    icon = 'lock';
  }

  // Unknown alert type
  if (icon === '') {
    return <ErrorOutlineIcon style={{ color }} />;
  }

  return (
    <div>
      <IcomoonReact iconSet={iconSet} icon={icon} color={color} className={className} />
    </div>
  );
};

export const getAlertTitle = type => {
  if (type.startsWith(ALERT_TYPE.LIGHT)) {
    return 'Lights';
  } else if (type.startsWith(ALERT_TYPE.FAN)) {
    return 'Fans';
  } else if (type.startsWith(ALERT_TYPE.ENV)) {
    return 'Environmental Sensor';
  } else if (type.startsWith(ALERT_TYPE.LOCK)) {
    return 'Lock';
  }
};

export const countLocationsByStatus = (locationsWithAlerts, status) => {
  return locationsWithAlerts.reduce(
    (locationCount, location) =>
      location.alerts.findIndex(alert => status.includes(alert.status)) !== -1 ? locationCount + 1 : locationCount,
    0
  );
};

// Counts the locations that have alerts that are NOT resolved
export const countLocationsWithUnresolvedAlerts = locationsWithAlerts => {
  return locationsWithAlerts.reduce(
    (locationCount, location) =>
      location.alerts.findIndex(alert => !ALERT_STATUS.RESOLVED.includes(alert.status)) !== -1 ? locationCount + 1 : locationCount,
    0
  );
};

export const getAlertColor = status => {
  let color = Colors.charts.signalStrengthStatus.excellent;
  if (status === ALERT_STATUS.NEW) {
    color = Colors.charts.signalStrengthStatus.poor;
  } else if (status === ALERT_STATUS.IN_PROCESS) {
    color = Colors.charts.signalStrengthStatus.fair;
  } else if (status === ALERT_STATUS.ADDRESSED) {
    color = Colors.charts.signalStrengthStatus.all;
  } else if (status === ALERT_STATUS.EXPIRED) {
    color = Colors.secondary.main;
  }
  return color;
};

export const getConnectionColor = status => {
  let color = Colors.charts.signalStrengthStatus.all;
  if (status === CONNECTION_STATUS.NEVER_CONNECTED) {
    color = Colors.charts.signalStrengthStatus.poor;
  } else if (status === CONNECTION_STATUS.OFFLINE) {
    color = Colors.charts.signalStrengthStatus.fair;
  } else if (status === CONNECTION_STATUS.ONLINE) {
    color = Colors.charts.signalStrengthStatus.excellent;
  }
  return color;
};

// Returns query params for the location name and company name. Used for filters on /monitoring/locations page.
// Ex: /monitoring/locations?isPublished=true&name=kapok&hubspotCompanyName=walmart
export const getFilterUrlParams = fleetOverviewFilters => {
  let filtersParam = fleetOverviewFilters.locationFilter ? `&name=${fleetOverviewFilters.locationFilter}` : '';
  filtersParam += fleetOverviewFilters.companyFilter ? `&hubspotCompanyName=${fleetOverviewFilters.companyFilter}` : '';
  return filtersParam;
};
