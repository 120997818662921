import { useCheckPermission } from '../../hooks/useCheckPermission';
import PropTypes from 'prop-types';

export const PermissionCheck = ({children, require, fallback = null}) => {
  const {isAuthorized} = useCheckPermission();

  return (
    require && isAuthorized(require) ? children : fallback
  );
};

PermissionCheck.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  require: PropTypes.string.isRequired,
};
