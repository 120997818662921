import { TableCell, TableRow } from '@mui/material';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import moment from 'moment';

import { DATE_FORMAT } from '../../utilities/dateUtils';
import DataTable from '../layout/dataTable/dataTable.component';

const TABLE_PADDING = 'dense';

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'createdDate', numeric: false, padding: TABLE_PADDING, label: 'Date Submitted', sortable: true, filtering: true },
  { id: 'name', numeric: false, padding: TABLE_PADDING, label: 'Location Name', sortable: true, filtering: true },
  { id: 'city', numeric: false, padding: TABLE_PADDING, label: 'City', sortable: true, filtering: true },
  { id: 'state', numeric: false, padding: TABLE_PADDING, label: 'State', sortable: true, filtering: true },
  { id: 'details', numeric: false, padding: TABLE_PADDING, label: 'Internal Notes', sortable: true, filtering: true },
];

function Locations ({ locations, history }) {
  
  const renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          hover
          onClick={() => {
            history.push({
              pathname: `locations/${row.id}`,
            });
          }}
        >
          <TableCell align="left" width={180} padding={TABLE_PADDING}>{row.formattedCreatedDate}</TableCell>
          <TableCell align="left" width={200} padding={TABLE_PADDING}>{row.name}</TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>{row.city}</TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>{row.state}</TableCell>
          <TableCell align="left" padding={TABLE_PADDING}>{row.details}</TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const formattedData = useMemo(
    () => locations.map((row) => {
      row = { ...row }; 
      row.formattedCreatedDate = row.createdDate ? moment(row.createdDate).format(DATE_FORMAT.DATE_TIME) : 'None';
      return row;
    }), [locations]);
  
  return (
    <DataTable
      data={formattedData}
      title={''}
      columns={columns}
      renderRow={renderRow}
      disableSearch
    />
  );
}

Locations.propTypes = {
  locations: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withRouter,
);

export default prepareForExport(Locations);
