import { PROMOTION_SLOT_TEXT_FIELDS } from '../promotionSlots/promotionSlots.actions';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

class PromotionSlotForm extends Component {
  static getPromotionSlot(promotionSlot) {
    if (!promotionSlot) {
      promotionSlot = {};
    }

    const out = {};
    PROMOTION_SLOT_TEXT_FIELDS.forEach((field) => {
      out[field.name] = promotionSlot[field.name] || '';
    });
    return out;
  }

  constructor(props) {
    super(props);

    let state = PromotionSlotForm.getPromotionSlot();

    if (!isEmpty(props.promotionSlot)) {
      // let category = find(props.promotionSlotCategories, { id: props.promotionSlot.categoryId });
      state = PromotionSlotForm.getPromotionSlot(props.promotionSlot);
    }

    this.state = state;

    ['setType', 'togglePublic', 'isFormValid', 'handleSubmit'].map((key) => (this[key] = this[key].bind(this)));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.promotionSlot !== this.props.promotionSlot) {
      this.setState(PromotionSlotForm.getPromotionSlot(nextProps.promotionSlot));
    }
  }

  setType(type) {
    this.setState({ type });
  }

  togglePublic() {
    this.setState({ isPublic: !this.state.isPublic });
  }

  isFormValid() {
    return PROMOTION_SLOT_TEXT_FIELDS.every((field) => field.optional || !isEmpty(this.state[field.name]));
  }

  handleSubmit(e) {
    e.preventDefault();
    let promotionSlotData = PromotionSlotForm.getPromotionSlot(this.state);

    this.props.submitAction(promotionSlotData);
  }

  render() {
    return (
      <div>
        {PROMOTION_SLOT_TEXT_FIELDS.map((field) => {
          return (
            <Fragment key={field.name}>
              <label>{field.label}</label>
              <input
                type="text"
                placeholder={field.label}
                value={this.state[field.name]}
                onChange={(e) => {
                  const state = {};
                  state[field.name] = e.target.value;
                  return this.setState(state);
                }}
              />
            </Fragment>
          );
        })}

        <div style={{ marginTop: '4rem' }}>
          <button className="button expanded" disabled={!this.isFormValid()} onClick={this.handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    );
  }
}

PromotionSlotForm.propTypes = {
  promotionSlot: PropTypes.object,
  submitAction: PropTypes.func.isRequired,
};

export default PromotionSlotForm;
