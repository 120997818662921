import 'chartjs-plugin-annotation';
import { Grid } from '@mui/material';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';

import { HARDWARE_TYPES } from '../../../types/location.types';
import { getLocationStatus } from '../monitoring.actions';
import LoadingOverlay from '../../layout/loadingOverlay/loadingOverlay.component';
import PodLocationOverviewContainer from './pod/podLocationOverview.container';
import TtLockLocationOverviewContainer from './ttlock/ttLockLocationOverview.container';

const MonitoringOverview = ({classes}) => {
  const isLoading = useSelector(state => state.monitoring.get('isLoading'));
  const selectedLocationStatus = useSelector(state => state.monitoring.get('selectedLocationStatus'));
  const selectedLocation = useSelector(state => state.location.get('selectedLocation'));
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getLocationStatus(selectedLocation.id));
  }, [selectedLocation]);

  if (isLoading === true || !selectedLocationStatus?.deviceType) {
    return <LoadingOverlay />;
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        {selectedLocationStatus.deviceType === HARDWARE_TYPES.VAROOM_TTLOCK || selectedLocationStatus.deviceType === HARDWARE_TYPES.TTLOCK_POD ? 
          <TtLockLocationOverviewContainer />
          :
          <PodLocationOverviewContainer />
        }
      </Grid>
    </div>
  );
};

const styles = () => ({
  container: {
    width: 'calc(100% - 24px)',
    marginTop: 12,
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 'calc(100% - 13rem)',
  },
});


MonitoringOverview.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  selectedLocationStatus: PropTypes.object.isRequired,
  getLocationStatus: PropTypes.func.isRequired,
};

const prepareForExport = compose(
  withStyles(styles)
);

export default prepareForExport(MonitoringOverview);
