import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { formatDurationHourMinSec } from '../../../../utilities/dateUtils';
import StatItem from '../../statItem.component';

const LifetimeCounts = ({locationStatus, classes}) => {
  
  const occ_duration = locationStatus.occ_time ? moment.duration(locationStatus.occ_time.value, 'seconds') : null;
  let occupancyTimeTitle = 'Unknown';
  if (!isNil(occ_duration)) {
    occupancyTimeTitle = formatDurationHourMinSec(occ_duration);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} className={classes.statWrapper}>
        <StatItem
          title={locationStatus.occ_cnt ? locationStatus.occ_cnt.value : 'Unknown'}
          date={locationStatus.occ_cnt ? locationStatus.occ_cnt.timestamp : null}
          subtitle="Occupancy Count"
        />
      </Grid>
      <Grid item xs={3} className={classes.statWrapper}>
        <StatItem
          title={occupancyTimeTitle}
          date={locationStatus.occ_time ? locationStatus.occ_time.timestamp : null}
          subtitle="Occupancy Duration"
        />
      </Grid>
      <Grid item xs={3} className={classes.statWrapper}>
        <StatItem
          title={locationStatus.dblt ? locationStatus.dblt.value : 'Unknown'}
          date={locationStatus.dblt ? locationStatus.dblt.timestamp : null}
          subtitle="Deadbolt Actuations"
        />
      </Grid>
      <Grid item xs={3} className={classes.statWrapper}>
        <StatItem
          title={locationStatus.door ? locationStatus.door.value : 'Unknown'}
          date={locationStatus.door ? locationStatus.door.timestamp : null}
          subtitle="Door Actuations"
        />
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  statWrapper: {
    display: 'flex',
  },
});

LifetimeCounts.propTypes = {
  classes: PropTypes.object.isRequired,
  locationStatus: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles)
);

export default prepareForExport(LifetimeCounts);