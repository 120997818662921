import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Input, Link, MenuItem, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { find, first, get, isEmpty, slice } from 'lodash';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import Star from './Star.component';
import VeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import VerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import moment from 'moment';

import { CREATE_REPLY_SUCCESS, DELETE_REPLY_SUCCESS, MODERATE_REVIEW_SUCCESS, UPDATE_REPLY_SUCCESS } from '../location/location.actions';
import { DATE_FORMAT } from '../../utilities/dateUtils';
import { LOCATION_TYPES } from '../../types/location.types';
import { REVIEW_STATUS } from '../../types/review.types';
import { createReply, deleteReply, moderateReview, updateReply } from './location.actions';
import { handleToastMessage, setDirtyForm } from '../layout/layout.actions';
import Colors from '../../styles/colors';

const MAMAVA_HUBSPOT_FORM_BASE_URL = 'https://www.mamava.com/contact/in-app';

const TYPE_OPTIONS = {
  SATISFIED_CLEANLINESS: 0,
  DISSATISFIED_CLEANLINESS: 1,
  SATISFIED_COMFORT: 2,
  DISSATISFIED_COMFORT: 3,
  SATISFIED_CONVENIENCE: 4,
  DISSATISFIED_CONVENIENCE: 5,
};

const DATA_COLUMNS = [
  { fieldName: 'userName', displayName: 'User Name', type: 'Input' },
  { fieldName: 'review', displayName: 'Words', type: 'Input' },
  { fieldName: 'createdDate', displayName: 'Created Date', type: 'None' },
  {
    fieldName: 'rating',
    displayName: 'Stars',
    type: 'Select',
    values: [
      <MenuItem key={'rating_-1'} value={-1}>
        All
      </MenuItem>,
      <MenuItem key={'rating_0'} value={0}>
        <Star total={5} rating={0} marginTop={-5} />
      </MenuItem>,
      <MenuItem key={'rating_1'} value={1}>
        <Star total={5} rating={1} marginTop={-5} />
      </MenuItem>,
      <MenuItem key={'rating_2'} value={2}>
        <Star total={5} rating={2} marginTop={-5} />
      </MenuItem>,
      <MenuItem key={'rating_3'} value={3}>
        <Star total={5} rating={3} marginTop={-5} />
      </MenuItem>,
      <MenuItem key={'rating_4'} value={4}>
        <Star total={5} rating={4} marginTop={-5} />
      </MenuItem>,
      <MenuItem key={'rating_5'} value={5}>
        <Star total={5} rating={5} marginTop={-5} />
      </MenuItem>,
    ],
  },
  {
    fieldName: 'type',
    displayName: 'type',
    type: 'Select',
    values: [
      <MenuItem key={'type_-1'} value={-1}>
        All
      </MenuItem>,
      <MenuItem key={`type_${TYPE_OPTIONS.SATISFIED_CLEANLINESS}`} value={TYPE_OPTIONS.SATISFIED_CLEANLINESS}>
        <span style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
          <VerySatisfiedIcon />Cleanliness
        </span>
      </MenuItem>,
      <MenuItem key={`type_${TYPE_OPTIONS.DISSATISFIED_CLEANLINESS}`} value={TYPE_OPTIONS.DISSATISFIED_CLEANLINESS}>
        <span style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
          <VeryDissatisfiedIcon />Cleanliness
        </span>
      </MenuItem>,
      <MenuItem key={`type_${TYPE_OPTIONS.SATISFIED_COMFORT}`} value={TYPE_OPTIONS.SATISFIED_COMFORT}>
        <span style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
          <VerySatisfiedIcon />Comfort
        </span>
      </MenuItem>,
      <MenuItem key={`type_${TYPE_OPTIONS.DISSATISFIED_CLEANLINESS}`} value={TYPE_OPTIONS.DISSATISFIED_COMFORT}>
        <span style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
          <VeryDissatisfiedIcon />Comfort
        </span>
      </MenuItem>,
      <MenuItem key={`type_${TYPE_OPTIONS.SATISFIED_CONVENIENCE}`} value={TYPE_OPTIONS.SATISFIED_CONVENIENCE}>
        <span style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
          <VerySatisfiedIcon />Convenience
        </span>
      </MenuItem>,
      <MenuItem key={`type_${TYPE_OPTIONS.DISSATISFIED_CONVENIENCE}`} value={TYPE_OPTIONS.DISSATISFIED_CONVENIENCE}>
        <span style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
          <VeryDissatisfiedIcon />Convenience
        </span>
      </MenuItem>,
    ],
  },
  {
    fieldName: 'status',
    displayName: 'Status',
    type: 'Select',
    values: [
      <MenuItem key={'status_ALL'} value={-1} style={{ margin: 10 }}>
        All
      </MenuItem>,
      <MenuItem key={'status_APPROVED'} value={REVIEW_STATUS.APPROVED} style={{ margin: 10 }}>
        Approved
      </MenuItem>,
      <MenuItem key={'status_DECLINED'} value={REVIEW_STATUS.DECLINED} style={{ margin: 10 }}>
        Declined
      </MenuItem>,
      <MenuItem key={'status_SUBMITTED'} value={REVIEW_STATUS.SUBMITTED} style={{ margin: 10 }}>
        Submitted
      </MenuItem>,
    ],
  },
];

class ReviewsComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hasChanged: false,
      reviews: [],
      filteredReviews: [],
      currentReviews: [],
      page: 0,
      rowsPerPage: 10,
      filters: {
        userName: '',
        review: '',
        rating: -1,
        type: -1,
        status: -1,
      },
      dialogOpen: false,
      selectedReview: null,
      reviewReply: '',
    };

    ['saveReply'].map((key) => (this[key] = this[key].bind(this)));
  }

  UNSAFE_componentWillMount() {
    this.buildReviews();
    this.buildCurrentReviews();
  }

  buildReviews() {
    let reviews = [];
    if (this.props.selectedLocation && this.props.selectedLocation.reviews) {
      reviews = this.props.selectedLocation.reviews.map((review) => {
        let newReview = {};
        newReview.userName = review.user.firstName;
        newReview.email = review.user.email;
        newReview.createdDate = review.createdDate;
        newReview.rating = review.rating;
        newReview.review = review.review;
        newReview.type = [
          review.cleanliness > 50 ? TYPE_OPTIONS.SATISFIED_CLEANLINESS : TYPE_OPTIONS.DISSATISFIED_CLEANLINESS,
          review.comfort > 50 ? TYPE_OPTIONS.SATISFIED_COMFORT : TYPE_OPTIONS.DISSATISFIED_COMFORT,
          review.convienence > 50 ? TYPE_OPTIONS.SATISFIED_CONVENIENCE : TYPE_OPTIONS.DISSATISFIED_CONVENIENCE,
        ];
        newReview.cleanliness = review.cleanliness;
        newReview.comfort = review.comfort;
        newReview.convienence = review.convienence;
        newReview.id = review.id;
        newReview.status = review.status;
        newReview.disabled = review.status !== REVIEW_STATUS.SUBMITTED;
        newReview.childReviews = review.childReviews;
        return newReview;
      });
    }
    this.props.setDirtyForm(false);
    this.setState({ reviews }, this.buildCurrentReviews);
  }

  buildCurrentReviews() {
    let { page, rowsPerPage } = this.state;
    let reviews = this.filterArray(this.state.reviews, this.state.filters);
    this.setState({
      currentReviews: slice(reviews, page * rowsPerPage, (page + 1) * rowsPerPage),
      filteredReviews: reviews,
    });
  }

  filterArray(array, filters) {
    let currentArray = array;
    if (array) {
      currentArray = array
        .map((record) => {
          let returnRecord = record;
          if (filters && Object.keys(filters).length > 0) {
            DATA_COLUMNS.map((column) => {
              if (returnRecord) {
                if (column.type === 'Input') {
                  if (
                    !isEmpty(filters[column.fieldName]) &&
                    (!record ||
                      !record[column.fieldName] ||
                      !record[column.fieldName].toLowerCase().includes(filters[column.fieldName].toLowerCase()))
                  ) {
                    returnRecord = undefined;
                  }
                } else {
                  if (Array.isArray(record[column.fieldName])) {
                    if (filters[column.fieldName] >= 0 && !record[column.fieldName].includes(filters[column.fieldName])) {
                      returnRecord = undefined;
                    }
                  } else {
                    if (
                      (!isNaN(filters[column.fieldName]) && filters[column.fieldName] >= 0) ||
                      (isNaN(filters[column.fieldName]) && !isEmpty(filters[column.fieldName]))
                    ) {
                      if (record[column.fieldName] !== filters[column.fieldName]) {
                        returnRecord = undefined;
                      }
                    }
                  }
                }
              }
              return null;
            });
          }
          return returnRecord;
        })
        .filter((record) => (record ? true : false))
        .sort((a, b) => {
          if (a.status === REVIEW_STATUS.SUBMITTED) {
            return -1;
          } else if (b.status === REVIEW_STATUS.SUBMITTED) {
            return 1;
          }
          return 0;
        });
    }
    return currentArray;
  }

  async moderateReview(reviewId, status) {
    let response = await this.props.moderateReview(this.props.selectedLocation.id, reviewId, status);
    if (response.type === MODERATE_REVIEW_SUCCESS) {
      this.props.handleToastMessage(`Review status has been updated to ${status}.`);
    } else {
      const message = first(get(response, 'messages', []));
      this.props.handleToastMessage(`Failed to moderate review: ${message}`, true);
    }

    this.buildReviews();
  }

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  reply(reviewId) {
    let selectedReview = find(this.state.reviews, { id: reviewId });
    this.setState({ selectedReview, reviewReply: '', dialogOpen: true });
  }

  editReply(reviewId) {
    let selectedReview = find(this.state.reviews, { id: reviewId });
    let reviewReply = selectedReview.childReviews ? selectedReview.childReviews[0].review : '';
    this.setState({ selectedReview, reviewReply, dialogOpen: true });
  }

  getHubspotCreateFormUrl(reviewerEmail) {
    const {name, id, podDeviceSerial } = this.props.selectedLocation;

    const hubspotUrlParam = new URLSearchParams({
      pod_used_location: name,
      location_id: id,
      pod_used_serial: podDeviceSerial,
      email: reviewerEmail,
      pod_review: 'true',
    });

    return `${MAMAVA_HUBSPOT_FORM_BASE_URL}?${hubspotUrlParam}`;
  }

  async saveReply() {
    let childReview = this.state.selectedReview.childReviews ? this.state.selectedReview.childReviews[0] : null;
  
    if (childReview){
      childReview.review = this.state.reviewReply;

      let response = await this.props.updateReply(this.props.selectedLocation.id, childReview.id, childReview);
      if (response.type === UPDATE_REPLY_SUCCESS) {
        this.props.handleToastMessage('Reply Udpdated.');
      } else {
        const message = first(get(response, 'messages', []));
        this.props.handleToastMessage(`Failed to update review: ${message}`, true);
      }
    } else {

      childReview = {
        cleanliness: 50,
        comfort: 50,
        convenience: 50,
        rating: 5,
        review: this.state.reviewReply,
        parentReviewId: this.state.selectedReview.id,
      };

      let response = await this.props.createReply(this.props.selectedLocation.id, childReview);
      if (response.type === CREATE_REPLY_SUCCESS) {
        this.props.handleToastMessage('Reply Created.');
      } else {
        const message = first(get(response, 'messages', []));
        this.props.handleToastMessage(`Failed to create reply: ${message}`, true);
      }

    }

    this.setState({ selectedReview: null, reviewReply: '', dialogOpen: false });
    this.buildReviews();
  }

  async deleteReply(review) {
    if (window.confirm('Are you sure you want to remove reply')) {
      let response = await this.props.deleteReply(this.props.selectedLocation.id, review.parentReviewId, review.id);

      if (response.type === DELETE_REPLY_SUCCESS) {
        this.props.handleToastMessage('Reply deleted.');
        this.buildReviews();
      }
      else {
        this.props.handleToastMessage('Failed to delete reply.', true);
      }
    }
  }

  handleUpdateFilters(filter, value) {
    let filters = this.state.filters;
    filters[filter] = value;
    this.setState({ state: { filters } }, this.buildCurrentReviews);
  }

  handleFilterChange = (field, value) => {
    this.handleUpdateFilters(field, value);
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, this.buildCurrentReviews);
  };

  handleChangeRowsPerPage = (event) => {
    localStorage.setItem('rowsPerPage', event.target.value);
    this.setState({ rowsPerPage: event.target.value }, this.buildReviews);
  };

  render() {
    let { classes } = this.props;
    return (
      <Card className={classes.reviewCard}>
        <CardContent>
          <div className={classes.averageRating}>
          Average: <Star total={5} rating={this.props.selectedLocation.averageRating} />
          </div>
          {this.props.isLoading && <div className="loader" />}
          {!this.props.isLoading && (
            <div className={classes.tableWrapper} id="tableWrapper">
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {DATA_COLUMNS.map((column, i) => {
                      return (
                        <TableCell className={classes.head} key={`tableCell-${i}`}>
                          <div>{column.displayName}</div>
                          {column.type === 'Input' && (
                            <div>
                              <Input
                                margin="none"
                                id={`filter_${column.fieldName}`}
                                variant="caption"
                                placeholder="filter"
                                className={classes.textField}
                                classes={{
                                  root: classes.root,
                                }}
                                fullWidth
                                value={this.state.filters[column.fieldName]}
                                onChange={(e) => this.handleFilterChange(column.fieldName, e.target.value)}
                                type={column.type ? column.type : 'text'}
                              />
                            </div>
                          )}
                          {column.type === 'Select' && (
                            <div>
                              <Select
                                id={`filter_${column.fieldName}`}
                                className={classes.textField}
                                value={this.state.filters[column.fieldName]}
                                onChange={(e) => this.handleFilterChange(column.fieldName, e.target.value)}
                              >
                                {column.values &&
                                  column.values.map((option) => {
                                    return option;
                                  })}
                                {!column.values && <MenuItem value="">None</MenuItem>}
                              </Select>
                            </div>
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell className={classes.head} key={'tableCell-actions'} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.currentReviews.map((review, i) => {
                    let rows = [];
                    let childReview = review.childReviews ? review.childReviews[0] : null;

                    rows.push (
                      <TableRow key={`review-${review.id}-${i}`}>
                        <TableCell
                          className={review.disabled ? classes.cellDisabled : classes.cell}
                          style={childReview && { borderColor: 'white'}}
                          component="td"
                          scope="row"
                          key={`review-userName-${i}`}
                        >
                          <div className={classes.userName} style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            {review.userName}
                          </div>
                          <a href={`mailto:${review.email}`} target="_blank">{review.email}</a>
                        </TableCell>
                        <TableCell
                          className={review.disabled ? classes.cellDisabled : classes.cell}
                          style={childReview && { borderColor: 'white'}}
                          component="td"
                          scope="row"
                          key={`review-review-${i}`}
                        >
                          <div className={classes.tableField}>{review.review}</div>
                        </TableCell>
                        <TableCell
                          className={review.disabled ? classes.cellDisabled : classes.cell}
                          style={childReview && { borderColor: 'white'}}
                          component="td"
                          scope="row"
                          key={`review-createdDate-${i}`}
                        >
                          <div className={classes.tableField}>{moment(review.createdDate).format(DATE_FORMAT.DATE_TIME)}</div>
                        </TableCell>
                        <TableCell
                          className={review.disabled ? classes.cellDisabled : classes.cell}
                          style={childReview && { borderColor: 'white'}}
                          component="td"
                          scope="row"
                          key={`review-rating-${i}`}
                        >
                          <div>
                            <Star total={5} rating={review.rating} />
                          </div>
                        </TableCell>
                        <TableCell
                          className={review.disabled ? classes.cellDisabled : classes.cell}
                          style={childReview && { borderColor: 'white'}}
                          component="td"
                          scope="row"
                          key={`review-type-${i}`}
                        >
                          <div
                            className={classes.tableFieldBold}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              alignSelf: 'flex-start',
                              padding: 5,
                            }}
                          >
                            <div style={{ display: 'flex', padding: 5, alignItems: 'center' }}>
                              {review.cleanliness > 50 ? (
                                <VerySatisfiedIcon />
                              ) : review.cleanliness < 50 ? (
                                <VeryDissatisfiedIcon />
                              ) : (
                                <SatisfiedIcon />
                              )}{' '}
                              Cleanliness
                            </div>
                            <div style={{ display: 'flex', padding: 5, alignItems: 'center' }}>
                              {review.comfort > 50 ? (
                                <VerySatisfiedIcon />
                              ) : review.cleanliness < 50 ? (
                                <VeryDissatisfiedIcon />
                              ) : (
                                <SatisfiedIcon />
                              )}{' '}
                              Comfort
                            </div>
                            <div style={{ display: 'flex', padding: 5, alignItems: 'center' }}>
                              {review.convenience > 50 ? (
                                <VerySatisfiedIcon />
                              ) : review.cleanliness < 50 ? (
                                <VeryDissatisfiedIcon />
                              ) : (
                                <SatisfiedIcon />
                              )}{' '}
                              Convenience
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className={review.disabled ? classes.cellDisabled : classes.cell}
                          style={childReview && { borderColor: 'white'}}
                          component="td"
                          scope="row"
                          key={`review-status-${i}`}
                        >
                          <div className={classes.tableFieldBold}>{review.status}</div>
                        </TableCell>

                        <TableCell 
                          className={classes.cell} 
                          style={childReview && { borderColor: 'white'}}
                          component="td" 
                          scope="row"
                          key={`review-actions-${i}`}
                        >
                          <div className={classes.rowActionBox}>
                            {this.props.selectedLocation.type === LOCATION_TYPES.MAMAVA && <Link target="_blank" href={this.getHubspotCreateFormUrl(review.email)} >Create Hubspot Form</Link> }
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                              {review.status === REVIEW_STATUS.APPROVED && !childReview &&
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  className={classes.button}
                                  onClick={() => this.reply(review.id, true)}
                                >
                                  Reply
                                </Button>
                              }
                              {review.status !== REVIEW_STATUS.APPROVED &&
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  className={classes.button}
                                  onClick={() => this.moderateReview(review.id, REVIEW_STATUS.APPROVED)}
                                >
                                  Approve
                                </Button>
                              }
                              {review.status !== REVIEW_STATUS.DECLINED &&
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  className={classes.button}
                                  onClick={() => this.moderateReview(review.id, REVIEW_STATUS.DECLINED)}
                                >
                                  Decline
                                </Button>
                              }
                              {review.status !== REVIEW_STATUS.UNDER_REVIEW &&
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  className={classes.button}
                                  onClick={() => this.moderateReview(review.id, REVIEW_STATUS.UNDER_REVIEW)}
                                >
                                  Under Review
                                </Button>
                              }
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                              
                    if (childReview){
                      rows.push(
                        <TableRow key={`review-${childReview.id}-${i}`}>
                          <TableCell
                            className={review.disabled ? classes.cellDisabled : classes.cell}
                            component="td"
                            scope="row"
                            key={`review-userName-${i}`}
                          />
                          <TableCell
                            className={review.disabled ? classes.cellDisabled : classes.cell}
                            component="td"
                            scope="row"
                            key={`review-review-${i}`}
                            colSpan={5}
                          >
                            <div className={classes.userName} style={{ display: 'flex', flexWrap: 'nowrap', fontSize: 12 }}>
                              {childReview.user.firstName} - {moment(childReview.createdDate).format('MMM Do YYYY')}
                            </div>
                            <div className={classes.tableField}>{childReview.review}</div>
                          </TableCell>
                          <TableCell className={classes.cell} component="td" scope="row"
                            key={`review-actions-${i}`}
                          >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                onClick={() => this.editReply(review.id)}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                style={{ fontSize: '0.7rem'}}
                                onClick={() => this.deleteReply(childReview)}
                              >
                                Delete Reply 
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }

                    return rows;

                  })}
                </TableBody>
              </Table> 
              <Dialog
                open={this.state.dialogOpen}
                onClose={this.handleClose}
                fullWidth
              >
                <DialogTitle style={{ color: Colors.primary.main }}>Reply to a Review</DialogTitle>
                <DialogContent>
                  { this.state.selectedReview &&
                    <div style={{ color: Colors.primary.main }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <div className={classes.userName} style={{ display: 'flex', flexWrap: 'nowrap' }}>
                          {this.state.selectedReview.userName} - {moment(this.state.selectedReview.createdDate).format('MMM YYYY')}
                        </div>
                        <div>
                          <Star total={5} rating={this.state.selectedReview.rating} />
                        </div>
                      </div>
                      <div className={classes.tableField}>{this.state.selectedReview.review}</div>
                      <TextField
                        autoFocus
                        id="reviewReply"
                        label="Reply"
                        fullWidth
                        multiline
                        rows="4"
                        className={classes.reviewReply}
                        value={this.state.reviewReply}
                        onChange={(event) => this.setState({ reviewReply: event.target.value })}
                      />
                    </div>
                  }

                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={this.saveReply} color="primary" variant="outlined"
                    disabled={this.state.reviewReply === ''}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          )}

          {!this.props.isLoading && (
            <TablePagination
              component="div"
              count={this.state.filteredReviews.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              style={{ flex: 0 }}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          )}
          
        </CardContent>
      </Card>
    );
  }
}

ReviewsComponent.propTypes = {
  selectedLocation: PropTypes.object.isRequired,
  moderateReview: PropTypes.func.isRequired,
  updateReply: PropTypes.func.isRequired,
  createReply: PropTypes.func.isRequired,
  deleteReply: PropTypes.func.isRequired,
  hasDirtyForm: PropTypes.bool,

  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,

  handleToastMessage: PropTypes.func.isRequired,
  setDirtyForm: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,

  // Injected by React Router
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.location.get('isLoading'),
    errors: state.location.get('errors'),
    hasDirtyForm: state.layout.get('hasDirtyForm'),
  };
};

const styles = (theme) => ({
  reviewCard: {
    margin: 10,
  },
  averageRating: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'GothamRounded-Bold',
    fontSize: 24,
    color: Colors.primary.main,
  },
  textField: {
    boxShadow: 'none',
    fontWeight: 'normal',
    border: 0,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 0,
    marginRight: 0,
    fontSize: 12,
  },
  selectField: {
    fontSize: 12,
    marginTop: -10,
  },
  cellDisabled: {
    color: Colors.secondary.main,
    '& a': {
      color: Colors.secondary.main,
    },
  },
  cell: {
    color: Colors.primary.main,
  },
  input: {
    height: 20,
  },
  link: {
    color: Colors.primary.main,
    textDecoration: 'none',
  },
  userName: {
    fontFamily: 'GothamRounded-Bold',
    fontSize: 16,
  },
  tableFieldBold: {
    fontFamily: 'GothamRounded-Bold',
    fontSize: 12,
    fontWeight: 800,
  },
  tableField: {
    fontFamily: 'GothamRounded-Light',
    fontSize: 12,
  },
  head: {
    position: 'sticky',
    zIndex: 5,
    top: 0,
    background: Colors.white,
  },
  tableWrapper: {
    padding: '1rem',
    paddingTop: 0,
    overflowX: 'auto',
  },
  tableBody: {
    overflowY: 'auto',
  },
  button: {
    margin: theme.spacing(1),
  },
  tabs: {
    backgroundColor: Colors.secondary.main,
  },
  tab: {
    color: Colors.white,
    fontWeight: 'bold',
  },
  reviewReply: {
    marginTop: theme.spacing(3),
  },
  rowActionBox: {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
  },
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      {
        handleToastMessage,
        setDirtyForm,
        moderateReview,
        createReply,
        updateReply,
        deleteReply,
      }
    )(ReviewsComponent)
  )
);
