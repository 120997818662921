import { isEmpty, union } from 'lodash';
import Immutable from 'immutable';
import moment from 'moment';

import {
  GET_COMMAND_CONFIG_FAILURE,
  GET_COMMAND_CONFIG_REQUEST,
  GET_COMMAND_CONFIG_SUCCESS,
  GET_COMMAND_SETS_FAILURE,
  GET_COMMAND_SETS_REQUEST,
  GET_COMMAND_SETS_SUCCESS,
  GET_COMMAND_SET_FAILURE,
  GET_COMMAND_SET_REQUEST,
  GET_COMMAND_SET_SUCCESS,
  GET_IOT_LOCATIONS_FAILURE,
  GET_IOT_LOCATIONS_REQUEST,
  GET_IOT_LOCATIONS_SUCCESS,
  GET_IOT_LOCATION_FAILURE,
  GET_IOT_LOCATION_REQUEST,
  GET_IOT_LOCATION_SUCCESS,
  GET_PODS_FAILURE,
  GET_PODS_REQUEST,
  GET_PODS_SUCCESS,
  RESPONSE_FAILURE,
  RESPONSE_PENDING,
  RESPONSE_SUCCESS,
  SELECT_PODS,
  SEND_COMMAND_FAILURE,
  SEND_COMMAND_REQUEST,
  SEND_COMMAND_SUCCESS,
  SET_ACTIVE_STEP,
  SET_COMMAND_PAYLOAD,
  SET_CUSTOM_COMMAND,
  SET_DESCRIPTION,
  SET_SELECTED_COMMAND_INDEX,
} from './iotCommand.actions';

const initialState = Immutable.Map({
  isLoading: false,
  errors: [],

  commandConfig: [],
  availableLocations: [],

  selectedPods: [],
  description: '',
  selectedCommandIndex: -1,
  commandPayload: {},
  activeStep: 0,
  customCommand: '',

  commandSets: [],
  locations: [],
  selectedLocation: null,
  selectedCommandSet: null,
});

export default function(state = initialState, action) {
  switch (action.type) {

    case GET_COMMAND_SETS_FAILURE:
      return state.set('isLoading', false);

    case GET_COMMAND_SETS_SUCCESS: {
      const commandSets = action.response.map((commandSet) => {
        const lastResponseReceived = commandSet.commandResponses.reduce((mostRecentDate, response) => {
          
          // If mostRecentDate has not been set, use deviceReceivedDate
          if (mostRecentDate === null) {
            return response.deviceReceivedDate ? response.deviceReceivedDate : null;
          }
  
          if (response.deviceReceivedDate && moment(mostRecentDate).diff(moment(response.deviceReceivedDate) > 0)) {
            // If deviceReceivedDate is more recent than mostRecentDate
            return response.deviceReceivedDate;
          }
  
          return mostRecentDate;
        }, null);
  
        return {
          ...commandSet,
          [RESPONSE_FAILURE]: commandSet.commandResponses.reduce(
            (count, response) => response.status === RESPONSE_FAILURE ? count + 1 : count, 0
          ),
          [RESPONSE_SUCCESS]: commandSet.commandResponses.reduce(
            (count, response) => response.status === RESPONSE_SUCCESS ? count + 1 : count, 0
          ),
          lastResponseReceived,
        };
      });

      return state.set('commandSets', commandSets).set('isLoading', false);
    }

    case GET_COMMAND_SETS_REQUEST:
      return state.set('isLoading', true);

    case GET_COMMAND_SET_FAILURE:
      return state.set('isLoading', false);
  
    case GET_COMMAND_SET_SUCCESS: {
      const selectedCommandSet = {
        ...action.response,
        [RESPONSE_FAILURE]: action.response.commandResponses ? 
          action.response.commandResponses.reduce(
            (count, response) => response.status === RESPONSE_FAILURE ? count + 1 : count, 0
          ): 0,
        [RESPONSE_SUCCESS]: action.response.commandResponses ? 
          action.response.commandResponses.reduce(
            (count, response) => response.status === RESPONSE_SUCCESS ? count + 1 : count, 0
          ): 0,
      };
      return state.set('selectedCommandSet', selectedCommandSet).set('isLoading', false);
    }
  
    case GET_COMMAND_SET_REQUEST:
      return state.set('isLoading', true);

    case GET_COMMAND_CONFIG_FAILURE:
      return state.set('isLoading', false);

    case GET_COMMAND_CONFIG_SUCCESS:
      return state.set('commandConfig', action.response).set('isLoading', false);

    case GET_COMMAND_CONFIG_REQUEST:
      return state.set('isLoading', true);

    case GET_PODS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_PODS_SUCCESS:
      return state.set('availableLocations', action.response).set('isLoading', false);

    case GET_PODS_REQUEST:
      return state.set('isLoading', true);

    case SELECT_PODS: {
      if (isEmpty(action.response.locationIds)) {
        return state;
      }

      // Add all the pods that are not already selected
      if (action.response.isSelected) {
        const prevSelectedLocationIds = state.get('selectedPods').map((location) => location.id);
        const newSelectedLocationIds = union(prevSelectedLocationIds, action.response.locationIds);
        return state.set('selectedPods', state.get('availableLocations').filter((location) => newSelectedLocationIds.includes(location.id)));
      }

      // Remove all the pods in the list passed in
      return state.set('selectedPods', state.get('selectedPods').filter((location) => !action.response.locationIds.includes(location.id)));
    }

    case SET_DESCRIPTION:
      return state.set('description', action.response);

    case SET_SELECTED_COMMAND_INDEX:
      return state.set('selectedCommandIndex', action.response);

    case SET_COMMAND_PAYLOAD:
      return state.set('commandPayload', action.response);

    case SET_CUSTOM_COMMAND:
      return state.set('customCommand', action.response);
    
    case SET_ACTIVE_STEP:
      return state.set('activeStep', action.response);

    case SEND_COMMAND_REQUEST: {

      const commandSet = {
        description: action.payload.description,
        commandName: action.payload.commandName,
        commandResponses: [],
      };
      commandSet.commandResponses = state.get('selectedPods').map((location) => {
        return {
          location,
          status: RESPONSE_PENDING,
        };
      });

      return state.set('selectedCommandSet', commandSet)
        .set('description', initialState.get('description'))
        .set('selectedCommandIndex', initialState.get('selectedCommandIndex'))
        .set('activeStep', initialState.get('activeStep'))
        .set('commandPayload', initialState.get('commandPayload'))
        .set('customCommand', initialState.get('customCommand'))
        .set('selectedPods', initialState.get('selectedPods'));
    }

    case SEND_COMMAND_FAILURE:
      return state.set('isLoading', false);

    case SEND_COMMAND_SUCCESS: {
      return state.set('selectedCommandSet', action.response).set('isLoading', false);
    }

    case GET_IOT_LOCATIONS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_IOT_LOCATIONS_SUCCESS:
      return state.set('locations', action.response).set('isLoading', false);

    case GET_IOT_LOCATIONS_REQUEST:
      return state.set('isLoading', true);

    case GET_IOT_LOCATION_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_IOT_LOCATION_SUCCESS:
      return state.set('selectedLocation', action.response).set('isLoading', false);

    case GET_IOT_LOCATION_REQUEST:
      return state.set('isLoading', true);

    default:
      return state;
  }
}