import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select} from '@mui/material';
import PropTypes from 'prop-types';
import React, {  } from 'react';

import { LIFECYCLE_STATUS, LOCATION_TYPES } from '../../../types/location.types';

export const LifecycleStatusToLabelMap = ({
  [LIFECYCLE_STATUS.PREBUILT]: {
    [LOCATION_TYPES.LOCATION]: 'Prebuilt',
    [LOCATION_TYPES.MAMAVA]: 'Prebuilt',
  },
  [LIFECYCLE_STATUS.WAITING_TO_ACTIVATE]: {
    [LOCATION_TYPES.LOCATION]: 'Waiting to activate',
    [LOCATION_TYPES.MAMAVA]: 'Waiting to install',
  },
  [LIFECYCLE_STATUS.ACTIVE]: {
    [LOCATION_TYPES.LOCATION]: 'Active',
    [LOCATION_TYPES.MAMAVA]: 'Installed',
  },
  [LIFECYCLE_STATUS.REJECTED]: {
    [LOCATION_TYPES.LOCATION]: 'Rejected',
    [LOCATION_TYPES.MAMAVA]: 'Rejected',
  },
  [LIFECYCLE_STATUS.TEMPORARILY_DOWN]: {
    [LOCATION_TYPES.LOCATION]: 'Location Temporarily Down',
    [LOCATION_TYPES.MAMAVA]: 'Location Temporarily Down',
  },
  [LIFECYCLE_STATUS.UNDER_REVIEW]: {
    [LOCATION_TYPES.LOCATION]: 'Under Review',
    [LOCATION_TYPES.MAMAVA]: 'Under Review',
  },
  [LIFECYCLE_STATUS.ACTIVATION_ON_HOLD]: {
    [LOCATION_TYPES.LOCATION]: 'Installation on Hold',
    [LOCATION_TYPES.MAMAVA]: 'Installation on Hold',
  },
  [LIFECYCLE_STATUS.DECOMMISSIONED]: {
    [LOCATION_TYPES.LOCATION]: 'Decommissioned',
    [LOCATION_TYPES.MAMAVA]: 'Decommissioned',
  },
  [LIFECYCLE_STATUS.DEPRECATED]: {
    [LOCATION_TYPES.LOCATION]: 'Deprecated',
    [LOCATION_TYPES.MAMAVA]: 'Deprecated',
  },
  [LIFECYCLE_STATUS.LOCATION_UNKNOWN]: {
    [LOCATION_TYPES.LOCATION]: 'Location Unknown',
    [LOCATION_TYPES.MAMAVA]: 'Location Unknown',
  },
});
function LifecycleStatusSection({ disabled, onChange, selectedLocation }) {
  const handleLifecycleStatusChange = async ({ target: { value } }) => {
    const isDecommissionned = value === LIFECYCLE_STATUS.DECOMMISSIONED;
    if (isDecommissionned) {
      const response = window.confirm('You are setting this pod to be decommissioned.  It will be set to uninstalled, and will not be part of the fleet.');
      if (!response ) {
        return;
      }
    } 
    await onChange({
      target: {
        name: 'lifecycleStatus',
        value,
      },
    });
  };

  const getLifecycleStatusLabel = lifecycleStatus => {
    return LifecycleStatusToLabelMap[lifecycleStatus][selectedLocation.type] || LifecycleStatusToLabelMap[lifecycleStatus][LOCATION_TYPES.MAMAVA];
  };

  return (
    <FormControl
      variant="outlined"
      error={!selectedLocation.lifecycleStatus}
      fullWidth
    >
      <InputLabel shrink>Lifecycle Status</InputLabel>
      <Select
        aria-label="lifecycleStatus"
        name="lifecycleStatus"
        value={selectedLocation.lifecycleStatus}
        onChange={handleLifecycleStatusChange}
        disabled={disabled}
        input={<OutlinedInput labelWidth={120} />}
      >
        <MenuItem disabled value={LIFECYCLE_STATUS.PREBUILT}>{getLifecycleStatusLabel(LIFECYCLE_STATUS.PREBUILT)}</MenuItem>
        <MenuItem value={LIFECYCLE_STATUS.WAITING_TO_ACTIVATE}>{getLifecycleStatusLabel(LIFECYCLE_STATUS.WAITING_TO_ACTIVATE)}</MenuItem>
        <MenuItem value={LIFECYCLE_STATUS.ACTIVE}>{getLifecycleStatusLabel(LIFECYCLE_STATUS.ACTIVE)}</MenuItem>
        <MenuItem value={LIFECYCLE_STATUS.REJECTED} disabled={true}>{getLifecycleStatusLabel(LIFECYCLE_STATUS.REJECTED)}</MenuItem>
        <MenuItem value={LIFECYCLE_STATUS.TEMPORARILY_DOWN}>{getLifecycleStatusLabel(LIFECYCLE_STATUS.TEMPORARILY_DOWN)}</MenuItem>
        <MenuItem value={LIFECYCLE_STATUS.UNDER_REVIEW}>{getLifecycleStatusLabel(LIFECYCLE_STATUS.UNDER_REVIEW)}</MenuItem>
        <MenuItem value={LIFECYCLE_STATUS.ACTIVATION_ON_HOLD}>{getLifecycleStatusLabel(LIFECYCLE_STATUS.ACTIVATION_ON_HOLD)}</MenuItem>
        <MenuItem value={LIFECYCLE_STATUS.DECOMMISSIONED}>{getLifecycleStatusLabel(LIFECYCLE_STATUS.DECOMMISSIONED)}</MenuItem>
        <MenuItem value={LIFECYCLE_STATUS.DEPRECATED}>{getLifecycleStatusLabel(LIFECYCLE_STATUS.DEPRECATED)}</MenuItem>
        <MenuItem value={LIFECYCLE_STATUS.LOCATION_UNKNOWN}>{getLifecycleStatusLabel(LIFECYCLE_STATUS.LOCATION_UNKNOWN)}</MenuItem>
      </Select>
      {!selectedLocation.lifecycleStatus && <FormHelperText>Lifecycle status is required</FormHelperText> }
    </FormControl>
  );
}

LifecycleStatusSection.propTypes = {
  disabled: PropTypes.bool,
  selectedLocation: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LifecycleStatusSection;
