import { compose } from 'redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

import DataTable from '../../layout/dataTable/dataTable.component';

const styles = () => ({
  tableRowRoot: {
    cursor: 'pointer !important',
  },
});

// COLUMN DEFINITIONS
// note: id names determine sort order key (i.e. 'name' will sort on name)
const columns = [
  { id: 'name', numeric: false, padding: 'dense', label: 'Description', sortable: true, filtering: true },
  { id: 'podDeviceSerial', numeric: false, padding: 'dense', label: 'POD Serial Number', sortable: true, filtering: true },
  { id: 'commandName', numeric: false, padding: 'dense', label: 'Last Command', sortable: true, filtering: true },
  { id: 'userEmail', numeric: false, padding: 'dense', label: 'User', sortable: true, filtering: true },
  { id: 'sentDate', numeric: false, padding: 'dense', label: 'Date Sent', sortable: true, filtering: false },
  { id: 'lastResponseReceived', numeric: false, padding: 'dense', label: 'Last Response Received', sortable: true, filtering: false },
  { id: 'status', numeric: true, padding: 'dense', label: 'Status', sortable: true, filtering: true },
];

const LocationsTable = ({data, history}) => {
  const handleAddClick = () => {
    history.push('/iotCommands/commandSet/create');
  };

  const handleRowClick = async (row) => {
    history.push(`/iotCommands/location/${row.id}`);
  };

  // get custom row render component
  const renderRow = (row, i) => {
    return (
      <Fragment key={i}>
        <TableRow
          tabIndex={-1}
          onClick={() => handleRowClick(row)}
          hover
        >
          <TableCell align="left" padding={columns[0].padding}>{row.name}</TableCell>
          <TableCell align="left" padding={columns[0].padding}>{row.podDeviceSerial}</TableCell>
          <TableCell align="left" padding={columns[1].padding}>{row.commandName}</TableCell>
          <TableCell align="left" padding={columns[2].padding}>{row.userEmail}</TableCell>
          <TableCell align="left" padding={columns[3].padding}>{row.sentDate}</TableCell>
          <TableCell align="left" padding={columns[4].padding}>{row.lastResponseReceived}</TableCell>
          <TableCell align="left" padding={columns[5].padding}>{row.status}</TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const formattedData = useMemo(
    () => data.map((row) => {
      row = { ...row }; 
      if (row.lastCommandResponse) {
        row.lastResponseReceived = `${row.lastCommandResponse.deviceReceivedDate ? moment(row.lastCommandResponse.deviceReceivedDate).format('MMMM D, YYYY h:mma') : ''}`;
        row.status = row.lastCommandResponse.status;
        if (row.lastCommandResponse.commandSet) {
          row.userEmail = row.lastCommandResponse.commandSet.user ? row.lastCommandResponse.commandSet.user.email : '';
          row.commandName = row.lastCommandResponse.commandSet.commandName;
          row.sentDate = `${row.lastCommandResponse.commandSet.sentDate ? moment(row.lastCommandResponse.commandSet.sentDate).format('MMMM D, YYYY h:mma') : ''}`;
        }
      }
      return row;
    }), [data]);

  return (
    <DataTable
      data={formattedData}
      title={''}
      columns={columns}
      renderRow={renderRow}
      disableSearch
      defaultSortOrder="asc"
      addButtonLabel="Add Command"
      onAddClick={handleAddClick}
    />
  );
};

LocationsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles)
);

export default prepareForExport(LocationsTable);
