import {
  CREATE_PROMOTION_SLOT_SUCCESS,
  DELETE_PROMOTION_SLOT_SUCCESS,
  GET_PROMOTION_SLOTS_FAILURE,
  GET_PROMOTION_SLOTS_REQUEST,
  GET_PROMOTION_SLOTS_SUCCESS,
  GET_PROMOTION_SLOT_FAILURE,
  GET_PROMOTION_SLOT_REQUEST,
  GET_PROMOTION_SLOT_SUCCESS,
  SET_SELECTED_PROMOTION_SLOT,
  UPDATE_PROMOTION_SLOT_SUCCESS,
} from './promotionSlots.actions';
import { findIndex, isEmpty } from 'lodash';
import Immutable from 'immutable';

const initialState = Immutable.Map({
  promotionSlots: [],
  selectedPromotionSlot: null,
  isLoading: false,
  errors: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROMOTION_SLOTS_SUCCESS: {
      return state.set('promotionSlots', action.response).set('isLoading', false);
    }

    case GET_PROMOTION_SLOTS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_PROMOTION_SLOTS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case CREATE_PROMOTION_SLOT_SUCCESS: {
      let promotionSlots = [...state.get('promotionSlots')];
      let newPromotionSlot = { ...action.response };
      promotionSlots.push(newPromotionSlot);

      return state.set('promotionSlots', promotionSlots).set('isLoading', false);
    }

    case GET_PROMOTION_SLOT_SUCCESS:
      return state.set('selectedPromotionSlot', action.response).set('isLoading', false);

    case GET_PROMOTION_SLOT_FAILURE:
      return state.set('isLoading', false);

    case GET_PROMOTION_SLOT_REQUEST:
      return state.set('isLoading', true);

    case SET_SELECTED_PROMOTION_SLOT:
      return state.set('selectedPromotionSlot', action.response);

    case UPDATE_PROMOTION_SLOT_SUCCESS: {
      let selectedPromotionSlot = { ...state.get('selectedPromotionSlot') };
      let promotionSlots = [...state.get('promotionSlots')];
      let promotionSlotId = action.actionMetadata;

      if (!isEmpty(selectedPromotionSlot) && selectedPromotionSlot.id === promotionSlotId) {
        selectedPromotionSlot = Object.assign(selectedPromotionSlot, action.payload);
      }

      let idx = findIndex(promotionSlots, { id: promotionSlotId });
      if (idx > -1) {
        promotionSlots[idx] = Object.assign(promotionSlots[idx], action.payload);
      }

      return state
        .set('selectedPromotionSlot', selectedPromotionSlot)
        .set('promotionSlots', promotionSlots)
        .set('isLoading', false);
    }

    case DELETE_PROMOTION_SLOT_SUCCESS: {
      let promotionSlots = [...state.get('promotionSlots')];

      let idx = findIndex(promotionSlots, { id: action.actionMetadata.promotionSlotId });
      if (idx > -1) {
        promotionSlots.splice(idx, 1);
      }

      return state.set('promotionSlots', promotionSlots).set('isLoading', false);
    }

    default:
      return state;
  }
}
