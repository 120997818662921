import { Grid, TextField, Typography } from '@mui/material';
import { locationValidator } from '../editLocation.validation';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';


function AddressSection({ selectedLocation, onChange }) {
  const validator = useMemo(() => locationValidator(selectedLocation), [selectedLocation]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Address</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="addressOne"
          variant="outlined"
          label="Address"
          inputProps={{ maxLength: 500 }}
          fullWidth
          value={selectedLocation.addressOne ? selectedLocation.addressOne : ''}
          onChange={onChange}
          helperText={validator.isFieldValid('addressOne') ? '' : 'Address is required'}
          error={!validator.isFieldValid('addressOne')}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Address 2"
          variant="outlined"
          name="addressTwo"
          fullWidth
          value={selectedLocation.addressTwo ? selectedLocation.addressTwo : ''}
          onChange={onChange}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="City"
          variant="outlined"
          name="city"
          fullWidth
          value={selectedLocation.city ? selectedLocation.city : ''}
          onChange={onChange}
          helperText={validator.isFieldValid('city') ? '' : 'City is required'}
          error={!validator.isFieldValid('city')}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="State"
          variant="outlined"
          name="state"
          fullWidth
          value={selectedLocation.state ? selectedLocation.state : ''}
          onChange={onChange}
          helperText={validator.isFieldValid('state') ? '' : 'State is required'}
          error={!validator.isFieldValid('state')}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Zip"
          variant="outlined"
          name="zip"
          fullWidth
          value={selectedLocation.zip ? selectedLocation.zip : ''}
          onChange={onChange}
          helperText={validator.isFieldValid('zip') ? '' : 'Zip is required'}
          error={!validator.isFieldValid('zip')}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Country"
          name="country"
          value={selectedLocation.country || ''}
          variant="outlined"
          disabled
        />      
      </Grid>
    </Grid>
  );
}

AddressSection.propTypes = {
  selectedLocation: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired,
};

export default AddressSection;
