import { 
  GET_SUBMITTED_LOCATIONS_FAILURE,
  GET_SUBMITTED_LOCATIONS_REQUEST,
  GET_SUBMITTED_LOCATIONS_SUCCESS,
  GET_SUBMITTED_PHOTOS_FAILURE,
  GET_SUBMITTED_PHOTOS_REQUEST,
  GET_SUBMITTED_PHOTOS_SUCCESS,
  GET_SUBMITTED_REVIEWS_FAILURE,
  GET_SUBMITTED_REVIEWS_REQUEST,
  GET_SUBMITTED_REVIEWS_SUCCESS,
} from './submissions.actions';
import Immutable from 'immutable';

const initialState = Immutable.Map({
  newLocations: [],
  newPhotos: [],
  newReviews: [],
  isLoadingReviews: false,
  isLoadingPhotos: false,
  isLoadingLocations: false,
  errors: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBMITTED_LOCATIONS_SUCCESS:
      return state
        .set('newLocations', action.response)
        .set('isLoadingLocations', false);

    case GET_SUBMITTED_LOCATIONS_FAILURE:
      return state.set('errors', action.messages).set('isLoadingLocations', false);

    case GET_SUBMITTED_LOCATIONS_REQUEST:
      return state.set('errors', []).set('isLoadingLocations', true);

    case GET_SUBMITTED_REVIEWS_SUCCESS:
      return state
        .set('newReviews', action.response)
        .set('isLoadingReviews', false);

    case GET_SUBMITTED_REVIEWS_FAILURE:
      return state.set('errors', action.messages).set('isLoadingReviews', false);

    case GET_SUBMITTED_REVIEWS_REQUEST:
      return state.set('errors', []).set('isLoadingReviews', true);

    case GET_SUBMITTED_PHOTOS_SUCCESS:
      return state
        .set('newPhotos', action.response)
        .set('isLoadingPhotos', false);

    case GET_SUBMITTED_PHOTOS_FAILURE:
      return state.set('errors', action.messages).set('isLoadingPhotos', false);

    case GET_SUBMITTED_PHOTOS_REQUEST:
      return state.set('errors', []).set('isLoadingPhotos', true);

    default:
      return state;
  }
}
