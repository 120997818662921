import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import withStyles from '@mui/styles/withStyles';

import TtlockConnectionStatsComponent from './ttlockConnectionStats.component';
import Unlocks from './unlocks.container';
import { PermissionCheck } from '../../../auth/permissionCheck.component';
import { PERMISSIONS } from '../../../auth/constants';

const TTLockMonitoringOverview = ({
  classes,
  selectedLocationStatus,
}) => {
  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Card>
            <CardHeader title="TTLock Battery Status" />
            <CardContent>
              <TtlockConnectionStatsComponent
                locationStatus={selectedLocationStatus}
              />
            </CardContent>
          </Card>
        </Grid>
        <PermissionCheck require={PERMISSIONS.EVENTS_READ}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="TTLock Unlock History" />
              <CardContent>
                <Unlocks />
              </CardContent>
            </Card>
          </Grid>
        </PermissionCheck>
      </Grid>
    </div>
  );
};

const styles = () => ({
  container: {
    width: 'calc(100% - 24px)',
    marginTop: 12,
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 'calc(100% - 13rem)',
  },
});

const mapStateToProps = state => {
  return {
    selectedLocationStatus: state.monitoring.get('selectedLocationStatus'),
  };
};

TTLockMonitoringOverview.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedLocationStatus: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
  ),
);

export default prepareForExport(TTLockMonitoringOverview);