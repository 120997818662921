import { Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { DATE_FORMAT } from '../../../utilities/dateUtils';

const ConnectionStat = ({title, subtitle, icon, classes, date, color, datePrefix}) => {
  const StatIcon = icon;
  return (
    <Paper
      className={classes.statContainer} 
      style={{ color, boxShadow: `0px 1px 5px 0px ${color}, 0px 2px 2px 0px ${color}, 0px 3px 1px -2px ${color}` }}
    >
      <div className={classes.statHeader}>
        <div className={classes.icon}>
          <StatIcon fontSize="inherit" /> 
        </div>
        <div className={classes.statTitle}>
          <Typography variant="h6">{title}</Typography>
          {subtitle && (
            <Typography variant="subtitle1">{subtitle}</Typography>
          )}
        </div>
      </div>
      {date && (
        <Typography variant="caption" align="center">{datePrefix ? datePrefix : 'as of'} {moment(date).format(DATE_FORMAT.DATE_TIME)}</Typography>
      )}
    </Paper>
  );
};

const styles = () => ({
  icon: {
    fontSize: 40,
    paddingRight: 8,
  },
  statContainer: {
    flex: 1,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  statHeader: {
    display: 'flex',
  },
  statTitle: {
    marginTop: 4,
  },
});

ConnectionStat.defaultProps = {
  date: '',
  subtitle: '',
  color: '',
};

ConnectionStat.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.func.isRequired,
  date: PropTypes.string,
  datePrefix: PropTypes.string,
  color: PropTypes.string,
};

const prepareForExport = compose(
  withStyles(styles)
);

export default prepareForExport(ConnectionStat);