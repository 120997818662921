import { CALL_API } from '../../middleware/api';

export const GET_PROMOTION_SLOTS_REQUEST = 'GET_PROMOTION_SLOTS_REQUEST';
export const GET_PROMOTION_SLOTS_SUCCESS = 'GET_PROMOTION_SLOTS_SUCCESS';
export const GET_PROMOTION_SLOTS_FAILURE = 'GET_PROMOTION_SLOTS_FAILURE';

export const CREATE_PROMOTION_SLOT_REQUEST = 'CREATE_PROMOTION_SLOT_REQUEST';
export const CREATE_PROMOTION_SLOT_SUCCESS = 'CREATE_PROMOTION_SLOT_SUCCESS';
export const CREATE_PROMOTION_SLOT_FAILURE = 'CREATE_PROMOTION_SLOT_FAILURE';

export const GET_PROMOTION_SLOT_REQUEST = 'GET_PROMOTION_SLOT_REQEUST';
export const GET_PROMOTION_SLOT_SUCCESS = 'GET_PROMOTION_SLOT_SUCCESS';
export const GET_PROMOTION_SLOT_FAILURE = 'GET_PROMOTION_SLOT_FAILURE';

export const SET_SELECTED_PROMOTION_SLOT = 'SET_SELECTED_PROMOTION_SLOT';

export const UPDATE_PROMOTION_SLOT_REQUEST = 'UPDATE_PROMOTION_SLOT_REQUEST';
export const UPDATE_PROMOTION_SLOT_SUCCESS = 'UPDATE_PROMOTION_SLOT_SUCCES';
export const UPDATE_PROMOTION_SLOT_FAILURE = 'UPDATE_PROMOTION_SLOT_FAILURE';

export const DELETE_PROMOTION_SLOT_REQUEST = 'DELETE_PROMOTION_SLOT_REQUEST';
export const DELETE_PROMOTION_SLOT_SUCCESS = 'DELETE_PROMOTION_SLOT_SUCCESS';
export const DELETE_PROMOTION_SLOT_FAILURE = 'DELETE_PROMOTION_SLOT_FAILURE';

export const PROMOTION_SLOT_TEXT_FIELDS = [
  { name: 'id', label: 'ID' }, // This is only editable on create, not edit, but since we're not implementing edit ATM this is ok
  { name: 'name', label: 'Name' },
];

export const getPromotionSlots = () => {
  return {
    [CALL_API]: {
      types: [GET_PROMOTION_SLOTS_REQUEST, GET_PROMOTION_SLOTS_SUCCESS, GET_PROMOTION_SLOTS_FAILURE],
      authenticated: true,
      endpoint: 'promotionSlots',
      method: 'GET',
    },
  };
};

const prepare = (promotionSlot) => {
  return promotionSlot;
};

export const createPromotionSlot = (promotionSlotData) => {
  return {
    [CALL_API]: {
      types: [CREATE_PROMOTION_SLOT_REQUEST, CREATE_PROMOTION_SLOT_SUCCESS, CREATE_PROMOTION_SLOT_FAILURE],
      authenticated: true,
      endpoint: 'promotionSlots',
      method: 'POST',
      payload: prepare(promotionSlotData),
    },
  };
};

export const getPromotionSlot = (promotionSlotId) => {
  return {
    [CALL_API]: {
      types: [GET_PROMOTION_SLOT_REQUEST, GET_PROMOTION_SLOT_SUCCESS, GET_PROMOTION_SLOT_FAILURE],
      authenticated: true,
      endpoint: `promotionSlots/${promotionSlotId}`,
      method: 'GET',
      actionMetadata: {
        promotionSlotId,
      },
    },
  };
};

export const setSelectedPromotionSlot = (promotionSlot) => {
  return {
    type: SET_SELECTED_PROMOTION_SLOT,
    response: promotionSlot,
  };
};

export const updatePromotionSlot = (promotionSlotId, promotionSlotData) => {
  return {
    [CALL_API]: {
      types: [UPDATE_PROMOTION_SLOT_REQUEST, UPDATE_PROMOTION_SLOT_SUCCESS, UPDATE_PROMOTION_SLOT_FAILURE],
      authenticated: true,
      endpoint: `promotionSlots/${promotionSlotId}`,
      method: 'PUT',
      payload: prepare(promotionSlotData),
      actionMetadata: {
        promotionSlotId,
      },
    },
  };
};

export const deletePromotionSlot = (promotionSlot) => {
  return {
    [CALL_API]: {
      types: [DELETE_PROMOTION_SLOT_REQUEST, DELETE_PROMOTION_SLOT_SUCCESS, DELETE_PROMOTION_SLOT_FAILURE],
      authenticated: true,
      endpoint: `promotionSlots/${promotionSlot.id}`,
      method: 'DELETE',
      actionMetadata: {
        promotionSlotId: promotionSlot.id,
      },
    },
  };
};
