import { CircularProgress, Grid } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { getLocationBootups } from '../monitoring.actions';
import { getUTCDateRange } from '../../../utilities/dateUtils';
import BootupsTable from './bootupsTable.component';
import DateRangePicker from '../../layout/dateRangePicker.component';

const BootupsComp = ({
  getLocationBootups,
  isLoadingBootups,
  selectedLocationBootups,
  selectedLocation,
}) => {

  const handleUpdateRange = ({fromDate, toDate}) => {
    const utcDateRange = getUTCDateRange(fromDate, toDate);
    getLocationBootups(
      selectedLocation.id,
      utcDateRange.fromDate,
      utcDateRange.toDate
    );
  };

  return (
    <Fragment>
      <DateRangePicker defaultRange={30} handleUpdateRange={handleUpdateRange} />
      {isLoadingBootups && (
        <Grid container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!isLoadingBootups && (
        <BootupsTable bootups={selectedLocationBootups} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedLocation: state.location.get('selectedLocation'),
    isLoadingBootups: state.monitoring.get('isLoadingBootups'),
    selectedLocationBootups: state.monitoring.get('selectedLocationBootups'),
  };
};

BootupsComp.propTypes = {
  isLoadingBootups: PropTypes.bool.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  selectedLocationBootups: PropTypes.array.isRequired,

  getLocationBootups: PropTypes.func.isRequired,
};


const prepareForExport = compose(
  connect(
    mapStateToProps,
    {
      getLocationBootups,
    },
  ),
);

const Bootups = prepareForExport(BootupsComp);

export default Bootups;