import { isEmpty, memoize } from 'lodash';

export const DEFAULT_LOCATION = {
  latitude: 44.4732219,
  longitude: -73.21788600000002,
};

export const waitForGoogleMaps = memoize(() => {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (window.google) {
        resolve(true);
        clearInterval(interval);
      }
    }, 100);
  });
});

export const parseAddress = (address) => {
  const locationAddress = {
    addressOne: '',
    addressTwo: '',
    city: '',
    state: '',
    zip: '',
  };
  let addressObject = {};
  if (address && address.address_components) {
    address.address_components.map((component) => {
      addressObject[component.types[0]] = {};
      addressObject[component.types[0]].long_name = component.long_name;
      addressObject[component.types[0]].shortName = component.short_name;
      return addressObject;
    });
    if (!isEmpty(addressObject)) {

      // Address One
      if (addressObject.street_number && addressObject.street_number.long_name) {
        locationAddress.addressOne = `${addressObject.street_number.long_name} ${addressObject.route.shortName}`;
      }

      // Address Two
      if (addressObject.subpremise && addressObject.subpremise.long_name) {
        locationAddress.addressTwo = addressObject.subpremise.long_name;
      }

      // City
      if (addressObject.locality && addressObject.locality.long_name) {
        locationAddress.city = addressObject.locality.long_name;
      }

      // State
      if (addressObject.administrative_area_level_1.shortName) {
        locationAddress.state = addressObject.administrative_area_level_1.shortName;
      }

      // Zip
      if (addressObject.postal_code && addressObject.postal_code.long_name) {
        locationAddress.zip = addressObject.postal_code.long_name;
      }

      // Country
      if (addressObject.country.shortName) {
        locationAddress.country = addressObject.country.shortName;
      }
    }
  }
  return locationAddress;
};
