import { Snackbar } from '@mui/material';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { hideToast } from './layout.actions';

class Toast extends Component {
  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.props.isShowToast}
          onClose={this.props.hideToast}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.props.toastMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.hideToast}
              size="large">
              <CloseIcon onClick={this.hideToast} />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

Toast.propTypes = {
  isShowToast: PropTypes.bool.isRequired,
  toastMessage: PropTypes.string.isRequired,
  isToastFailure: PropTypes.bool,
  toastPosition: PropTypes.string,
  responsiveBreakpoint: PropTypes.object.isRequired,

  hideToast: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isShowToast: state.layout.get('isShowToast'),
    toastMessage: state.layout.get('toastMessage'),
    isToastFailure: state.layout.get('isToastFailure'),
    toastPosition: state.layout.get('toastPosition'),
    responsiveBreakpoint: state.layout.get('responsiveBreakpoint'),
  };
};

export default connect(
  mapStateToProps,
  {
    hideToast,
  }
)(Toast);
