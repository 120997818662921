import { Card, CardContent, CardHeader } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';

import ButtonGroup from '../../layout/buttonGroup.component';
import MapChart from '../../layout/charts/mapChart.component';

const MapCardComponent = ({title, data, color, onClick, isLoading, classes, selectedItem, setSelectedItem, buttons, getPointLabel}) => {

  return (
    <Card className={classes.card}>
      <CardHeader
        title={title}
        classes={{title: classes.cardTitle}}
      />
      <div className={classes.buttonGroup}>
        <ButtonGroup
          buttons={buttons}
          exclusive={true}
          handleSelectButton={(item) => setSelectedItem(item)}
          selectedButton={selectedItem}
          size="small"
        />
      </div>
      <CardContent>
        <MapChart 
          data={data}
          isLoading={isLoading}
          color={color}
          onClick={onClick}
          getPointLabel={getPointLabel}
        />
      </CardContent>
    </Card>
  );
};

const styles = () => ({
  card: {
    position: 'relative',
    flex: 1,
  },
  cardTitle: {
    fontSize: '1.2rem',
    lineHeight: 1,
  },
  buttonGroup: {
    position: 'absolute',
    top: 8,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    right: 0,
  },
});

MapCardComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  selectedItem: PropTypes.string,
  buttons: PropTypes.array,
  data: PropTypes.array.isRequired,
  
  setSelectedItem: PropTypes.func,
  onClick: PropTypes.func,
  getPointLabel: PropTypes.func,
};

const prepareForExport = compose(
  withStyles(styles),
);

const MapCard = prepareForExport(MapCardComponent);

export default MapCard;