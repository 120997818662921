import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Fab, MenuItem, Select, Typography } from '@mui/material';
import { filter } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import DataTableHead from './dataTableHead.component';
import DownloadIcon from '@mui/icons-material/GetApp';
import LoadingOverlay from '../loadingOverlay/loadingOverlay.component';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  table: {
    backgroundColor: 'transparent !important',
  },
  tableContainer: {
    overflow: 'auto',
    flex: '1',
  },
  addButton: {
    margin: theme.spacing(1),
    position: 'absolute',
    bottom: 10,
    right: 10,
    fontSize: '8px',
    lineHeight: 'normal',
  },
  pagination: {
    marginRight: '100px',
  },
  filterInput: {
    fontSize: '0.75rem',
  },
  filterTextInput: {
    width: 30,
  },
});

const desc = (a, b, orderBy, nilAtBeginning) => {
  if (isNil(b[orderBy]) || b[orderBy] === '') {
    if (isNil(a[orderBy]) || a[orderBy] === '') {
      return 0;
    } else {
      return nilAtBeginning ? 1 : -1;
    }
  }
  if (isNil(a[orderBy]) || a[orderBy] === '') {
    return nilAtBeginning ? -1 : 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy, true);
};

const filterData = (array, dataFilters) => {
  const filters = { ...dataFilters };

  //Strip out empty strings from the filter list before applying
  Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);

  return filter(array, (obj) => {
    let matchesFilter = true;
    Object.keys(filters).forEach((key) => {
      if (
        !obj[key] ||
        !obj[key]
          .toString() //Convert numbers/dates/etc to strings
          .toLowerCase()
          .includes(filters[key].toLowerCase())
      ) {
        matchesFilter = false;
      }
    });
    return matchesFilter;
  });
};

const DataTable = ({
  defaultSortOrder,
  defaultSortField,
  defaultFilters,
  data,
  classes,
  columns,
  renderRow,
  isLoading,
  disableFilters,
  isAllSelected,
  addButtonLabel,

  onDataChanged,
  onPagingChanged,
  onSelectAll,
  onAddClick,
  onExportClick,
}) => {

  const [order, setOrder] = useState(defaultSortOrder ? defaultSortOrder : 'desc');
  const [orderBy, setOrderBy] = useState(defaultSortField ? defaultSortField : 'date');
  const [tableData, setTableData] = useState(data && data.length > 0 ? data : []);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({});
  
  useEffect(() => {
    setFilters(columns ? 
      columns.reduce((initFilters, column) => {
        const filterId = column.filterId ? column.filterId : column.id;

        if (defaultFilters && filterId in defaultFilters) {
          initFilters[filterId] = defaultFilters[filterId];
        } else {
          initFilters[filterId] = '';
        }

        return initFilters;
      }, {}) : {});
  }, [columns, defaultFilters]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleDataChanged = () => {
    if (onDataChanged) onDataChanged(tableData);
  };

  const handlePagingChanged = () => {
    if (onPagingChanged) onPagingChanged();
    handleDataChanged();
  };

  const handleRequestSort = (event, property) => {
    const orderBy = property;
    let newOrder = 'desc';

    if (orderBy === property && order === 'desc') {
      newOrder = 'asc';
    }

    setOrder(newOrder);
    setOrderBy(orderBy);
  };

  const handleFilterChange = (name) => (event) => {
    const newFilters = {...filters};
    newFilters[name] = event.target.value;
    setFilters(newFilters);
    handleDataChanged();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handlePagingChanged();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    handlePagingChanged(tableData);
  };

  const filteredData = filterData(tableData, filters);

  // TODO: This stuff should be memoized
  const paginatedData = stableSort(filteredData, getSorting(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (isLoading === true) {
    return <LoadingOverlay />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <DataTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={tableData.length}
            columns={columns}
            onAddClick={onAddClick}
            onSelectAll={onSelectAll ? (newIsSelected) => onSelectAll(newIsSelected, paginatedData) : null}
            isAllSelected={isAllSelected}
          />
          <TableBody>
            {/* FILTERS ROW */}
            {!disableFilters &&
              <TableRow>
                {onSelectAll && (
                  <TableCell padding="none"/>
                )}
                {columns.map((column) => {
                  const filterId = column.filterId ? column.filterId : column.id;
                  return (
                    <TableCell key={column.id} padding={column.padding}>
                      {column.filtering && !column.filterOptions &&
                        <TextField
                          id={column.id}
                          value={filters[filterId] ? filters[filterId] : ''}
                          onChange={handleFilterChange(filterId)}
                          label=""
                          autoComplete="new-password" //Needed to turn off autoComplete
                          inputProps={{
                            className: classNames(classes.filterInput, classes.filterTextInput),
                          }}
                          variant='standard'
                          fullWidth
                        />
                      }
                      {column.filtering && column.filterOptions &&
                        <Select
                          id={column.id}
                          value={filters[filterId] ? filters[filterId] : ''}
                          onChange={handleFilterChange(filterId)}
                          inputProps={{
                            className: classes.filterInput,
                          }}
                        >
                          {column.filterOptions.map((option) => (
                            <MenuItem value={option} key={option}>{option}</MenuItem>
                          ))}
                          <MenuItem value="">Clear Filter</MenuItem>
                        </Select>
                      }
                    </TableCell>
                  );
                })}
              </TableRow>
            }

            {tableData && tableData.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Typography>{'No results available.'} </Typography>
                </TableCell>
              </TableRow>
            )}

            {tableData && tableData.length !== 0 && filteredData && filteredData.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Typography>{'No results available with current filters.'} </Typography>
                </TableCell>
              </TableRow>
            )}

            {paginatedData.map((n, i) => renderRow(n, i))}
          </TableBody>
        </Table>
        
      </div>

      {filteredData && filteredData.length > 0 && (
        <div className={classes.pagination}>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          
          />
        </div>
      )}

      {onAddClick && (
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.addButton}
          onClick={onAddClick}
        >
          {addButtonLabel ? addButtonLabel : <AddIcon />}
        </Fab>
      )}

      {onExportClick && (
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.addButton}
          onClick={onExportClick(filteredData)}
        >
          <DownloadIcon />
        </Fab>
      )}
    </div>
  );

};

DataTable.defaultProps = {
  disableFilters: false,
};

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.any.isRequired,
  onRowClick: PropTypes.func,
  columns: PropTypes.array.isRequired,
  renderRow: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func, // callback when sort/page/filter is updated
  defaultSortOrder: PropTypes.string,
  defaultSortField: PropTypes.string,
  defaultFilters: PropTypes.object,
  onPagingChanged: PropTypes.func,
  onAddClick: PropTypes.func, //callback when the add button is clicked
  onExportClick: PropTypes.func, //callback when the export button is clicked
  addButtonLabel: PropTypes.string,
  disableFilters: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelectAll: PropTypes.func,
  isAllSelected: PropTypes.bool,
};

export default withStyles(styles)(DataTable);
