import { getDownloadURL, getStorage, ref, deleteObject, uploadBytes } from 'firebase/storage';

export const getMediaRefFull = fullPath => {
  let storage = getStorage();
  return ref(storage, fullPath);
};

// Cache
const CACHE_TTL = 5 * 60 * 1000; // 5 Minutes
const cache = {};
const promises = {};

export const getMedia = (mediaPath, defaultRef) => {
  if (promises[mediaPath]) {
    return promises[mediaPath];
  }

  if (cache[mediaPath]) {
    if (cache[mediaPath].timestamp + CACHE_TTL > Date.now()) {
      // It has not expired, so use the existing value!
      let val = cache[mediaPath];
      return Promise.resolve(val.url);
    }

    // It has expired, so delete our cached object
    delete cache[mediaPath];
    // Re-fetch!
  }

  const promise = new Promise((resolve, reject) => {
    const doReject = () => {
      // If we have a defaultRef, resolve with that
      if (defaultRef) {
        cache[mediaPath] = {
          timestamp: Date.now(),
          url: defaultRef,
        };
        resolve(defaultRef);
      } else {
        cache[mediaPath] = {
          timestamp: Date.now(),
          url: null,
        };
        resolve();
      }
    };

    const mediaRef = getMediaRefFull(mediaPath);
    return getDownloadURL(mediaRef).then((downloadURL) => {
        delete promises[mediaPath];
        cache[mediaPath] = {
          timestamp: Date.now(),
          url: downloadURL,
        };
        resolve(downloadURL);
      }, doReject);
  });
  promises[mediaPath] = promise;
  return promise;
};

/* This enables uploading media using JS File objects for the web */
export const uploadMediaAsFile = (fileData, mediaPath) => {
  return new Promise(async (resolve) => {
    const mediaRef = getMediaRefFull(mediaPath);

    try {
      await uploadBytes(mediaRef, fileData);
      await mediaRef.put(fileData);
      delete promises[mediaPath];
      const url = await getDownloadURL(mediaRef);
      cache[mediaPath] = url;
      resolve({
        imageRef: mediaRef.fullPath,
        resolvedImageRef: url,
      });
    } catch (e) {
      console.log('ERROR WITH FIREBASE FILE UPLOAD', e);
    }
  });
};

export const removeMedia = async (mediaPath) => {
  const mediaRef = getMediaRefFull(mediaPath);
  return deleteObject(mediaRef);
};
