import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';

const INPUT_TYPE_SELECT = 'select';
const INPUT_TYPE_NUMBER = 'number';

const PayloadOptions = ({options, handleValueChange, commandPayload, errors}) => {

  const handleInputChange = useCallback(
    (option) => (e) => {
      const updatedPayload = {...commandPayload};
      const newValue = e.target.value;
      if (newValue === undefined || newValue === '') {
        delete updatedPayload[option.key];
      } else {
        if (option.inputType === INPUT_TYPE_NUMBER) {
          updatedPayload[option.key] = parseInt(newValue, 10);
        } else {
          updatedPayload[option.key] = newValue;
        }
      }
      handleValueChange(updatedPayload);
    }, [commandPayload, handleValueChange]);

  return (
    <Fragment>
      <Typography variant="subtitle1">
        Payload Options
      </Typography>
      <Grid container spacing={2}>
        {options.map((option, i) => (
          <Grid item xs={12} key={option.key}>
            {option.inputType === INPUT_TYPE_SELECT ? (
              <TextField 
                fullWidth
                select
                label={option.name}              
                value={(commandPayload && commandPayload[option.key] !== undefined) ? commandPayload[option.key] : ''}
                onChange={handleInputChange(option)}
              >
                {[
                  <MenuItem
                    key="clear-option"
                    value=""
                  >(Clear Option)</MenuItem>,
                  option.selectOptions.map((selectOption) => (
                    <MenuItem
                      key={selectOption.value}
                      value={selectOption.value}
                    >{selectOption.label}</MenuItem>
                  )),
                ]}

              </TextField>
            ) : (
              <TextField 
                fullWidth
                error={errors[i].hasError}
                helperText={errors[i].errorMessage}
                label={option.name}
                type={option.inputType}
                value={(commandPayload && commandPayload[option.key] !== undefined) ? commandPayload[option.key] : ''}
                onChange={handleInputChange(option)}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

PayloadOptions.propTypes = {
  options: PropTypes.array.isRequired,
  commandPayload: PropTypes.object.isRequired,
  
  handleValueChange: PropTypes.func.isRequired,

  errors: PropTypes.array.isRequired,
};

export default PayloadOptions;
