import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { EMPTY_COMMAND_INDEX, setCommandPayload, setCustomCommand, setDescription, setSelectedCommandIndex } from '../iotCommand.actions';
import { selectCommandPayloadErrors, selectCustomCommandError, selectGeneratedCommand, selectSelectedCommand } from '../iotCommand.selectors';
import CommandFormBasics from './commandFormBasics.component';
import GeneratedCommandInput from './generatedCommandInput.component';
import PayloadOptions from './payloadOptions.component';

const CommandForm = ({ 
  classes,
  commandConfig,
  description,
  selectedCommandIndex,
  commandPayload,
  selectedCommand,
  generatedCommand,
  setDescription,
  setSelectedCommandIndex,
  setCommandPayload,
  setCustomCommand,
  customCommandError,
  payloadErrors,
}) => (
  <div className={classes.stepWrapper}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1">
          Enter in a description for the set of commands you are running and select the command you want to run on the pods you selected. You will have a chance to review the selected pods and command before it is run.
        </Typography>
      </Grid>

      <Grid
        item
        container
        sm={12}
        md={7}
        spacing={2}
      >

        <CommandFormBasics
          description={description}
          commandConfig={commandConfig}
          selectedCommandIndex={selectedCommandIndex}
          setDescription={setDescription}
          setSelectedCommandIndex={(newValue) => {
            setSelectedCommandIndex(newValue);
            setCommandPayload({});
          }}
        />

        {selectedCommand && selectedCommand.payloadOptions && (
          <Grid item xs={12}>
            <PayloadOptions
              options={selectedCommand.payloadOptions}
              handleValueChange={setCommandPayload}
              commandPayload={commandPayload}
              errors={payloadErrors}
            />
          </Grid>
        )}

        {selectedCommandIndex !== EMPTY_COMMAND_INDEX && (
          <Grid item xs={12}>
            <GeneratedCommandInput
              generatedCommand={generatedCommand}
              customCommandError={customCommandError}
              selectedCommandIndex={selectedCommandIndex}
              setCustomCommand={setCustomCommand}
            />
          </Grid>
        )}

      </Grid>
    </Grid>

  </div>
);

const styles = () => ({
  stepWrapper: {
    marginLeft: 24,
    marginRight: 24,
  },
  generatedCommandInput: {
    fontFamily: 'monospace',
  },
});

CommandForm.defaultProps = {
  description: '',
  payloadErrors: [],
  selectedCommand: null,
};

CommandForm.propTypes = {
  classes: PropTypes.object.isRequired,

  commandConfig: PropTypes.array.isRequired,

  description: PropTypes.string,
  selectedCommandIndex: PropTypes.number.isRequired,
  commandPayload: PropTypes.object.isRequired,
  selectedCommand: PropTypes.object,
  generatedCommand: PropTypes.string.isRequired,
  
  setDescription: PropTypes.func.isRequired,
  setSelectedCommandIndex: PropTypes.func.isRequired,
  setCommandPayload: PropTypes.func.isRequired,
  setCustomCommand: PropTypes.func.isRequired,
  
  customCommandError: PropTypes.string.isRequired,
  payloadErrors: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    commandConfig: state.iotCommand.get('commandConfig'),
    description: state.iotCommand.get('description'),
    selectedCommandIndex: state.iotCommand.get('selectedCommandIndex'),
    commandPayload: state.iotCommand.get('commandPayload'),
    selectedCommand: selectSelectedCommand(state),
    generatedCommand: selectGeneratedCommand(state),
    customCommandError: selectCustomCommandError(state),
    payloadErrors: selectCommandPayloadErrors(state),
  };
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    {
      setDescription,
      setSelectedCommandIndex,
      setCommandPayload,
      setCustomCommand,
    }
  )
);

export default prepareForExport(CommandForm);
