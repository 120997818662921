import { Grid, Typography } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getLocationPreview } from '../hubspot/hubspot.actions';
import { setPageTitle } from '../layout/layout.actions';
import Colors from '../../styles/colors';
import EditLocation from './editLocation.component';

class LocationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLocation: {},
    };
  }

  async componentDidMount() {
    this.props.setPageTitle('Location');

    await this.props.getLocationPreview(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !isEmpty(nextProps.selectedLocation) &&
      (isEmpty(this.state.selectedLocation) || nextProps.selectedLocation.id !== this.state.selectedLocation.id)
    ) {
      this.props.setPageTitle(`Hubspot Preview: ${nextProps.selectedLocation.name}`, '/locations', 'LOCATION LIST');
    }
  }

  render() {
    let { classes, isLoading } = this.props;
    let { selectedLocation } = this.state;
    if (!isEmpty(selectedLocation)) {
      return (
        <div id="selectionLocation" className={classes.root}>
          {this.props.isLoading && <div className="loader" />}
          {!isLoading && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <EditLocation
                      selectedLocation={this.state.selectedLocation}
                      isHubspotPreviewMode={true}
                      handleEdit={() => {}}
                      handleMapUpdate={() => {}}
                      googlePlace={{}}
                      clearGooglePlace={() => {}}
                      placeLookup={() => {}}
                      handleChange={() => {}}
                      updatePodSoftware={() => {}}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      );
    }

    if (isLoading && isEmpty(this.state.selectedLocation) && isEmpty(this.props.selectedLocation)) {
      return <div className="loader" />;
    }

    if (!isLoading && isEmpty(this.state.selectedLocation) && isEmpty(this.props.selectedLocation)) {
      return (
        <div className={classes.notFoundMessage}>
          <Typography variant="h5">Could not find that location.</Typography>
        </div>
      );
    }

    return null;
  }
}

LocationContainer.propTypes = {
  selectedLocation: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  getLocationPreview: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,

  // Injected by React Router
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedLocation: state.hubspot.get('locationPreview'),
    isLoading: state.hubspot.get('isLoadingLocationPreview'),
  };
};

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: Colors.primary.background,
    overflowY: 'scroll',
    display: 'flex',
    flex: 1,
  },
  previewWrapper: {
    flex: 1,
    display: 'flex',
  },
  notFoundMessage: {
    textAlign: 'center',
    marginTop: '2rem',
  },
});

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(
    mapStateToProps,
    {
      setPageTitle,
      getLocationPreview,
    }
  ),
);

export default prepareForExport(LocationContainer);
