import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../index.reducer';

// TODO: Configure store with getAccessTokenSilently
const configureStore = (api) => (preloadedState) => createStore(
  rootReducer,
  preloadedState,
  applyMiddleware(thunk, api)
);

export default configureStore;