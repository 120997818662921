import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import api from '../middleware/api';
import configureStore from '../store/configureStore';

// Exposes getAccessTokenSilently to the api linked to this store
export default function useAuth0Store() {
  const [store, setStore] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setStore(configureStore(api(getAccessTokenSilently))());
  }, [getAccessTokenSilently]);
  
  return store;
}