import { Chip, Grid, Typography } from '@mui/material';
import { SearchCriteriaPropertyNameToLabel } from './locationSearch.component';
import { useSelector } from 'react-redux';
import React from 'react';

export const LocationSearchCriterias = () => {
  const searchState = useSelector((state) => state.locations.get('searchFilters'));

  const hasFilterValues = !Object.values(searchState).every((val)=> !val);

  return hasFilterValues ? (
    <Grid container style={{rowGap: 10}}> 
      <Typography variant="h6">Current filters:</Typography>
      <Grid container item spacing={2}>
        {Object.entries(searchState)
          .filter(([_, value]) => !!value)
          .map(([key, value]) => {
            const label = `${SearchCriteriaPropertyNameToLabel[key] || key}: ${value}`;
            return (<Grid item key={key} ><Chip label={label} /></Grid>);
          })}
      </Grid>
      
    </Grid>) : null;
};