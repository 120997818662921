
import { CUSTOM_COMMAND_INDEX, EMPTY_COMMAND_INDEX } from '../iotCommand.actions';
import { TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';

import PropTypes from 'prop-types';
import React from 'react';

const GeneratedCommandInput = ({
  classes,
  readonly,
  generatedCommand,
  selectedCommandIndex,
  customCommandError,
  setCustomCommand,
}) => (
  <TextField
    multiline={true}
    error={customCommandError !== ''}
    margin="normal"
    label="Command to be sent"
    fullWidth
    value={generatedCommand}
    disabled={readonly || selectedCommandIndex !== CUSTOM_COMMAND_INDEX}
    InputProps={{ inputProps: { className: classes.generatedCommandInput } }}
    onChange={(e) => setCustomCommand(e.target.value)}
    helperText={customCommandError}
  />
);

GeneratedCommandInput.defaultProps = {
  readonly: false,
  selectedCommandIndex: EMPTY_COMMAND_INDEX,
  customCommandError: '',
  setCustomCommand: () => {},
};

GeneratedCommandInput.propTypes = {
  classes: PropTypes.object.isRequired,

  generatedCommand: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  selectedCommandIndex: PropTypes.number,
  customCommandError: PropTypes.string,

  setCustomCommand: PropTypes.func,
};

const styles = () => ({
  generatedCommandInput: {
    fontFamily: 'monospace',
  },
});

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
);

export default prepareForExport(GeneratedCommandInput);