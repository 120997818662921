import { Badge, Button, Card, CardActions, CardContent, CardMedia, Fab, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import moment from 'moment';

class PhotoCard extends Component {
  render() {
    const {
      classes,
      selectedLocation,
      imageLoading,
      moveImageUp,
      removeImage,
      moveImageDown,
      denyImage,
      approveImage,
      addImage,
      acceptType,
    } = this.props;
    let imageCount = 0;
    return (
      <div className={classes.photoCardContainer}>
        <Card className={classes.imageCard}>
          <CardContent>
            <Typography variant="h6">Location Photos</Typography>
            <div className={classes.imageListWrapper}>
              <div className={classes.imageListWrapper}>
                {imageLoading ? (
                  <div className="loader" />
                ) : (
                  <Fragment>
                    {selectedLocation.locationPhotos &&
                      selectedLocation.locationPhotos.map((locationPhoto, key) => {
                        const url = locationPhoto.url;
                        if (url) {
                          imageCount = imageCount + 1;
                          return (
                            <div key={`photo_${key}`}>
                              <Badge classes={{ badge: this.props.classes.badge }} badgeContent={key + 1} color="primary">
                                <Card className={classes.imageItem}>
                                  <CardMedia component="img" className={classes.media} height="140"
                                    src={url} title="Lactation Space Image"
                                  />

                                  {locationPhoto.status === 'SUBMITTED' && (
                                    <div className={classes.submittedImageInfo}>
                                      <span>
                                        {locationPhoto.user ? `${locationPhoto.user.firstName} ${locationPhoto.user.lastName}` : ''}
                                      </span>
                                      <span>{moment(locationPhoto.createdDate).format('LL')}</span>
                                    </div>
                                  )}
                                  <CardActions className={classes.imageItemActions}>
                                    {locationPhoto.status === 'APPROVED' && (
                                      <Fragment>
                                        <IconButton
                                          size="small"
                                          color="primary"
                                          disabled={key < 1}
                                          onClick={() => {
                                            moveImageUp(locationPhoto.id);
                                          }}
                                        >
                                          <ArrowBackIcon />
                                        </IconButton>
                                        <IconButton
                                          color="primary"
                                          onClick={() => {
                                            removeImage(locationPhoto.id);
                                          }}
                                          size="large">
                                          <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                          color="primary"
                                          disabled={key === selectedLocation.locationPhotos.length - 1}
                                          onClick={() => {
                                            moveImageDown(locationPhoto.id);
                                          }}
                                          size="large">
                                          <ArrowForwardIcon />
                                        </IconButton>
                                      </Fragment>
                                    )}
                                    {locationPhoto.status === 'SUBMITTED' && (
                                      <Fragment>
                                        <Button
                                          size="small"
                                          color="primary"
                                          onClick={() => {
                                            denyImage(locationPhoto.id);
                                          }}
                                        >
                                          Deny
                                        </Button>
                                        <Button
                                          size="small"
                                          color="primary"
                                          onClick={() => {
                                            approveImage(locationPhoto.id);
                                          }}
                                        >
                                          Approve
                                        </Button>
                                      </Fragment>
                                    )}
                                    {locationPhoto.status === 'REJECTED' && <span>(rejected)</span>}
                                  </CardActions>
                                </Card>
                              </Badge>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </Fragment>
                )}
              </div>
              <div className={classes.addImageWrapper}>
                <Tooltip title="Add a new photo">
                  <Fab
                    color="primary"
                    aria-label="Add"
                    className={classes.button}
                    onClick={() => {
                      this.upload.click();
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </div>
              <input
                id="myInput"
                type="file"
                accept={acceptType}
                ref={(ref) => (this.upload = ref)}
                className={classes.addImageInput}
                onChange={(e) => addImage(e)}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const styles = (theme) => ({
  photoCardContainer: {
    width: 'calc(100vw - 74px)',
    overflow: 'scroll',
  },
  button: {
    margin: theme.spacing(1),
  },
  badge: {
    top: 0,
    right: 0,
    // The border color match the background color.
    border: `2px solid ${theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`,
  },
  imageCard: {
    marginTop: 0,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    flex: 2,
    flexBasis: 50,
    minHeight: 225,
    overflow: 'auto',
  },
  imageListWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  imageItem: {
    margin: 10,
    width: 200,
  },
  imageItemActions: {
    position: 'sticky',
    backgroundColor: 'gray',
    justifyContent: 'center',
  },
  submittedImageInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: 'gray',
    fontSize: 8,
    color: 'white',
    padding: '3px',
  },
  addImageWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  addImageInput: {
    display: 'none',
  },
  media: {
    objectFit: 'cover',
  },
});

PhotoCard.propTypes = {
  selectedLocation: PropTypes.object,

  imageLoading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  moveImageUp: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  moveImageDown: PropTypes.func.isRequired,
  denyImage: PropTypes.func.isRequired,
  approveImage: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  acceptType: PropTypes.string,
};

PhotoCard.defaultProps = {
  acceptType: null,
};

export default withStyles(styles, { withTheme: true })(PhotoCard);
