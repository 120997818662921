import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function InstalledSection({onChange, selectedLocation}) {
  return (
    <TextField
      name={'podBuildDate'}
      label="Pod Installation Date"
      inputProps={{ maxLength: 120 }}
      fullWidth
      value={selectedLocation.podBuildDate || ''}
      onChange={onChange}
      variant="outlined"
    />
  );
}

InstalledSection.propTypes = {
  selectedLocation: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InstalledSection;