import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';

import BootupStats from './bootupStats.component';
import Bootups from './bootups.container';

const DebugComponent = ({
  classes,
}) => {

  return (
    <div className={classes.container}>
      
      <Grid container spacing={2}>

        <Grid item xs={4} className={classes.cardWrapper}>
          <BootupStats />       
        </Grid>


        <Grid
          item 
          xs={8}
        >
          <Card>
            <CardHeader title="Bootups" />
            <CardContent>
              <Bootups />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </div>
  );
};

const styles = () => ({
  container: {
    width: 'calc(100% - 24px)',
    marginTop: 12,
    marginBottom: 12,
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 'calc(100% - 13rem)',
  },
  cardWrapper: {
    display: 'flex',
  },
});

DebugComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles),
);

const MonitoringDebug = prepareForExport(DebugComponent);

export default MonitoringDebug;