export const celsiusToFahrenheit = (temp) => temp * (9 / 5) + 32;

export const roundToDecimals = (value, decimals = 0) => 
  Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);

export const roundUpToNextMultipleOfX = (value, x) =>
  Math.ceil((value) / x) * x;

export const convertMacId = (podHardwareIdentifier) => {
  return podHardwareIdentifier ? `pcdv1_${podHardwareIdentifier.replace(/:/g, '').toLowerCase()}` : '';
};