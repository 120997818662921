import * as React from 'react';
import { Button, FormControlLabel, Select, Switch, TextField } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * Component used to automatically apply logic to input childrens.
 * @param {string} disableAllChildren - If true, will put all child inputs as disabled.
 * @param {string} disabledFieldNames - Manually specify input field to disable in children.
 */
export const InputGroup = ({
  disableAllChildren,
  disabledFieldNames,
  children,
}) => {
  const disablableTypes = [TextField, Select, Button, Switch, FormControlLabel];

  const createChildElement = (children, i=1) => {
    if (children?.props) {
      return React.createElement(children.type, {
        ...{
          ...children.props,
          ...(disableAllChildren && disablableTypes.includes(children.type) && { disabled: true }),
          ...(disabledFieldNames && disabledFieldNames.includes(children.props.name) && { disabled: true }),
          children: children.props.children && renderChildren(children.props.children),
          key: `${children.type.displayName}_${i}`,
        },
      });
    }
    if (Array.isArray(children)) {
      return children.map(createChildElement);
    }
    return children;
  };

  const renderChildren = children => {
    return Array.isArray(children) ? children.map(createChildElement) : createChildElement(children);
  };

  return (
    <>
      {renderChildren(children)}
    </>
  );
};


InputGroup.propTypes = {
  disableAllChildren: PropTypes.bool,
  disabledFieldNames: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
