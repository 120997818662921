import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';

import { DATE_FORMAT } from '../../../../utilities/dateUtils';
import { PERMISSIONS } from '../../../auth/constants';
import { PermissionCheck } from '../../../auth/permissionCheck.component';
import LifetimeCounts from './lifetimeCounts.component';
import Occupancies from './occupancies.container';
import PodConnectionStats from './podConnectionStats.component';

const PodMonitoringOverview = ({
  classes,
  selectedLocation,
  selectedLocationStatus,
}) => {
  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="POD Status" />
            <CardContent>
              <PodConnectionStats
                location={selectedLocation}
                locationStatus={selectedLocationStatus}
              />
            </CardContent>
          </Card>
        </Grid><Grid item xs={12}>
          <Card>
            <CardHeader
              title="POD Usage Lifetime Counts"
              subheader={selectedLocation.podBuildDate ?
                `Install date of ${moment(selectedLocation.podBuildDate).format(DATE_FORMAT.DISPLAY)} (${moment(selectedLocation.podBuildDate).fromNow()})` :
                'No Install Date'}
            />
            <CardContent>
              <LifetimeCounts locationStatus={selectedLocationStatus} />
            </CardContent>
          </Card>
        </Grid>
        <PermissionCheck require={PERMISSIONS.EVENTS_READ}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Occupancies" />
              <CardContent>
                <Occupancies />
              </CardContent>
            </Card>
          </Grid>
        </PermissionCheck>
      </Grid>
    </div>
  );
};

const styles = () => ({
  container: {
    width: 'calc(100% - 24px)',
    marginTop: 12,
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 'calc(100% - 13rem)',
  },
});

const mapStateToProps = state => {
  return {
    selectedLocation: state.location.get('selectedLocation'),
    selectedLocationStatus: state.monitoring.get('selectedLocationStatus'),
  };
};

PodMonitoringOverview.propTypes = {
  selectedLocation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  selectedLocationStatus: PropTypes.object.isRequired,
};

const prepareForExport = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
  ),
);

export default prepareForExport(PodMonitoringOverview);