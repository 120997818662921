import {
  CREATE_PROMOTION_SUCCESS,
  DELETE_PROMOTION_SUCCESS,
  GET_PROMOTIONS_FAILURE,
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTION_FAILURE,
  GET_PROMOTION_REQUEST,
  GET_PROMOTION_SUCCESS,
  SET_SELECTED_PROMOTION,
  UPDATE_PROMOTION_SUCCESS,
} from './promotions.actions';
import { findIndex, isEmpty } from 'lodash';
import Immutable from 'immutable';

const initialState = Immutable.Map({
  promotions: [],
  selectedPromotion: null,
  isLoading: false,
  errors: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROMOTIONS_SUCCESS: {
      return state.set('promotions', action.response).set('isLoading', false);
    }

    case GET_PROMOTIONS_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);

    case GET_PROMOTIONS_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    case CREATE_PROMOTION_SUCCESS: {
      let promotions = [...state.get('promotions')];
      let newPromotion = { ...action.response };
      promotions.push(newPromotion);

      return state.set('promotions', promotions).set('isLoading', false);
    }

    case GET_PROMOTION_SUCCESS:
      return state.set('selectedPromotion', action.response).set('isLoading', false);

    case GET_PROMOTION_FAILURE:
      return state.set('isLoading', false);

    case GET_PROMOTION_REQUEST:
      return state.set('isLoading', true);

    case SET_SELECTED_PROMOTION:
      return state.set('selectedPromotion', action.response);

    case UPDATE_PROMOTION_SUCCESS: {
      let selectedPromotion = { ...state.get('selectedPromotion') };
      let promotions = [...state.get('promotions')];
      let promotionId = action.actionMetadata;

      if (!isEmpty(selectedPromotion) && selectedPromotion.id === promotionId) {
        selectedPromotion = Object.assign(selectedPromotion, action.payload);
      }

      let idx = findIndex(promotions, { id: promotionId });
      if (idx > -1) {
        promotions[idx] = Object.assign(promotions[idx], action.payload);
      }

      return state
        .set('selectedPromotion', selectedPromotion)
        .set('promotions', promotions)
        .set('isLoading', false);
    }

    case DELETE_PROMOTION_SUCCESS: {
      let promotions = [...state.get('promotions')];

      let idx = findIndex(promotions, { id: action.actionMetadata.promotionId });
      if (idx > -1) {
        promotions.splice(idx, 1);
      }

      return state.set('promotions', promotions).set('isLoading', false);
    }

    default:
      return state;
  }
}
