import { Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import { DATE_FORMAT } from '../../utilities/dateUtils';

const StatItemComp = ({title, subtitle, classes, date, color}) => {
  return (
    <Paper className={classes.statContainer} style={{backgroundColor: color}}>
      <div>
        <Typography variant="subtitle1" align="center">{subtitle}</Typography>
        <Typography variant="h5" align="center">{title}</Typography>
      </div>
      {date && (
        <Typography variant="caption" align="center">as of {moment(date).format(DATE_FORMAT.DATE_TIME)}</Typography>
      )}
    </Paper>
  );
};

const styles = () => ({
  icon: {
    fontSize: 40,
    paddingRight: 8,
  },
  statContainer: {
    flex: 1,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

const prepareForExport = compose(
  withStyles(styles)
);

StatItemComp.defaultProps = {
  date: '',
  subtitle: '',
  color: '',
};

StatItemComp.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  color: PropTypes.string,
};

const StatItem = prepareForExport(StatItemComp);

export default StatItem;
