import { Grid, Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PodTable from './podsTable.component';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { selectGeneratedCommand } from '../iotCommand.selectors';
import { setPodsSelected } from '../iotCommand.actions';
import CommandFormBasics from './commandFormBasics.component';
import GeneratedCommandInput from './generatedCommandInput.component';

const ReviewCommand = ({
  classes,
  commandConfig,
  selectedPods,
  description,
  selectedCommandIndex,
  generatedCommand,
  setPodsSelected,
}) => {
  
  // -- PODS

  const [podsForReview, setPodsForReview] = useState(selectedPods.map((location) => ({
    ...location,
    isSelected: true,
  })));

  const setPodsSelectedForRun = ({isSelected, locationIds}) => {
    setPodsForReview(podsForReview.map((location) => ({
      ...location,
      isSelected: locationIds.includes(location.id) ? isSelected : location.isSelected,
    })));

    setPodsSelected({locationIds, isSelected});
  };

  return (
    <Grid 
      container
      spacing={2}
    >
      <Grid 
        container
        item
        xs={12}
        spacing={2}
      >
        <Grid 
          item xs={6}
        >
          <CommandFormBasics
            readonly
            description={description}
            commandConfig={commandConfig}
            selectedCommandIndex={selectedCommandIndex}
          />
        </Grid>

        <Grid 
          item xs={6}
          className={classes.generatedCommandGrid}
        >
          <GeneratedCommandInput
            readonly
            generatedCommand={generatedCommand}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          <Grid container>
            <Grid item xs>
              <Typography variant="subtitle1" className={classes.title}>
                {selectedPods.length > 1 ? 'For these Selected Pods' : 'For this Selected Pod'}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" className={classes.title} align="right">
                {`${selectedPods.length} Pod${selectedPods.length > 1 ? 's' : ''} selected`}
              </Typography>
            </Grid>
          </Grid>
          <PodTable
            locations={podsForReview}
            handleSelectPods={setPodsSelectedForRun}
          />
        </Paper>
      </Grid>

    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    commandConfig: state.iotCommand.get('commandConfig'),

    selectedPods: state.iotCommand.get('selectedPods'),
    description: state.iotCommand.get('description'),
    selectedCommandIndex: state.iotCommand.get('selectedCommandIndex'),
    generatedCommand: selectGeneratedCommand(state),
  };
};

ReviewCommand.propTypes = {
  classes: PropTypes.object.isRequired,
  
  commandConfig: PropTypes.array.isRequired,
  
  selectedPods: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
  selectedCommandIndex: PropTypes.number.isRequired,
  generatedCommand: PropTypes.string.isRequired,

  setPodsSelected: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  title: {
    padding: 16,
  },
  generatedCommandGrid: {
    alignSelf: 'flex-end',
  },
});

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    {
      setPodsSelected,
    }
  )
);

export default prepareForExport(ReviewCommand);