import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { has } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

import { GET_COMMAND_SET_FAILURE, getCommandSet } from './iotCommand.actions';
import { handleToastMessage, setPageTitle } from '../layout/layout.actions';
import CommandResponses from './commandDetails/commandResponses.component';
import CommandSet from './commandDetails/commandSetDetails.component';
import LoadingOverlay from '../layout/loadingOverlay/loadingOverlay.component';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const CommandSetDetails = ({
  classes,
  match,
  history,
  getCommandSet,
  selectedCommandSet,
  isLoading,
  handleToastMessage,
  setPageTitle,
}) => {

  //Once the send command response comes back with the proper ID update the URL
  const prev = usePrevious({selectedCommandSet});
  useEffect(() => {
    if (prev && prev.selectedCommandSet && !has(prev, 'selectedCommandSet.id') && has(selectedCommandSet, 'id')) {
      history.replace(`/iotCommands/commandSet/${selectedCommandSet.id}`);
    }
  }, [selectedCommandSet]);

  // Set Page Title
  useEffect(() => {
    setPageTitle('IoT Command Details');
  }, []);

  // Fetch CommandSet
  useEffect(() => {
    const fetchData = async () => {
      const id = match.params.id;
      if (id === 'new'){
        if (selectedCommandSet === null){
          history.replace('/iotCommands');
        }
      } else {
        const commandSetResponse = await getCommandSet(id);
        if (commandSetResponse.type === GET_COMMAND_SET_FAILURE) {
          handleToastMessage(`Error getting command set: ${commandSetResponse.messages[0]}`, true);
        }
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (selectedCommandSet === null) {
    return (
      <div className={classes.container}>
        <Typography variant="body1">Could Not Load Command Set</Typography>
      </div>
    );
  }

  return (
    <div className={classnames([classes.container, 'page-padding-bottom'])}>
      <CommandSet
        commandSet={selectedCommandSet}
      />
      <CommandResponses
        commandResponses={selectedCommandSet.commandResponses}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCommandSet: state.iotCommand.get('selectedCommandSet'),
    isLoading: state.iotCommand.get('isLoading'),
  };
};

CommandSetDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getCommandSet: PropTypes.func.isRequired,
  selectedCommandSet: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  handleToastMessage: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
};

const styles = () => ({
  container: {
    width: 'calc(100% - 24px)',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 'calc(100% - 13rem)',
  },
});

const prepareForExport = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      getCommandSet,
      handleToastMessage,
      setPageTitle,
    }
  ),
);

export default prepareForExport(CommandSetDetails);