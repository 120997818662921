import { Badge, Tab, Tabs } from '@mui/material';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import { getSubmittedLocations, getSubmittedPhotos, getSubmittedReviews } from './submissions.actions';
import { setPageTitle } from '../layout/layout.actions';
import Colors from '../../styles/colors';
import SubmittedLocations from './submittedLocations.component';
import SubmittedPhotos from './submittedPhotos.component';
import SubmittedReviews from './submittedReviews.component';
import { useCheckPermission } from '../../hooks/useCheckPermission';
import { PERMISSIONS } from '../auth/constants';

const TABS = {
  LOCATIONS: 'LOCATIONS',
  PHOTOS: 'PHOTOS',
  REVIEWS: 'REVIEWS',
};

function SubmissionsContainer ({classes, isLoadingReviews, isLoadingPhotos, isLoadingLocations, newPhotos, newLocations, newReviews, location, history}) {
  const [tabIndex, setTabIndex] = useState(null);
  const dispatch = useDispatch();
  const { isAuthorized } = useCheckPermission();
  const showReviews = isAuthorized(PERMISSIONS.REVIEWS_READ);

  useEffect(() => {
    dispatch(setPageTitle('Submissions'));
    dispatch(getSubmittedReviews());
    dispatch(getSubmittedPhotos());
    dispatch(getSubmittedLocations());
  }, []);

  // Request every time we go to a new tab
  useEffect(() => {
    if (tabIndex === TABS.LOCATIONS) {
      dispatch(getSubmittedLocations());
    } else if (tabIndex === TABS.PHOTOS) {
      dispatch(getSubmittedPhotos());
    } else if (tabIndex === TABS.REVIEWS) {
      dispatch(getSubmittedReviews());
    }
  }, [tabIndex]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setTabIndex(queryParams.get('tab') ? TABS[queryParams.get('tab')] : TABS.LOCATIONS);
  }, [location]);

  const buildTabs = (arr, text) => {
    if (arr && arr.length > 0) {
      return (
        <Badge color="error" classes={{ badge: classes.badge }} badgeContent={arr.length}>
          {text}
        </Badge>
      );
    } else {
      return <span>{text}</span>;
    }
  };

  const changeTabs = (event, value) => {
    setTabIndex(value);
    const pathname = location.pathname;
    history.push({
      pathname,
      search: `?tab=${value}`,
    });
  };

  return (
    <Fragment>
      <Tabs className={classes.tabs} value={tabIndex} onChange={changeTabs}>
        <Tab label={buildTabs(newLocations, 'New Locations')} value={TABS.LOCATIONS} className={classes.tab} />
        <Tab label={buildTabs(newPhotos, 'New Photos')} value={TABS.PHOTOS} className={classes.tab} />
        {showReviews &&
          <Tab label={buildTabs(newReviews, 'New Reviews')} value={TABS.REVIEWS} className={classes.tab} />
        }
      </Tabs>
      <div className={classes.tableWrapper} id="tableWrapper">
        {tabIndex === TABS.LOCATIONS && (
          <Fragment>
            {isLoadingLocations && <div className="loader" />}
            {!isLoadingLocations && <SubmittedLocations locations={newLocations} />}
          </Fragment>
        )}
        {tabIndex === TABS.PHOTOS && (
          <Fragment>
            {isLoadingPhotos && <div className="loader" />}
            {!isLoadingPhotos && <SubmittedPhotos photos={newPhotos} />}
          </Fragment>
        )}
        {tabIndex === TABS.REVIEWS && (
          <Fragment>
            {isLoadingReviews && <div className="loader" />}
            {!isLoadingReviews && <SubmittedReviews reviews={newReviews} />}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

const styles = (theme) => ({
  badge: {
    top: -5,
    right: -10,
  },
  tableWrapper: {
    padding: '0rem',
    overflowX: 'auto',
  },
  tabs: {
    backgroundColor: Colors.secondary.main,
  },
  tab: {
    color: Colors.white,
    fontWeight: 'bold',
    paddingLeft: 30,
    paddingRight: 30,
  },
});

SubmissionsContainer.propTypes = {
  newLocations: PropTypes.array.isRequired,
  newPhotos: PropTypes.array.isRequired,
  newReviews: PropTypes.array.isRequired,
  isLoadingReviews: PropTypes.bool.isRequired,
  isLoadingPhotos: PropTypes.bool.isRequired,
  isLoadingLocations: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,

  getSubmittedReviews: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,

  classes: PropTypes.object.isRequired,

  // Injected by React Router
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    newLocations: state.submissions.get('newLocations'),
    newPhotos: state.submissions.get('newPhotos'),
    newReviews: state.submissions.get('newReviews'),

    isLoadingReviews: state.submissions.get('isLoadingReviews'),
    isLoadingPhotos: state.submissions.get('isLoadingPhotos'),
    isLoadingLocations: state.submissions.get('isLoadingLocations'),
    errors: state.submissions.get('errors'),
  };
};

const prepareForExport = compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(
    mapStateToProps,
    {}
  ),
);

export default prepareForExport(SubmissionsContainer);
