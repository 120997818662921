import { Checkbox } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';

const COLUMN_HEADER_FONT_SIZE = '0.75rem';

class DataTableHead extends Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, columns, onSelectAll, isAllSelected, classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          <Fragment>
            {onSelectAll && (
              <TableCell padding="none">
                <Checkbox
                  className={classes.checkbox}
                  checked={isAllSelected}
                  onClick={() => onSelectAll(!isAllSelected)}
                />
              </TableCell>
            )}
            {columns.map(
              (column) => (
                <TableCell
                  key={column.id}
                  align={column.numeric ? 'right' : 'left'}
                  padding={column.padding}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  {column.sortable ? (
                    <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={order}
                        onClick={this.createSortHandler(column.id)}
                        className={classes.columnHeader}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  ) : (
                    <div className={classes.columnHeader}>{column.label}</div>
                  )}
                </TableCell>
              )
            )}
          </Fragment>
        </TableRow>
      </TableHead>
    );
  }
}

DataTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  onAddClick: PropTypes.func,
  onSelectAll: PropTypes.func,
  isAllSelected: PropTypes.bool,
};

const styles = () => ({
  checkbox: {
    width: 'auto',
    height: 'auto',
  },
  columnHeader: {
    fontSize: COLUMN_HEADER_FONT_SIZE,
  },
});

const prepareForExport = compose(
  withStyles(styles)
);

export default prepareForExport(DataTableHead);
